import _ from "lodash/fp";
import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchLearningObjects } from "../routines";

import { LinearProgress } from "@material-ui/core";

const LearningObjectsLoader = ({
  type,
  active = true,
  fetchLearningObjects,
  fetchingLearningObjects,
  learningObjectsFilters: filters,
}) => {
  useEffect(() => {
    fetchLearningObjects({ type, active, filters });
  }, [fetchLearningObjects, type, active, filters]);
  return fetchingLearningObjects ? <LinearProgress /> : null;
};

const mapStateToProps = _.pick([
  "fetchingLearningObjects",
  "learningObjectsFilters",
]);

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchLearningObjects }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LearningObjectsLoader);
