import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";

const useStyles = makeStyles({
  root: {
    border: "1px solid #c7c7c7",
    borderRadius: "4px",
    height: "56px",
    padding: "18.5px 14px",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "16px",
    "&:hover": {
      borderColor: "black",
    },
    display: "flex",
    alignItems: "center",
  },
  error: {
    borderColor: "#f44337",
    color: "#f44337",
  },
  helperText: {
    color: "#f44337",
    margin: "8px 14px 0",
    fontSize: "0.75rem",
    minHeight: "1em",
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "1em",
  },
});

export default ({
  name,
  value,
  onChange,
  required,
  label,
  error,
  helperText,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={`${classes.root} ${error ? classes.error : ""}`}>
        {label} {required ? " *" : ""}
        <Rating
          value={value / 2}
          precision={0.5}
          onChange={({ target: { value } }) =>
            onChange({ target: { name, value: value * 2 } })
          }
        />
      </div>
      {error && <div className={classes.helperText}>{helperText}</div>}
    </>
  );
};
