import _ from "lodash/fp";
import pathToRegexp from "path-to-regexp";

const routes = {
  SCORES: "/scores",
  USER_ACTIONS: "/scores/user_actions",
  USER_ACTIONS_IN_PROGRESS: "/scores/user_actions/in_progress",
  USER_ACTIONS_LASTEST: "/scores/user_actions/lastest",
  USER_ACTIONS_PAYMENTS: "/scores/user_actions/payments",
  REWARDS: "/scores/rewards",
};

const linkTo = _.mapValues(pathToRegexp.compile, routes);

export { routes as default, linkTo };
