import _ from "lodash/fp";
import React from "react";
import {
  People as PeopleIcon,
  Grain as GracinIcon,
  Explore as ExploreIcon,
  ImportContacts as ImportContactsIcon,
  SportsSoccer as SportsSoccerIcon,
  VolumeUp as VolumeUpIcon,
} from "@material-ui/icons";

const scores = [
  { prop: "zircoins", name: "Zircoins", icon: <GracinIcon /> },
  {
    prop: "learning_points",
    name: "Aprendizaje",
    icon: <ImportContactsIcon />,
  },
  {
    prop: "experience_points",
    name: "Experiencia",
    icon: <ExploreIcon />,
  },
  {
    prop: "communication_points",
    name: "Comunicación",
    icon: <VolumeUpIcon />,
  },
  {
    prop: "community_points",
    name: "Comunidad",
    icon: <PeopleIcon />,
  },
  {
    prop: "team_work_points",
    name: "T. Equipo",
    icon: <SportsSoccerIcon />,
  },
];

const allPoints = [
  "zircoins",
  "learning_points",
  "experience_points",
  "communication_points",
  "community_points",
  "team_work_points",
];

const zeros = {
  zircoins: 0,
  learning_points: 0,
  experience_points: 0,
  communication_points: 0,
  community_points: 0,
  team_work_points: 0,
};

const minPointsSum = (pts1, pts2) =>
  _.flow(
    _.map((key) => pts1[key] + pts2[key]),
    _.min
  )(allPoints);

const ranks = {
  universe: {
    name: "Universo",
    learning_points: 20000,
    experience_points: 1080,
    communication_points: 150,
    community_points: 1120,
    team_work_points: 160,
  },
  supercluster: {
    name: "Supercúmulo",
    learning_points: 10000,
    experience_points: 480,
    community_points: 480,
    team_work_points: 80,
  },
  galaxy: {
    name: "Galaxia",
    learning_points: 4000,
    experience_points: 120,
    community_points: 120,
  },
  constellation: {
    name: "Constelación",
    learning_points: 1500,
  },
  star: {
    name: "Estrella",
    learning_points: 500,
  },
  planet: {
    name: "Planeta",
    learning_points: 100,
  },
  asteroid: {
    name: "Asteroide",
  },
};

const allRankNames = [
  "universe",
  "supercluster",
  "galaxy",
  "constellation",
  "star",
  "planet",
  "asteroid",
];

const hasMinPointsFor = (rank, points) =>
  _.reduce(
    (acc, p) =>
      acc && _.getOr(0, p)(points) >= _.getOr(0, p)(_.prop(rank)(ranks)),
    true
  )(allPoints);
const getRankCode = (points) =>
  _.reduce(
    (acc, rank) =>
      !_.isNil(acc) ? acc : hasMinPointsFor(rank, points) ? rank : null,
    null
  )(allRankNames);

const getRank = (points) => _.prop(getRankCode(points))(ranks);

const action_types = {
  compensation: "Compesación",
  atend: "Asistida",
  speak: "Ponente",
  record_video: "Video grabado",
  edit_video: "Video editado",
  insert: "Añadido",
  request: "Solicitado",
  complete: "Completado",
  out_complete: "Completado por fuera",
  publish: "Publicado",
  start_pack: "Puntos Iniciales",
  desist: "Desistir",
};

const getActionTypeText = (action_type) =>
  action_types[action_type] || action_type;

export {
  allPoints,
  scores,
  zeros,
  minPointsSum,
  ranks,
  getRank,
  getActionTypeText,
};
