import _ from 'lodash/fp'
import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  useQuery,
  gql
} from "@apollo/client";

import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import Paper from '@material-ui/core/Paper'
import AddIcon from '@material-ui/icons/Add'

import parseLoImage from 'learning_objects/loImageParser'
import routes, { linkTo } from '../routes'
import { UserCompact } from 'accounts/components'
import Moment from 'react-moment'
import { LearningObjectStatsCompact } from '.'
import orderingFields from './novaBlogsOrdering'
import useOrderBy from 'common/orderer'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  content: {
    maxWidth: '1300px',
  },
  addButton: {
    float: 'right',
    marginTop: '15px',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
  },
  post_item: {
    margin: '5px 15px',
    padding: '15px',
    cursor: 'pointer',
    display: 'flex',
  },
  post_image: {
    width: '250px',
    height: '150px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  item_content: {
    flexGrow: '1',
    padding: '0px 15px',
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontSize: '25px',
    fontWeight: 'bolder',
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
    fontSize: '15px',
  },
})

const LEARNING_OBJECTS = gql`
  query (
    $filter: LearningObjectFilter,
    $orderBy: LearningObjectOrder
  ){
    learningObjects(
      filter: $filter,
    	orderBy: $orderBy
    ) {
      id
      name
      tags
      badgesIds
      rateAverage
      rateCount
      activeCount
      completeCount
      accessCount
      publish_date
      metadata {
        speakers {
          id
          given_name
          family_name
          email
          picture
        }
      }
      requestCost {
        zircoins
      }
    }
  }
`

export default () => {
  const classes = useStyles()
  const history = useHistory()

  let filter = {
    type: 'nova_blog',
    active: true,
  }
  const { orderBySelector, orderBy } = useOrderBy(orderingFields, 'most_recent')

  const { data: learningObjectsData } = useQuery(
    LEARNING_OBJECTS,
    {variables: {
      filter,
      orderBy
    },
    fetchPolicy: "network-only"
  })
  
  const novaBlogs = _.getOr([], 'learningObjects')(learningObjectsData)

  const navigate = (blog) => history.push(linkTo.LEARNING_OBJECT(blog))
  const navigateNew = () => history.push(routes.NOVA_BLOGS_NEW)

  const PostRow = ({ learningObject }) => {
    const imageSource = parseLoImage(learningObject)
    return (
      <Paper
        className={classes.post_item}
        onClick={() => navigate(learningObject)}
      >
        <div
          className={classes.post_image}
          style={{ backgroundImage: `url(${imageSource})` }}
        />
        <div className={classes.item_content}>
          <div className={classes.title}>{_.get('name')(learningObject)}</div>
        </div>
        <div className={classes.detail}>
          <UserCompact user={_.get('creator')(learningObject)} />
          <Moment
            className={classes.timestamp}
            date={_.prop('publish_date')(learningObject)}
            format="DD/MM/YYYY"
          />
          <LearningObjectStatsCompact
            learningObject={learningObject}
            firstItem="access"
            showOnlyFirst
          />
        </div>
      </Paper>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Fab
          variant="extended"
          onClick={navigateNew}
          className={classes.addButton}
          color="primary"
        >
          <AddIcon />
          Crear un post
        </Fab>
        <h1>Nova Blogs</h1>
        {orderBySelector}
        <div className={classes.list}>
          {novaBlogs.map((lo, key) => (
            <PostRow key={key} learningObject={lo} />
          ))}
        </div>
      </div>
    </div>
  )
}
