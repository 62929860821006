import _ from "lodash/fp";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";

import { linkTo } from "../routes";
import { UserCompact } from "accounts/components";
import parseType from "common/loTypeParser";
import { deleteLearningObject } from "../routines";
import ButtonModal from "common/ButtonModal";

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    learningObjects,
    currentUser: { is_admin, id: currentUserId },
  } = useSelector(_.pick(["learningObjects", "currentUser"]));

  const deleteLo = (id) => dispatch(deleteLearningObject(id));

  const navigate = (learningObject) =>
    history.push(linkTo.LEARNING_OBJECT_ACTIONS_EDIT(learningObject));
  return (
    <>
      <h1>Cursos o Eventos pendientes de aprobación</h1>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            {learningObjects.map(
              ({ name, subject, id, type, creator, metadata }, idx) => (
                <TableRow hover key={idx}>
                  <TableCell>{parseType(type, metadata)}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>{subject}</TableCell>
                  <TableCell>
                    <UserCompact user={creator} />
                  </TableCell>
                  {is_admin && (
                    <TableCell>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => navigate({ id })}
                      >
                        Verificar
                      </Button>
                    </TableCell>
                  )}
                  {(is_admin || _.prop("id")(creator) === currentUserId) && (
                    <TableCell>
                      <ButtonModal
                        buttonText="Borrar"
                        modalTitle="Borrar curso?"
                        onSubmit={() => deleteLo(id)}
                      >
                        Seguro que quiere borrar el curso pendiente?
                      </ButtonModal>
                    </TableCell>
                  )}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
