import { fetchLearningObject } from "../routines";

const fetchingLearningObject = (state = false, { type }) => {
  switch (type) {
    case fetchLearningObject.TRIGGER:
      return true;
    case fetchLearningObject.FULFILL:
      return false;
    default:
      return state;
  }
};

const initialState = {};

const learningObject = (state = initialState, { type, payload }) => {
  switch (type) {
    case fetchLearningObject.SUCCESS:
      return payload.data;
    default:
      return state;
  }
};

export { learningObject, fetchingLearningObject };
