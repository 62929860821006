import _ from 'lodash/fp'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import { Avatar } from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'

import { PointsCompact, RankWidget } from 'scores/components'
import UserActionsButton from './UserActionsButton'
import UserBadges from './UserBadges'
import { updateUser } from '../routines'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    background: 'white',
    border: '1px solid #efefef',
    margin: '10px',
    flexWrap: 'wrap',
  },
  avatar: {
    margin: '20px',
    width: '96px',
    height: '96px',
  },
  info_content: {
    margin: '20px',
    flexDirection: 'column',
  },
  name: {
    height: '48px',
    fontSize: '40px',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  email: {
    height: '24px',
    fontSize: '20px',
    alignItems: 'center',
    color: '#656565',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  rank: {
    height: '24px',
    fontSize: '18px',
    fontWeight: 'bolder',
    alignItems: 'center',
    textAlign: 'center',
    color: '#3347be',
  },
  admin_tag: {
    fontSize: '13px',
    fontWeight: 'bolder',
    color: 'green',
    marginRight: '10px',
  },
  points: {
    margin: '20px',
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  points_row: {
    flexDirection: 'row',
    fontWeight: 'bolder',
    '& .MuiChip-root': {
      fontSize: '17px',
    },
  },
  pending_points_row: {
    flexDirection: 'row',
    '& .MuiChip-root': {
      border: '1px solid #a9a931',
      background: '#fffee9',
    },
  },
  button: {
    fontSize: '11px',
    padding: '0px 10px',
    height: 'unset',
    marginLeft: '5px',
  },
})

export default () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {user, currentUser: {is_admin: isCurrentAdmin}} = useSelector(_.pick(['user', 'currentUser']))
  if (!user) return null

  const {
    id,
    given_name,
    family_name,
    email,
    picture,
    is_admin,
    points,
    pending_points,
    hidden_at,
  } = user
  return (
    <div>
      <div className={classes.root}>
        <Avatar src={picture} className={classes.avatar} />
        <div className={classes.info_content}>
          <div className={classes.name}>
            {given_name} {family_name}
          </div>
          <div className={classes.email}>{email}</div>
          <div className={classes.rank}>
            <RankWidget points={points} />
          </div>
        </div>
        <div className={classes.points}>
          {is_admin && <div className={classes.admin_tag}>Administrador</div>}
          <div className={classes.points_row}>
            <PointsCompact points={points} size="medium" />
          </div>
          <div className={classes.pending_points_row}>
            <PointsCompact points={pending_points} />
          </div>
          <UserBadges />
          {hidden_at && (
            <div>Oculto desde {moment(hidden_at).format('DD/MM/YYYY')}</div>
          )}
          {isCurrentAdmin &&
            <div>
              <UserActionsButton user={user} />
              <ToggleButton
                value="hidden"
                className={classes.button}
                variant="outlined"
                selected={hidden_at}
                onClick={(e) => {
                  e.stopPropagation()
                  dispatch(
                    updateUser({
                      user_id: id,
                      hidden_at: hidden_at ? null : new Date(),
                    })
                  )
                }}
              >
                Oculto
              </ToggleButton>
              <ToggleButton
                value="admin"
                className={classes.button}
                variant="outlined"
                selected={is_admin}
                onClick={(e) => {
                  e.stopPropagation()
                  dispatch(
                    updateUser({
                      user_id: id,
                      is_admin: !is_admin,
                    })
                  )
                }}
              >
                ADMIN
              </ToggleButton>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
