import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import parseImage from "../loImageParser";
import LearningObjectDetail from "./LearningObjectDetail";

const useStyles = makeStyles({
  root: {
    padding: "5px",
    display: "flex",
  },
  img: {
    objectFit: "cover",
    width: "100px",
    height: "100px",
    borderRadius: "5px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "5px",
  },
  name: {
    fontSize: "18px",
    marginBottom: "10px",
  },
});

export default ({ learningObject }) => {
  const classes = useStyles();
  const imageSource = parseImage(learningObject);

  return learningObject ? (
    <div className={classes.root}>
      <img
        className={classes.img}
        src={imageSource}
        alt={learningObject.name}
      />
      <div className={classes.content}>
        <div className={classes.name}>{learningObject.name}</div>
        <LearningObjectDetail learningObject={learningObject} />
      </div>
    </div>
  ) : null;
};
