import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createLearningObject } from "../routines";
import { useHistory } from "react-router-dom";
import routes from "../routes";
import { Avatar, Container, CssBaseline } from "@material-ui/core";
import { Class as ClassIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import GenericForm from "common/GenericForm";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const formFields = [
  { field: "external_url", label: "Enlace", required: true },
  { field: "name", label: "Título", required: true },
  {
    field: "description",
    label: "Descripción",
    multiline: true,
  },
  { field: "subject", label: "Tema" },
  {
    field: "tags",
    label: "Etiquetas (Pulsa Enter para añadir nuevas)",
    type: "chips",
  },
  { field: "metadata.image", label: "URL Imagen" },
  {
    field: "metadata.duration",
    label: "Duración en horas",
    type: "numeric",
    required: true,
  },
  {
    field: "metadata.real_cost",
    label: "Precio del curso",
    required: true,
    type: "numeric",
  },
  {
    field: "metadata.reason",
    label: "Motivo de la solicitud",
    required: true,
    multiline: true,
  },
];

const MoocsNew = ({ createLearningObject }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleSubmit = (item) => {
    createLearningObject({ ...item, type: "mooc" });
    history.push(routes.LEARNING_OBJECTS_PENDING);
  };

  return (
    <Container maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <ClassIcon />
        </Avatar>
        <h1>Solicitar nuevo curso</h1>
        <GenericForm
          fields={formFields}
          onSubmit={handleSubmit}
          onCancel={() => history.goBack()}
        />
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createLearningObject }, dispatch);

export default connect(null, mapDispatchToProps)(MoocsNew);
