import _ from 'lodash/fp'
import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { linkTo } from '../routes'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRadius: '15px',
    alignItems: 'center',
    paddingRight: '6px',
    height: '26px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  rootSmall: {
    display: 'flex',
    borderRadius: '15px',
    alignItems: 'center',
    paddingRight: '6px',
    height: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  rootHover: {
    cursor: 'pointer',
    '&:hover': {
      background: '#e1e1e1',
    },
  },
  backgroundme: {
    background: '#e0249f42',
  },
  name: {
    marginLeft: '5px',
  },
  nameSmall: {
    fontSize: '12px',
    marginLeft: '2px',
  },
  normal: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}))

const UserCompact = ({ user, currentUser, readOnly = false, size }) => {
  const classes = useStyles()
  const history = useHistory()

  if (!user) return null

  const navigate = () => history.push(linkTo.USER({ user_id: user.id }))

  const identifier = user.given_name
    ? user.given_name + ' ' + user.family_name
    : user.email

  return (
    <div
      onClick={!readOnly ? navigate : null}
      className={
        (size === 'small' ? classes.rootSmall : classes.root) +
        (readOnly ? '' : ' ' + classes.rootHover) +
        (user.id === currentUser.id ? ' ' + classes.backgroundme : '')
      }
    >
      <Avatar
        src={user.picture}
        className={size === 'small' ? classes.small : classes.normal}
      />
      <div className={size === 'small' ? classes.nameSmall : classes.name}>
        {identifier}
      </div>
    </div>
  )
}

export default connect(_.pick(['currentUser']))(UserCompact)
