import React from "react";
import { useDispatch } from "react-redux";
import { createIssue, updateIssue } from "../routines";

import GenericForm from "common/GenericForm";

const formFields = [
  { field: "title", label: "Título", required: true },
  {
    field: "content",
    label: "Contenido",
    required: true,
    multiline: true,
  },
];

export default ({ onComplete, edit }) => {
  const dispatch = useDispatch();

  const handleSubmit = (item) => {
    if (edit) dispatch(updateIssue({ ...edit, ...item }));
    else dispatch(createIssue({ ...item }));
    onComplete();
  };

  return (
    <GenericForm
      fields={formFields}
      defaultItem={edit}
      submitText="Guardar incidencia/sugerencia"
      onSubmit={handleSubmit}
      onCancel={onComplete}
    />
  );
};
