import _ from "lodash/fp"
import React, { useEffect } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { fetchUsers } from "../routines";

import { LinearProgress } from "@material-ui/core"


const UserLoader = ({ fetchUsers, fetchingUsers }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchUsers() }, []);

  return (
        fetchingUsers
        ? <LinearProgress />
        : null
    );
}

const mapStateToProps = _.pick(["fetchingUsers"])

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators({
        fetchUsers,
    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(UserLoader)