import _ from "lodash/fp";
import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { createUserAction } from "scores/routines";

import ButtonModal from "common/ButtonModal";
import { makeStyles } from "@material-ui/core/styles";
import FormBody from "common/FormBody";
import { validationErrors, formatValue } from "common/formUtils";

const useStyles = makeStyles({
  requested: {
    display: "inline",
    marginLeft: "5px",
    background: "#828282",
    borderRadius: "15px",
    color: "white",
    padding: "1px 8px",
  },
});

const fields = [
  {
    field: "metadata.proof",
    label: "Añada el enlace al certificado o prueba de la finalización",
    required: true,
  },
  {
    field: "metadata.end_date",
    type: "date",
    label: "Fecha de expiración",
  },
  {
    field: "metadata.rate",
    type: "rating",
    label: "Valoración",
    required: true,
  },
  {
    field: "metadata.comment",
    label: "Comparte tus comentarios acerca del curso",
    multiline: true,
    required: true,
  },
];

const CompleteButton = ({ learningObject }) => {
  const [form, setForm] = useState({});
  const [formErrors, setFormErrors] = useState();
  const classes = useStyles();
  const currentUser = useSelector(_.get("currentUser"));
  const dispatch = useDispatch();
  const { create } = bindActionCreators({ create: createUserAction }, dispatch);

  const requestAction = _.flow(
    _.get("actions"),
    _.filter({ action_type: "request" }),
    _.orderBy("inserted_at", "desc"),
    _.head
  )(learningObject);

  const completeAction = _.flow(
    _.get("actions"),
    _.filter({ action_type: "complete" }),
    _.orderBy("inserted_at", "desc"),
    _.head
  )(learningObject);

  const desistAction = _.flow(
    _.get("actions"),
    _.filter({ action_type: "desist" }),
    _.orderBy("inserted_at", "desc"),
    _.head
  )(learningObject);

  const filterApproved = _.filter({ status: "APPROVED" });
  const completedUserEmails = _.flow(
    _.prop("user_actions"),
    filterApproved,
    _.map(({ user: { email } }) => email)
  )(completeAction);
  const requestedUserEmails = _.flow(
    _.prop("user_actions"),
    filterApproved,
    _.map(({ user: { email } }) => email),
    _.filter((email) => _.indexOf(email)(completedUserEmails) < 0)
  )(requestAction);

  const isMyPendingComplete = !_.isNil(
    _.flow(
      _.get("user_actions"),
      _.filter({ status: "PENDING" }),
      _.find({ user: { id: currentUser.id } })
    )(completeAction)
  );

  const lastTimeUserAction = _.flow(
    _.prop("user_actions"),
    _.filter({status: "APPROVED", user: {email: currentUser.email}}),
    _.orderBy("action_timestamp", "desc"),
    _.head,
    _.prop("action_timestamp"),
    isoDate => _.toNumber(new Date(isoDate))
  )

  const lastTimeRequestAction = lastTimeUserAction(requestAction)
  const lastTimeDesistAction = lastTimeUserAction(desistAction)
  const hasBeenDesisted = !_.isNaN(lastTimeDesistAction) && lastTimeDesistAction > lastTimeRequestAction

  const canComplete =
    !isMyPendingComplete &&
    !hasBeenDesisted &&
    _.indexOf(currentUser.email)(requestedUserEmails) >= 0;

  const onSubmit = () => {
    const errors = validationErrors(fields, form);
    if (!_.isEmpty(errors)) {
      setFormErrors(errors);
      return false;
    }
    create({
      ...formatValue(fields, form),
      user: currentUser,
      action: completeAction,
      lo_id: learningObject.id,
    });
  };
  return canComplete ? (
    <ButtonModal
      fullWidth
      buttonText="Completar!"
      modalTitle={learningObject.name}
      onSubmit={onSubmit}
    >
      <FormBody
        value={form}
        setValue={setForm}
        fields={fields}
        errors={formErrors}
      />
    </ButtonModal>
  ) : isMyPendingComplete ? (
    <div className={classes.requested}>Completitud en revisión</div>
  ) : null;
};

export default CompleteButton;
