import _ from "lodash/fp";
import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { createUserAction } from "scores/routines";

import ButtonModal from "common/ButtonModal";
import FormBody from "common/FormBody";
import { validationErrors, formatValue } from "common/formUtils";

const fields = [
  {
    field: "metadata.proof",
    label: "Añada el enlace al certificado o prueba de la finalización",
    required: true,
  },
  {
    field: "metadata.end_date",
    type: "date",
    label: "Fecha de expiración",
  },
  {
    field: "metadata.rate",
    type: "rating",
    label: "Valoración",
    required: true,
  },
  {
    field: "metadata.comment",
    label: "Comparte tus comentarios acerca del curso",
    multiline: true,
    required: true,
  },
];

export default ({ learningObject }) => {
  const [form, setForm] = useState({});
  const [formErrors, setFormErrors] = useState();
  const currentUser = useSelector(_.get("currentUser"));
  const dispatch = useDispatch();
  const { create } = bindActionCreators({ create: createUserAction }, dispatch);

  if (_.prop("type")(learningObject) !== "mooc") return null;

  const disabled = _.flow(
    _.get("actions"),
    _.filter(
      ({ action_type }) =>
        action_type === "request" ||
        action_type === "complete" ||
        action_type === "out_complete"
    ),
    _.any(
      _.flow(
        _.get("user_actions"),
        _.map("user.id"),
        _.any((id) => id === _.get("id")(currentUser))
      )
    )
  )(learningObject);

  const outCompleteAction = _.flow(
    _.get("actions"),
    _.filter({ action_type: "out_complete" }),
    _.orderBy("inserted_at", "desc"),
    _.head,
    _.defaultTo({})
  )(learningObject);

  const onSubmit = () => {
    const errors = validationErrors(fields, form);
    if (!_.isEmpty(errors)) {
      setFormErrors(errors);
      return false;
    }
    create({
      ...formatValue(fields, form),
      user: currentUser,
      action: outCompleteAction,
      lo_id: learningObject.id,
    });
  };
  return !disabled ? (
    <ButtonModal
      fullWidth
      buttonText="Ya lo había hecho"
      modalTitle={learningObject.name}
      onSubmit={onSubmit}
    >
      <FormBody
        value={form}
        setValue={setForm}
        fields={fields}
        errors={formErrors}
      />
    </ButtonModal>
  ) : null;
};
