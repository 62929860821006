import _ from "lodash/fp";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { linkTo } from "learning_objects/routes";

import Moment from "react-moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";

import ActionTypeChip from "./ActionTypeChip";
import UserCompact from "accounts/components/UserCompact";
import { UpdateUserActionStatusButton } from "./userActionButtons";

const useStyles = makeStyles({
  points_list_item: {
    display: "block",
    fontSize: "smaller",
  },
  lo_cell: {
    cursor: "pointer",
    "&:hover": {
      background: "#efefef",
    },
  },
});

const UserPendingActions = ({ userActions }) => {
  const classes = useStyles();
  const history = useHistory();

  const ordered = _.orderBy(["action_timestamp"], ["desc"])(userActions);
  return (
    <>
      <h1>Solicitudes pendientes de aprobación</h1>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {ordered.map((userAction, idx) => {
              const { action, action_timestamp, creator } = userAction;
              return (
                <TableRow hover key={idx}>
                  <TableCell>
                    <UserCompact user={creator} />
                  </TableCell>
                  <TableCell>
                    <ActionTypeChip action_type={action.action_type} />
                  </TableCell>
                  <TableCell
                    className={action.learning_object ? classes.lo_cell : ""}
                    onClick={() => {
                      if (action.learning_object)
                        history.push(
                          linkTo.LEARNING_OBJECT(action.learning_object)
                        );
                    }}
                  >
                    {_.path("learning_object.name")(action)}
                  </TableCell>
                  <TableCell>
                    <Moment date={action_timestamp} format="DD/MM/YYYY" />
                  </TableCell>
                  <TableCell>
                    <UpdateUserActionStatusButton userAction={userAction} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default connect(_.pick(["userActions"]))(UserPendingActions);
