import React from "react";
import { useDispatch } from "react-redux";
import { deleteUserAction } from "scores/routines";

import ButtonModal from "common/ButtonModal";
import DeleteIcon from "@material-ui/icons/Delete";

export default ({
  userActionId,
  userId,
  confirmMessage = "Quieres borrar la acción del usuario?",
}) => {
  const dispatch = useDispatch();

  const handleDeleteUserAction = () =>
    dispatch(deleteUserAction({ id: userActionId, user_id: userId }));

  return (
    <ButtonModal
      buttonIcon={<DeleteIcon />}
      modalTitle="Borrar acción?"
      onSubmit={handleDeleteUserAction}
    >
      {confirmMessage}
    </ButtonModal>
  );
};
