import _ from 'lodash/fp'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import VisibilityIcon from '@material-ui/icons/Visibility'

const useStyles = makeStyles({
  root: { display: 'flex', color: '#555' },
  item: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
    padding: '3px',
  },
  icon: { marginRight: '3px', display: 'flex', alignItems: 'center' },
})

export default ({ learningObject, showItem }) => {
  const classes = useStyles()

  const activeCount = (
    <div className={classes.item}>
      <div className={classes.icon}>
        <AssignmentIndIcon fontSize="small" />
      </div>
      {_.get('activeCount')(learningObject)}
    </div>
  )
  const completeCount = (
    <div className={classes.item}>
      <div className={classes.icon}>
        <AssignmentTurnedInIcon fontSize="small" />
      </div>
      {_.get('completeCount')(learningObject)}
    </div>
  )
  const accessCount = (
    <div className={classes.item}>
      <div className={classes.icon}>
        <VisibilityIcon fontSize="small" />
      </div>
      {_.get('accessCount')(learningObject)}
    </div>
  )
  if (!showItem)
    return (
      <div className={classes.root}>
        {_.get('activeCount')(learningObject) !== 0 && activeCount}
        {_.get('completeCount')(learningObject) !== 0 && completeCount}
        {_.get('accessCount')(learningObject) !== 0 && accessCount}
      </div>
    )
  switch (showItem) {
    case 'active':
      return activeCount
    case 'complete':
      return completeCount
    default:
      return accessCount
  }
}
