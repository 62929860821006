import React from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import GenericForm from "common/GenericForm";
import { createAction } from "../routines";

const useStyles = makeStyles({
  form: {
    margin: "15px 0px",
    padding: "20px",
  },
});

const formFields = [
  { field: "label", label: "Nombre", required: true },
  { field: "action_type", label: "Tipo de la acción", required: true },
  { field: "points", label: "Puntos", type: "points" },
];

export default ({ onSubmit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = ({ points, label, action_type }) => {
    dispatch(createAction({ ...points, label, action_type }));
    onSubmit();
  };

  return (
    <Paper className={classes.form}>
      <GenericForm
        fields={formFields}
        onSubmit={handleSubmit}
        onCancel={onSubmit}
        submitText="Crear recompensa"
      />
    </Paper>
  );
};
