import _ from 'lodash/fp'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core'
import RatingInput from 'common/RatingInput'
import ChipInput from 'material-ui-chip-input'
import { UsersSelector } from 'accounts/components'
import { PointsInput } from 'scores/components'
import { LearningObjectSelector } from 'learning_objects/components'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControl-root': {
      margin: theme.spacing(1, 0),
    },
    '& .MuiButton-root': {
      margin: theme.spacing(1, 0),
    },
    alignContent: 'center',
  },
}))

export default ({ fields, setValue, value: item, errors }) => {
  const classes = useStyles()
  const isNilOrEmpty = (value) => _.isNil(value) || value === ''

  const onChange = ({ target: { name, value } }) => setValue(_.set(name, value))

  const getPropValue = (prop, defValue = '') => {
    const value = _.getOr(defValue, prop)(item)
    return isNilOrEmpty(value) ? defValue : value
  }
  const getDateValue = (prop) => {
    const value = _.getOr('', prop)(item)
    const parsedValue = _.includes('T')(value)
      ? _.flow(_.split('T'), _.first)(value)
      : value
    return isNilOrEmpty(value) ? '' : parsedValue
  }

  const baseProps = (field) => ({
    variant: 'outlined',
    fullWidth: true,
    error: _.has(field)(errors),
    helperText: _.prop(field)(errors),
  })
  const fieldProps = (field) => ({
    name: field,
    value: getPropValue(field),
    onChange,
  })
  const genericProps = (field) => ({
    ...baseProps(field),
    ...fieldProps(field),
  })
  const buildTextField = (field, key, props) => (
    <TextField key={key} {...genericProps(field)} {...props} />
  )
  const buildCheckboxField = (field, key, { label, required }) => (
    <FormControlLabel
      key={key}
      labelPlacement="start"
      label={label}
      control={
        <Checkbox
          required={required}
          name={field}
          checked={getPropValue(field, false)}
          onChange={(ev) =>
            onChange({ target: { name: field, value: ev.target.checked } })
          }
        />
      }
    />
  )
  const buildRatingInput = (field, key, props) => (
    <RatingInput key={key} {...genericProps(field)} {...props} />
  )
  const buildSelectField = (field, key, { label, required, options = [] }) => (
    <FormControl
      key={key}
      required={required}
      variant="outlined"
      fullWidth
      error={_.has(field)(errors)}
    >
      <InputLabel id={`form-label-${key}`}>{label}</InputLabel>
      <Select
        labelId={`form-label-${key}`}
        label={label}
        {...fieldProps(field)}
      >
        {options.map(({ text, value }, key) => (
          <MenuItem value={value} key={key}>
            {text}
          </MenuItem>
        ))}
      </Select>
      {_.has(field)(errors) && (
        <FormHelperText>{_.prop(field)(errors)}</FormHelperText>
      )}
    </FormControl>
  )
  const buildChipsField = (field, key, props) => (
    <ChipInput
      key={key}
      {...baseProps(field)}
      defaultValue={getPropValue(field, [])}
      onChange={(value) => onChange({ target: { name: field, value } })}
      {...props}
    />
  )
  const buildDateField = (field, key, props) => (
    <TextField
      key={key}
      {...genericProps(field)}
      {...props}
      type="date"
      InputLabelProps={{
        shrink: true,
      }}
      value={getDateValue(field)}
    />
  )
  const buildUsersSelector = (field, key, props) => (
    <UsersSelector
      key={key}
      {...genericProps(field)}
      {...props}
      value={getPropValue(field, [])}
    />
  )
  const buildLearningObjectSelector = (field, key, props) => (
    <LearningObjectSelector
      key={key}
      {...genericProps(field)}
      {...props}
      value={getPropValue(field, [])}
    />
  )
  const buildPointsInput = (field, key, props) => (
    <PointsInput
      key={key}
      {...genericProps(field)}
      {...props}
      value={getPropValue(field, [])}
    />
  )

  return (
    <div className={classes.root}>
      {fields.map(
        (
          { field, label, required, type, multiline, options, showOnly },
          key
        ) => {
          switch (type) {
            case 'select':
              return buildSelectField(field, key, { label, required, options })
            case 'chips':
              return buildChipsField(field, key, { label, required })
            case 'checkbox':
              return buildCheckboxField(field, key, { label, required })
            case 'date':
              return buildDateField(field, key, { label, required })
            case 'users':
              return buildUsersSelector(field, key, { label, required })
            case 'learning_object':
              return buildLearningObjectSelector(field, key, {
                label,
                required,
              })
            case 'points':
              return buildPointsInput(field, key, {
                label,
                required,
                showOnly,
              })
            case 'rating':
              return buildRatingInput(field, key, { label, required })
            default:
              return buildTextField(field, key, {
                label,
                required,
                multiline,
                rows: multiline ? 4 : null,
              })
          }
        }
      )}
    </div>
  )
}
