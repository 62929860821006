import {
  clearUserActions,
  fetchUserActions,
  createUserAction,
  deleteUserAction,
} from "../routines";

const creatingUserAction = (state = false, { type }) => {
  switch (type) {
    case createUserAction.TRIGGER:
      return true;
    case createUserAction.FULFILL:
      return false;
    default:
      return state;
  }
};

const deletingUserAction = (state = false, { type }) => {
  switch (type) {
    case deleteUserAction.TRIGGER:
      return true;
    case deleteUserAction.FULFILL:
      return false;
    default:
      return state;
  }
};

const fetchingUserActions = (state = false, { type }) => {
  switch (type) {
    case fetchUserActions.TRIGGER:
      return true;
    case fetchUserActions.FULFILL:
      return false;
    default:
      return state;
  }
};

const initialState = [];

const userActions = (state = initialState, { type, payload }) => {
  switch (type) {
    case fetchUserActions.SUCCESS:
      return payload.data;
    case clearUserActions.TRIGGER:
      return initialState;
    default:
      return state;
  }
};

export {
  creatingUserAction,
  deletingUserAction,
  userActions,
  fetchingUserActions,
};
