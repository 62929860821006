import _ from "lodash/fp";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { scores } from "../constants";

const useStyles = makeStyles({
  root: {
    display: "flex",
    margin: "5px 0px",
  },
  field: {
    width: "110px",
  },
});

export default ({ value, onChange, showOnly, error }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(!expanded);

  const handleChange = ({ target: { name, value: evValue } }) => {
    onChange({ ...value, [name]: evValue });
  };

  const buildField = ({ prop, name, icon }, idx) => (
    <TextField
      error={error}
      className={classes.field}
      key={idx}
      size="small"
      name={prop}
      value={_.getOr(0, prop)(value)}
      onChange={handleChange}
      label={name}
      icon={icon}
      variant="outlined"
    />
  );
  const filteredScores = _.reject(
    ({ prop }) => showOnly && !expanded && _.indexOf(prop)(showOnly) < 0
  )(scores);
  return (
    <div className={classes.root}>
      {filteredScores.map(buildField)}
      {showOnly && (
        <Button variant="outlined" size="small" onClick={toggleExpanded}>
          {expanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </Button>
      )}
    </div>
  );
};
