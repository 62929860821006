import _ from "lodash/fp";
import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createLearningObjectAction } from "../routines";
import { useHistory } from "react-router-dom";
import { linkTo } from "learning_objects/routes";

import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Button } from "@material-ui/core";

import FormBody from "common/FormBody";
import { validationErrors, formatValue } from "common/formUtils";
import { getActionTypeText } from "../constants";

const useStyles = makeStyles({
  card: {
    width: "100%",
    marginTop: "5px",
  },
  button: {
    padding: "15px",
  },
});

const getDefaultSpeakPoints = (lo) => {
  const subtype = _.prop("metadata.subtype")(lo);
  switch (subtype) {
    case "nova_course":
      return {
        learning_points: 300,
        experience_points: 25,
        community_points: 20,
        communication_points: 20,
        zircoins: 20,
      };
    case "lightning_talk":
      return {
        learning_points: 125,
        experience_points: 5,
        community_points: 20,
        communication_points: 5,
        zircoins: 5,
      };
    default:
      return {
        learning_points: 250,
        experience_points: 10,
        community_points: 40,
        communication_points: 10,
        zircoins: 10,
      };
  }
};

const getDefaultAtendPoints = (lo) => {
  const subtype = _.prop("metadata.subtype")(lo);
  switch (subtype) {
    case "nova_course":
      return {
        learning_points: 100,
        zircoins: 4,
      };
    case "lightning_talk":
      return {
        learning_points: 25,
        community_points: 5,
      };
    default:
      return {
        learning_points: 50,
        community_points: 10,
      };
  }
};

const NovaTalksActionsForm = ({
  createLearningObjectAction,
  learningObject,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const speak = getDefaultSpeakPoints(learningObject);
  const atend = getDefaultAtendPoints(learningObject);

  const [form, setForm] = useState({
    speak,
    atend,
  });
  const [formErrors, setFormErrors] = useState();

  const fields = [
    {
      field: "speak",
      label: getActionTypeText("speak"),
      type: "points",
      showOnly: [
        "learning_points",
        "experience_points",
        "community_points",
        "communication_points",
        "zircoins",
      ],
    },
    {
      field: "atend",
      label: getActionTypeText("atend"),
      type: "points",
      showOnly: ["learning_points", "community_points"],
    },
  ];

  const onSubmit = () => {
    const errors = validationErrors(fields, form);
    if (!_.isEmpty(errors)) {
      setFormErrors(errors);
      return false;
    }

    const { speak, atend } = formatValue(fields, form);

    const lo_id = _.prop("id")(learningObject);
    createLearningObjectAction({
      lo_id,
      action: {
        ...speak,
        action_type: "speak",
      },
    });
    createLearningObjectAction({
      lo_id,
      action: {
        ...atend,
        action_type: "atend",
      },
    });
    history.push(linkTo.LEARNING_OBJECT(learningObject));
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <FormBody
          value={form}
          setValue={setForm}
          fields={fields}
          errors={formErrors}
        />
        <Button variant="outlined" fullWidth onClick={onSubmit}>
          Confirmar Nova Talk
        </Button>
      </CardContent>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createLearningObjectAction }, dispatch);

export default connect(null, mapDispatchToProps)(NovaTalksActionsForm);
