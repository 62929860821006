import _ from 'lodash/fp'
import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  useQuery,
  gql
} from "@apollo/client";

import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import routes from '../routes'

import NovaPathItem from './NovaPathItem'
import orderingFields from './novaTalksOrdering'
import useOrderBy from 'common/orderer'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  content: {
    width: '100%',
    maxWidth: '1000px',
  },
  addButton: {
    float: 'right',
    marginRight: '15px',
    marginTop: '15px',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    margin: '5px 15px',
    padding: '15px',
    cursor: 'pointer',
    display: 'flex',
  },
  image: { height: '100px' },
  title: {
    fontSize: '30px',
  },
  description: {
    color: 'gray',
    whiteSpace: 'pre',
    padding: '5px 15px',
  },
})

const LEARNING_OBJECTS = gql`
  query (
    $filter: LearningObjectFilter,
    $orderBy: LearningObjectOrder
  ){
    learningObjects(
      filter: $filter,
    	orderBy: $orderBy
    ) {
      id
      name
      tags
      badgesIds
      rateAverage
      rateCount
      activeCount
      completeCount
      accessCount
      publish_date
      metadata {
        speakers {
          id
          given_name
          family_name
          email
          picture
        }
      }
      requestCost {
        zircoins
      }
    }
  }
`

export default () => {
  const classes = useStyles()
  const history = useHistory()

  let filter = {
    type: 'nova_path',
    active: true,
  }
  const { orderBySelector, orderBy } = useOrderBy(orderingFields, 'most_recent')

  const { data: learningObjectsData } = useQuery(
    LEARNING_OBJECTS,
    {variables: {
      filter,
      orderBy
    },
    fetchPolicy: "network-only"
  })
  
  const learningObjects = _.getOr([], 'learningObjects')(learningObjectsData)

  const navigateNew = () => history.push(routes.NOVA_PATHS_NEW)

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Fab
          variant="extended"
          onClick={navigateNew}
          className={classes.addButton}
          color="primary"
        >
          <AddIcon />
          Crear un Nova Path
        </Fab>
        <h1>Nova Paths</h1>
        {orderBySelector}
        <div className={classes.list}>
          {learningObjects.map((lo, key) => (
            <NovaPathItem key={key} learningObject={lo} />
          ))}
        </div>
      </div>
    </div>
  )
}
