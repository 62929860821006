import React from "react";
import { useDispatch } from "react-redux";
import { deleteAction } from "scores/routines";

import ButtonModal from "common/ButtonModal";

export default ({
  actionId,
  learningObject,
  confirmMessage = "Quieres borrar la acción?",
}) => {
  const dispatch = useDispatch();

  const handleDeleteAction = () =>
    dispatch(deleteAction({ id: actionId, lo_id: learningObject.id }));

  return (
    <ButtonModal
      buttonText={"Borrar"}
      modalTitle="Borrar acción?"
      onSubmit={handleDeleteAction}
    >
      {confirmMessage}
    </ButtonModal>
  );
};
