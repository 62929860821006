import _ from "lodash/fp";
import React from "react";
import useBadges from 'queries/badges'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  badges: { display: "flex", alignItems: "center" },
  badge: {
    margin: "2px",
    padding: "2px",
  },
  badge_clickable: {
    cursor: "pointer",
    "&:hover": {
      filter: "grayscale(0.5)",
    },
  },
  inactive: {
    filter: "grayscale(1)",
  },
  active: {
    filter: "none",
    border: "2px solid #4050b5",
    borderRadius: "3px",
  },
});

export default ({ selectedBadges, onChange, target = "user" }) => {
  const classes = useStyles()
  const {badges} = useBadges()

  const orderedBadges = _.flow(
    _.filter({ target }),
    _.orderBy(["order"], ["asc"])
  )(badges)

  const selectBadge = (id) => {
    const hasBadge = _.indexOf(id)(selectedBadges) >= 0;
    const newBadges = _.flow(
      _.reject((item) => item === id),
      _.concat(hasBadge ? [] : [id])
    )(selectedBadges)
    onChange(newBadges)
  };

  return (
    <div className={classes.badges}>
      {orderedBadges.map(({ image, name, id }, key) => (
        <div
          key={key}
          className={`${classes.badge} ${classes.badge_clickable} ${
            _.indexOf(id)(selectedBadges) >= 0
              ? classes.active
              : classes.inactive
          }`}
          title={name}
          onClick={() => selectBadge(id)}
        >
          <img src={image} width="48px" alt={name} />
        </div>
      ))}
    </div>
  );
};
