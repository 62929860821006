import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteLearningObject } from "../routines";

import ButtonModal from "common/ButtonModal";

export default ({
  learningObjectId,
  confirmMessage = "Al borrar el Learning Object se borrarán todas las acciones asociadas",
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDeleteUserAction = () => {
    dispatch(deleteLearningObject(learningObjectId));
    history.push("/");
  };

  return (
    <ButtonModal
      buttonText="Borrar"
      modalTitle="Borrar Learning Object?"
      onSubmit={handleDeleteUserAction}
      compact
      fullWidth
    >
      {confirmMessage}
    </ButtonModal>
  );
};
