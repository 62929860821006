import _ from "lodash/fp";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import UserCompact from "./UserCompact";
import { fetchUsers } from "../routines";
import { lowerDeburrTrim } from "common/filters";

const UsersSelector = ({ onChange, value, name, ...props }) => {
  const users = useSelector(_.get("users"));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleChange = (_e, newValue) => {
    if (typeof onChange === "function")
      onChange({ target: { name, value: newValue } });
  };

  const filterOptions = (users, { inputValue }) =>
    _.flow(
      _.filter(
        _.flow(
          _.at(["family_name", "given_name", "email"]),
          _.map(lowerDeburrTrim),
          _.some(_.includes(inputValue))
        )
      ),
      _.take(5)
    )(users);

  return (
    <Autocomplete
      multiple
      options={users}
      onChange={handleChange}
      value={value}
      filterOptions={filterOptions}
      getOptionLabel={(user) => <UserCompact user={user} readOnly />}
      filterSelectedOptions
      renderInput={(params) => <TextField {...props} {...params} />}
    />
  );
};

export default UsersSelector;
