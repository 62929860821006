import _ from "lodash/fp";
import { apiGet, apiPost, apiPatch, apiDelete, JSON_OPTS } from "api";
import pathToRegexp from "path-to-regexp";
import {
  fetchLearningObject,
  fetchLearningObjects,
  createLearningObject,
  updateLearningObject,
  updateLOCode,
  deleteLearningObject,
  updateLearningObjectBadges,
} from "../routines";
import {
  LEARNING_OBJECTS,
  LEARNING_OBJECT,
  API_LEARNING_OBJECT_BADGES,
} from "../api";
import { all, call, put, takeLatest } from "redux-saga/effects";

export function* learningObjectsRequestSaga() {
  yield all([
    takeLatest(fetchLearningObject.TRIGGER, fetchLearningObjectSaga),
    takeLatest(fetchLearningObjects.TRIGGER, fetchLearningObjectsSaga),
    takeLatest(createLearningObject.SUCCESS, fetchLearningObjectsSaga),
    takeLatest(deleteLearningObject.SUCCESS, fetchLearningObjectsSaga),

    takeLatest(createLearningObject.TRIGGER, createLearningObjectSaga),
    takeLatest(updateLearningObject.TRIGGER, updateLearningObjectSaga),
    takeLatest(updateLOCode.TRIGGER, updateLOCodeSaga),
    takeLatest(deleteLearningObject.TRIGGER, deleteLearningObjectSaga),
    takeLatest(
      updateLearningObjectBadges.TRIGGER,
      updateLearningObjectBadgesSaga
    ),
  ]);
}

/**
 * INDEX
 */
export function* fetchLearningObjectsSaga({ payload }) {
  try {
    const type = _.prop("type")(payload);
    const active = _.prop("active")(payload) ? "true" : "false";
    const activeQuery = `active=${active}`;
    const queryParams = type ? `type=${type}&${activeQuery}` : activeQuery;
    const url = LEARNING_OBJECTS + `?${queryParams}`;
    yield put(fetchLearningObjects.request());
    const { data } = yield call(apiGet, url, JSON_OPTS);
    yield put(fetchLearningObjects.success(data));
  } catch (error) {
    yield put(fetchLearningObjects.failure(error.response));
  } finally {
    yield put(fetchLearningObjects.fulfill());
  }
}

/**
 * SHOW
 */
export function* fetchLearningObjectSaga({ payload }) {
  try {
    const url = pathToRegexp.compile(LEARNING_OBJECT)({ id: payload.id });
    yield put(fetchLearningObject.request());
    const { data } = yield call(apiGet, url, JSON_OPTS);
    yield put(fetchLearningObject.success(data));
  } catch (error) {
    yield put(fetchLearningObject.failure(error.response));
  } finally {
    yield put(fetchLearningObject.fulfill());
  }
}

/**
 * CREATE
 */
export function* createLearningObjectSaga({ payload }) {
  try {
    const url = LEARNING_OBJECTS;
    yield put(createLearningObject.request());
    const { data: learningObject } = yield call(
      apiPost,
      url,
      { learning_object: payload },
      JSON_OPTS
    );
    yield put(createLearningObject.success(learningObject));
  } catch (error) {
    yield put(createLearningObject.failure(error.response));
  } finally {
    yield put(createLearningObject.fulfill());
  }
}

/**
 * UPDATE
 */
export function* updateLearningObjectSaga({ payload }) {
  try {
    const url = pathToRegexp.compile(LEARNING_OBJECT)({ id: payload.id });
    yield put(updateLearningObject.request());
    const { data: learningObject } = yield call(
      apiPatch,
      url,
      { learning_object: payload },
      JSON_OPTS
    );
    yield put(updateLearningObject.success(learningObject));
    yield put(fetchLearningObject.trigger(payload));
  } catch (error) {
    yield put(updateLearningObject.failure(error.response));
  } finally {
    yield put(updateLearningObject.fulfill());
  }
}

/**
 * UPDATE Learning object points and code
 */
export function* updateLOCodeSaga({ payload }) {
  try {
    const url = pathToRegexp.compile(LEARNING_OBJECT)({ id: payload.id });
    yield put(updateLOCode.request());
    const { data: learningObject } = yield call(
      apiPatch,
      url,
      payload,
      JSON_OPTS
    );
    yield put(updateLOCode.success(learningObject));
    yield put(fetchLearningObject.trigger(payload));
  } catch (error) {
    yield put(updateLOCode.failure(error.response));
  } finally {
    yield put(updateLOCode.fulfill());
  }
}

/**
 * UPDATE BADGES
 */
export function* updateLearningObjectBadgesSaga({ payload: { id, badges } }) {
  try {
    const url = pathToRegexp.compile(API_LEARNING_OBJECT_BADGES)({ id });
    yield put(updateLearningObjectBadges.request());
    const { data: learning_object } = yield call(
      apiPatch,
      url,
      { badges },
      JSON_OPTS
    );
    yield put(updateLearningObjectBadges.success(learning_object));
    yield put(fetchLearningObject.trigger({ id }));
  } catch (error) {
    yield put(updateLearningObjectBadges.failure(error.response));
  } finally {
    yield put(updateLearningObjectBadges.fulfill());
  }
}

/**
 * DELETE
 */
export function* deleteLearningObjectSaga({ payload }) {
  try {
    const url = pathToRegexp.compile(LEARNING_OBJECT)({ id: payload });
    yield put(deleteLearningObject.request());
    yield call(apiDelete, url, JSON_OPTS);
    yield put(deleteLearningObject.success());
  } catch (error) {
    yield put(deleteLearningObject.failure(error.response));
  } finally {
    yield put(deleteLearningObject.fulfill());
  }
}
