import React from "react";

import {
  CompleteButton,
  RequestButton,
  OutCompleteButton,
} from "./userActionButtons";

export default ({ learningObject }) => {
  return (
    <>
      <RequestButton learningObject={learningObject} />
      <CompleteButton learningObject={learningObject} />
      <OutCompleteButton learningObject={learningObject} />
    </>
  );
};
