import _ from "lodash/fp";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Button,
  Typography,
  Divider,
} from "@material-ui/core";

import { UserCompact } from "accounts/components";
import LearningObjectEditButton from "./LearningObjectEditButton";
import parseLoImage from "learning_objects/loImageParser";

const useStyles = makeStyles({
  card: {
    width: "100%",
    // height: 375,
  },
  media: {
    height: 140,
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: "3px",
    },
  },
  statsItem: {
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
  },
  actions: {
    float: "right",
    display: "flex",
    "& .MuiDivider-root": {
      width: "5px",
    },
  },
  detail_row: {
    display: "flex",
    margin: "5px 0px",
  },
  detail_row_title: {
    fontWeight: "bolder",
    marginRight: "5px",
  },
  detail_row_value: {
    color: "#4d4d4d",
  },
  requested_by_title: {
    display: "flex",
  },
  requested_by_title_text: {
    marginRight: "5px",
    fontWeight: "bolder",
  },
  requested_by_reason: { marginLeft: "20px", color: "#4d4d4d" },
});

const LearningObjectPreview = ({ learningObject }) => {
  const classes = useStyles();

  const imageSource = parseLoImage(learningObject);

  const detailFields = [
    {
      field: "metadata.duration",
      label: "Duración:",
      suffix: " horas",
    },
    {
      field: "metadata.real_cost",
      label: "Precio:",
      suffix: "€",
    },
    {
      field: "publish_date",
      label: "Fecha sugerida:",
      suffix: "",
    },
  ];

  return (
    <Card className={classes.card}>
      <CardMedia className={classes.media} image={imageSource} />
      <CardContent>
        {_.has("metadata.code")(learningObject) && (
          <div className={classes.code_tag}>
            #{_.prop("metadata.code")(learningObject)}
          </div>
        )}
        <div className={classes.actions}>
          <LearningObjectEditButton learningObject={learningObject} />
          <Divider orientation="vertical" />
          <Button
            variant="outlined"
            href={learningObject.external_url}
            target="_blank"
            size="small"
            color="primary"
          >
            Enlace externo
          </Button>
        </div>
        <Typography variant="h6" gutterBottom>
          {learningObject.name}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {learningObject.description}
        </Typography>
        {learningObject.tags && (
          <div className={classes.tags}>
            {learningObject.tags.map((t, i) => (
              <Chip key={i} label={t} size="small" />
            ))}
          </div>
        )}
        <div>
          {detailFields.map(({ field, label, suffix }, key) =>
            !_.isNil(_.prop(field)(learningObject)) ? (
              <div className={classes.detail_row} key={key}>
                <div className={classes.detail_row_title}>{label}</div>
                <div className={classes.detail_row_value}>
                  {_.prop(field)(learningObject) + suffix}
                </div>
              </div>
            ) : null
          )}
          {_.has("metadata.speakers")(learningObject) &&
            !_.isNil(_.has("metadata.speakers")(learningObject)) && (
              <div className={classes.detail_row}>
                <div className={classes.detail_row_title}>Ponentes</div>
                <div className={classes.detail_row_value}>
                  {_.get("metadata.speakers")(learningObject).map((user, k) => (
                    <UserCompact key={k} user={user} />
                  ))}
                </div>
              </div>
            )}
        </div>
        <div>
          <div className={classes.requested_by_title}>
            <div className={classes.requested_by_title_text}>
              Solicitado por:
            </div>
            <UserCompact user={_.prop("creator")(learningObject)} />
          </div>
          <div className={classes.requested_by_reason}>
            {_.prop("metadata.reason")(learningObject)}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default LearningObjectPreview;
