import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchBadges } from "../routines";

export default () => {
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    stableDispatch(fetchBadges());
  }, [stableDispatch]);

  return null;
};
