import React, { useState } from 'react'
import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  orderButtons: {
    padding: '15px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  orderTitle: {
    fontSize: '15px',
    fontWeight: 'bold',
    marginRight: '10px',
  },
})

export default (
  { orderFields, orderDirections, orderChips },
  defaultOrderBy
) => {
  const classes = useStyles()
  const [orderBy, setOrderBy] = useState(defaultOrderBy)

  return {
    orderBySelector: (
      <div className={classes.orderButtons}>
        <div className={classes.orderTitle}>Ordenar:</div>
        {orderChips.map(({ key, label }, idx) => (
          <Chip
            key={idx}
            className={classes.order_tag}
            variant={orderBy === key ? 'default' : 'outlined'}
            label={label}
            size="small"
            onClick={() => setOrderBy(key)}
          />
        ))}
      </div>
    ),
    orderBy: {
      field: orderFields[orderBy],
      order: orderDirections[orderBy]
    }
  }
}
