import _ from 'lodash/fp'
import React from 'react'
import moment from 'moment'

import {
  useQuery,
  gql
} from "@apollo/client";

import { makeStyles } from '@material-ui/core/styles'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'
import { UserCompact } from 'accounts/components'
import {
  LearningObjectCompact,
  LearningObjectStatsCompact,
} from 'learning_objects/components'

import Rating from '@material-ui/lab/Rating'

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    maxWidth: '1300px',
  },
  logo: {
    width: '200px',
  },
  column: {
    width: '50%',
    overflow: 'scroll',
    height: 'calc(100vh - 245px)',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  title: { fontSize: '21px', fontWeight: 'bolder', margin: '5px 0px' },
  item: {
    border: '1px solid #ccc',
    borderRadius: '3px',
    boxShadow: '1px 1px 2px 1px #ccc',
    margin: '5px',
    padding: '3px',
  },
  head: {
    display: 'flex',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '125px',
  },
  date: {
    margin: '5px',
  },
  rate: {
    margin: '2px',
    textAlign: 'left',
  },
  user: {
    margin: '5px',
  },
  learning_object: {
    margin: '2px',
  },
  header: {
    display: 'flex',
    // justifyContent: 'space-around',
    alignItems: 'center',
  },
  comment: {
    textAlign: 'left',
    padding: '15px',
    color: '#555',
  },
  top_section: {
    padding: '0px 5px',
    margin: '0px 5px 15px 5px',
    border: '1px solid #ccc',
    borderRadius: '3px',
    boxShadow: '1px 1px 2px 1px #ccc',
  },
  top_item: {
    margin: '2px',
    padding: '1px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  top_metrics: {},
})

const USER_ACTIONS = gql`
  query {
    userActions(
    	limit: 10,
      filter: {
        actionTypes: ["complete", "out_complete"],
        status: "APPROVED",
    	},
    	orderBy: {
        field: ACTION_TIMESTAMP,
        order: DESC
      }
    ) {
      actionTimestamp
      metadata {
        rate
        comment
      }
      action {
        learningObject {
          id
          name
          metadata {
            image
            code
          }
        }
      }
      user {
        id
        given_name
        family_name
        email
        picture
      }
    }
  }
`

const TOP_LOS = gql`
  query {
    learningObjectsTop {
      accessCount {
        id
        name
        metadata {
          image
          code
        }
        accessCount
      }
      completeCount {
        id
        name
        metadata {
          image
          code
        }
        completeCount
      }
      activeCount {
        id
        name
        metadata {
          image
          code
        }
        activeCount
      }
    }
  }
`

export const Home = () => {
  const classes = useStyles()

  const { loading: fetchingUserActions, data: userActions } = useQuery(USER_ACTIONS)
  const { loading: fetchingLearningObjects, data: learningObjects } = useQuery(TOP_LOS)

  const latestApproved = _.getOr([], 'userActions')(userActions)

  const topActive = _.getOr([], 'learningObjectsTop.activeCount')(learningObjects)
  const topComplete = _.getOr([], 'learningObjectsTop.completeCount')(learningObjects)
  const topAccess = _.getOr([], 'learningObjectsTop.accessCount')(learningObjects)

  return (
    <>
      <div className={classes.root}>
        <img className={classes.logo} src="logo_nova.svg" alt="Nova Project" />
        {!fetchingLearningObjects && !fetchingUserActions && (
          <div className={classes.content}>
            <div className={classes.column}>
              <div className={classes.title}>Cursos más activos</div>
              <div className={classes.top_section}>
                {topActive.map((lo, idx) => (
                  <div key={idx} className={classes.top_item}>
                    <LearningObjectCompact learningObject={lo} />
                    <LearningObjectStatsCompact
                      learningObject={lo}
                      showItem="active"
                    />
                  </div>
                ))}
              </div>
              <div className={classes.title}>Cursos más completados</div>
              <div className={classes.top_section}>
                {topComplete.map((lo, idx) => (
                  <div key={idx} className={classes.top_item}>
                    <LearningObjectCompact learningObject={lo} />
                    <LearningObjectStatsCompact
                      learningObject={lo}
                      showItem="complete"
                    />
                  </div>
                ))}
              </div>
              <div className={classes.title}>Cursos más accedidos</div>
              <div className={classes.top_section}>
                {topAccess.map((lo, idx) => (
                  <div key={idx} className={classes.top_item}>
                    <LearningObjectCompact learningObject={lo} />
                    <LearningObjectStatsCompact
                      learningObject={lo}
                      showItem="access"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.column}>
              <div className={classes.title}>Últimos cursos completados</div>
              {(latestApproved || []).map((userAction, idx) => (
                <div key={idx} className={classes.item}>
                  <div className={classes.head}>
                    <div className={classes.col}>
                      <div className={classes.date}>
                        {moment(
                          _.get('actionTimestamp')(userAction)
                        ).fromNow()}
                      </div>
                      <div className={classes.rate}>
                        {_.get('metadata.rate')(userAction) && (
                          <Rating
                            value={_.get('metadata.rate')(userAction) / 2}
                            precision={0.5}
                            readOnly
                          />
                        )}
                      </div>
                    </div>
                    <div className={classes.col}>
                      <div className={classes.user}>
                        <UserCompact user={_.get('user')(userAction)} />
                      </div>
                      <div className={classes.learning_object}>
                        <LearningObjectCompact
                          learningObject={_.get('action.learningObject')(
                            userAction
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  {_.get('metadata.comment')(userAction) && (
                    <div className={classes.comment}>
                      <FormatQuoteIcon />
                      {_.get('metadata.comment')(userAction)}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Home
