import _ from "lodash/fp";
import jwt_decode from "jwt-decode";
import { apiGet, apiPost, JSON_OPTS } from "api";
import { login, fakeLogin, fetchAuthorizationUri } from "../routines";
import {
  API_SESSION,
  API_SESSION_FAKE,
  API_SESSION_AUTHORIZATION_URI,
} from "../api";
import { call, put, takeLatest } from "redux-saga/effects";

export function saveToken(token) {
  localStorage.setItem(process.env.REACT_APP_TOKEN, token);
}

export function* loginSaga({ payload }) {
  try {
    const url = API_SESSION;
    yield put(login.request());

    const body = { token: payload };
    const {
      data: { jwt, user },
    } = yield call(apiPost, url, body, JSON_OPTS);

    yield call(saveToken, jwt);
    const session = yield call(jwt_decode, jwt);
    yield put(login.success({ session, user }));
  } catch (error) {
    yield put(login.failure(error.response));
  } finally {
    yield put(login.fulfill());
  }
}

export function* fakeLoginSaga({ payload: { email } }) {
  try {
    const url = API_SESSION_FAKE;
    yield put(login.request());

    const [given_name, family_name] = email.split("@");
    const body = {
      given_name,
      family_name,
      email,
    };
    const {
      data: { jwt, user },
    } = yield call(apiPost, url, body, JSON_OPTS);

    yield call(saveToken, jwt);
    const session = yield call(jwt_decode, jwt);
    yield put(login.success({ session, user }));
  } catch (error) {
    yield put(login.failure(error.response));
  } finally {
    yield put(login.fulfill());
  }
}

export function* fetchAuthorizationUriSaga() {
  try {
    const url = API_SESSION_AUTHORIZATION_URI;
    yield put(fetchAuthorizationUri.request());
    const data = yield call(apiGet, url, JSON_OPTS);
    const uri = _.path("data.uri")(data);
    yield put(fetchAuthorizationUri.success(uri));
  } catch (error) {
    yield put(fetchAuthorizationUri.failure(error.response));
  } finally {
    yield put(fetchAuthorizationUri.fulfill());
  }
}

export function* loginRequestSaga() {
  yield takeLatest(login.TRIGGER, loginSaga);
  yield takeLatest(fakeLogin.TRIGGER, fakeLoginSaga);
  yield takeLatest(fetchAuthorizationUri.TRIGGER, fetchAuthorizationUriSaga);
}
