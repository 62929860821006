import _ from "lodash/fp";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createLearningObjectAction } from "scores/routines";

import { makeStyles } from "@material-ui/core/styles";

import ButtonModal from "common/ButtonModal";
import { LearningObjectCompact } from "learning_objects/components";
import { ActionTypeChip } from "..";
import FormBody from "common/FormBody";
import { validationErrors, formatValue } from "common/formUtils";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
  },
  headerName: {
    fontSize: "20px",
    fontWeight: "bold",
    marginLeft: "10px",
    marginRight: "10px",
  },
  detail: {
    padding: "20px",
  },
  completeProofButton: {
    marginLeft: "5px",
    textTransform: "none",
  },
}));

export default ({ learningObject, actionType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form, setForm] = useState({});
  const [formErrors, setFormErrors] = useState();
  const onApprove = () => {
    const errors = validationErrors(fields, form);
    if (!_.isEmpty(errors)) {
      setFormErrors(errors);
      return false;
    }

    const action = {
      ..._.getOr({}, "points")(formatValue(fields, form)),
      action_type: actionType,
    };

    dispatch(
      createLearningObjectAction({
        action,
        lo_id: learningObject.id,
      })
    );
  };

  const fields = [{ field: "points", label: "Puntos", type: "points" }];

  return (
    <ButtonModal
      buttonText={"Crear acción"}
      modalTitle="Crear acción del Learning Object"
      onSubmit={onApprove}
    >
      <>
        <div className={classes.header}>
          <ActionTypeChip action_type={actionType} />
          <LearningObjectCompact learningObject={learningObject} />
        </div>
        <div className={classes.detail}>
          <FormBody
            value={form}
            setValue={setForm}
            fields={fields}
            errors={formErrors}
          />
        </div>
      </>
    </ButtonModal>
  );
};
