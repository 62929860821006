import { loginRequestSaga } from "./login";
import { retrieveSessionRequestSaga } from "./retrieveSession";
import { usersRequestSaga } from "./usersSaga";
import { userAccessesRequestSaga } from "./userAccessesSaga";
import { badgesRequestSaga } from "./badgesSaga";

export {
  loginRequestSaga,
  retrieveSessionRequestSaga,
  usersRequestSaga,
  userAccessesRequestSaga,
  badgesRequestSaga,
};
export default [
  loginRequestSaga(),
  retrieveSessionRequestSaga(),
  usersRequestSaga(),
  userAccessesRequestSaga(),
  badgesRequestSaga(),
];
