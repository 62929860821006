import React from 'react'
import { withRouter } from 'react-router-dom'

import { IconButton } from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

const LogoutButton = ({history}) => {
  const onLogout = () => {
    localStorage.removeItem(process.env.REACT_APP_TOKEN)
    history.push('/login')
  }
  return (
    <IconButton onClick={onLogout} color="inherit">
      <ExitToAppIcon />
    </IconButton>
  )
}

export default withRouter(LogoutButton)