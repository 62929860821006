import _ from "lodash/fp";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { linkTo } from "../routes";
import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  button: {
    fontSize: "11px",
    padding: "0px 10px",
  },
  icon: {
    fontSize: "11px",
  },
});

export default ({ user: { id } }) => {
  const { currentUser } = useSelector(_.pick(["currentUser"]));
  const history = useHistory();
  const classes = useStyles();
  const navigate = (e) => {
    e.stopPropagation();
    history.push(linkTo.USER_ACTIONS({ user_id: id }));
  };

  return _.get("is_admin")(currentUser) ? (
    <Button className={classes.button} variant="outlined" onClick={navigate}>
      Editar acciones
    </Button>
  ) : null;
};
