import _ from "lodash/fp";

export default (learningObject) => {
  const imageId =
    _.flow(
      _.prop("id"),
      _.reduce((acc, x) => acc + x.charCodeAt(0), 0)
    )(learningObject) % 10;

  const metadataImage = _.prop("metadata.image")(learningObject);
  return metadataImage ? metadataImage : `/stock_images/${imageId}.jpg`;
};
