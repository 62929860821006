const baseFields = [
  {
    field: "action_timestamp",
    label: "Fecha de la acción",
    type: "date",
  },
];

const getActionFields = (action_type) => {
  switch (action_type) {
    case "request":
      return [
        ...baseFields,
        { field: "metadata.reason", label: "Motivo" },
        { field: "metadata.cost", type: "numeric", label: "Coste €" },
      ];
    case "complete":
      return [
        ...baseFields,
        {
          field: "metadata.end_date",
          type: "date",
          label: "Fecha de expiración",
        },
        { field: "metadata.rate", type: "rating", label: "Valoración" },
        { field: "metadata.comment", label: "Comentarios" },
        { field: "metadata.payment", label: "Fecha pago", type: "date" },
      ];
    case "out_complete":
      return [
        ...baseFields,
        {
          field: "metadata.end_date",
          type: "date",
          label: "Fecha de expiración",
        },
        { field: "metadata.rate", type: "rating", label: "Valoración" },
        { field: "metadata.comment", label: "Comentarios" },
      ];
    default:
      return baseFields;
  }
};

export { getActionFields };
