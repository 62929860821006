import _ from "lodash/fp";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { updateIssue, deleteIssue } from "../routines";

import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import AddIcon from "@material-ui/icons/Add";

import IssueForm from "./IssueForm";
import { UserCompact } from "accounts/components";
import ButtonModal from "common/ButtonModal";

const useStyles = makeStyles({
  addButton: {
    float: "right",
    marginRight: "15px",
    marginTop: "15px",
  },
  list: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    margin: "5px",
    padding: "15px",
    border: "1px solid #ddd",
    borderRadius: "5px",
    display: "flex",
  },
  header: { display: "flex", flexDirection: "column", alignItems: "center" },
  body: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "15px",
    flexGrow: "1",
  },
  actions: { display: "flex", flexDirection: "column", alignItems: "flex-end" },
  title: { fontSize: "18px" },
  content: { fontSize: "15px", marginLeft: "10px" },
});

export default () => {
  const classes = useStyles();
  const {
    issues,
    currentUser: { is_admin },
  } = useSelector(_.pick(["issues", "currentUser"]));
  const dispatch = useDispatch();
  const [openedNew, setOpenedNew] = useState();
  const [editIssue, setEditIssue] = useState();
  const [showClosed, setShowClosed] = useState(false);

  const orderedIssues = _.flow(
    _.orderBy(["inserted_at"], ["desc"]),
    _.filter(({ status }) => showClosed || status !== "CLOSED")
  )(issues);
  const statusTexts = {
    CREATED: "Nuevo",
    SEEN: "Visto",
    CLOSED: "Cerrado",
  };

  const onDeleteIssue = (issueId) => dispatch(deleteIssue(issueId));
  const onMarkSeen = (id) => dispatch(updateIssue({ id, status: "SEEN" }));
  const onMarkClosed = (id) => dispatch(updateIssue({ id, status: "CLOSED" }));

  return (
    <>
      {!openedNew && (
        <Fab
          variant="extended"
          onClick={() => setOpenedNew(true)}
          className={classes.addButton}
          color="primary"
          aria-label="add"
        >
          <AddIcon />
          Crear incidencia/sugerencia
        </Fab>
      )}
      <h1>Incidencias y Sugerencias</h1>
      {openedNew && <IssueForm onComplete={() => setOpenedNew(false)} />}
      {editIssue && (
        <IssueForm edit={editIssue} onComplete={() => setEditIssue()} />
      )}
      <div>
        <Checkbox
          checked={showClosed}
          onChange={() => setShowClosed(!showClosed)}
        />
        Mostrar incidencias cerradas
      </div>
      <div className={classes.list}>
        {orderedIssues.map((issue, idx) => {
          const { id, title, content, status, creator, inserted_at } = issue;
          return (
            <div key={idx} className={classes.row}>
              <div className={classes.header}>
                {is_admin && <UserCompact user={creator} />}
                {moment(inserted_at).format("DD/MM/YYYY")}
                <div>{statusTexts[status]}</div>
              </div>
              <div className={classes.body}>
                <div className={classes.title}>{title}</div>
                <div className={classes.content}>{content}</div>
              </div>
              <div className={classes.actions}>
                {is_admin && status === "CREATED" && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => onMarkSeen(id)}
                  >
                    Marcar como vista
                  </Button>
                )}
                {is_admin && status === "SEEN" && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => onMarkClosed(id)}
                  >
                    Marcar como cerrada
                  </Button>
                )}
                {(is_admin || status === "CREATED") && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setEditIssue(issue)}
                  >
                    Editar
                  </Button>
                )}
                {(is_admin || status === "CREATED") && (
                  <ButtonModal
                    buttonText="Borrar"
                    modalTitle="Borrar incidencia/sugerencia?"
                    onSubmit={() => onDeleteIssue(id)}
                  >
                    Seguro que quiere borrar la incidencia/sugerencia
                  </ButtonModal>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
