import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import UserActionsList from "scores/components/UserActionsList";
import UserInfo from "./UserInfo";
import UserOpenBadges from "./UserOpenBadges";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

export const User = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <UserInfo />
      <UserOpenBadges />
      <UserActionsList />
    </div>
  );
};

export default User;
