import _ from "lodash/fp";
import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchUser } from "../routines";

import { LinearProgress } from "@material-ui/core";

const UserLoader = ({ fetchUser, fetchingUser }) => {
  const { user_id } = useParams();

  useEffect(() => {
    fetchUser({ user_id });
  }, [fetchUser, user_id]);

  return fetchingUser ? <LinearProgress /> : null;
};

const mapStateToProps = _.pick(["fetchingUser"]);

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchUser,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserLoader);
