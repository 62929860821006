import _ from "lodash/fp";
import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchUserActions, clearUserActions } from "../routines";

import { LinearProgress } from "@material-ui/core";

const UserActionsLoader = ({
  fetchUserActions,
  clearUserActions,
  fetchingUserActions,
}) => {
  const { user_id: id } = useParams();

  useEffect(() => {
    fetchUserActions({ id });
    return () => clearUserActions();
  }, [clearUserActions, fetchUserActions, id]);
  return fetchingUserActions ? <LinearProgress /> : null;
};

const mapStateToProps = _.pick(["fetchingUserActions"]);

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchUserActions,
      clearUserActions,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserActionsLoader);
