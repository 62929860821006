import { apiGet, apiPost, apiPatch, apiDelete, JSON_OPTS } from "api";
import pathToRegexp from "path-to-regexp";
import {
  fetchIssues,
  createIssue,
  updateIssue,
  deleteIssue,
} from "../routines";
import { API_ISSUES, API_ISSUE } from "../api";
import { all, call, put, takeLatest } from "redux-saga/effects";

export function* issuesRequestSaga() {
  yield all([
    takeLatest(fetchIssues.TRIGGER, fetchIssuesSaga),
    takeLatest(createIssue.SUCCESS, fetchIssuesSaga),
    takeLatest(updateIssue.SUCCESS, fetchIssuesSaga),
    takeLatest(deleteIssue.SUCCESS, fetchIssuesSaga),

    takeLatest(createIssue.TRIGGER, createIssueSaga),
    takeLatest(updateIssue.TRIGGER, updateIssueSaga),
    takeLatest(deleteIssue.TRIGGER, deleteIssueSaga),
  ]);
}

/**
 * INDEX
 */
export function* fetchIssuesSaga({ payload }) {
  try {
    yield put(fetchIssues.request());
    const { data } = yield call(apiGet, API_ISSUES, JSON_OPTS);
    yield put(fetchIssues.success(data));
  } catch (error) {
    yield put(fetchIssues.failure(error.response));
  } finally {
    yield put(fetchIssues.fulfill());
  }
}

/**
 * CREATE
 */
export function* createIssueSaga({ payload }) {
  try {
    const url = API_ISSUES;
    yield put(createIssue.request());
    const { data: issue } = yield call(
      apiPost,
      url,
      { issue: payload },
      JSON_OPTS
    );
    yield put(createIssue.success(issue));
  } catch (error) {
    yield put(createIssue.failure(error.response));
  } finally {
    yield put(createIssue.fulfill());
  }
}

/**
 * UPDATE
 */
export function* updateIssueSaga({ payload }) {
  try {
    const url = pathToRegexp.compile(API_ISSUE)({ id: payload.id });
    yield put(updateIssue.request());
    const { data: issue } = yield call(
      apiPatch,
      url,
      { issue: payload },
      JSON_OPTS
    );
    yield put(updateIssue.success(issue));
  } catch (error) {
    yield put(updateIssue.failure(error.response));
  } finally {
    yield put(updateIssue.fulfill());
  }
}

/**
 * DELETE
 */
export function* deleteIssueSaga({ payload }) {
  try {
    const url = pathToRegexp.compile(API_ISSUE)({ id: payload });
    yield put(deleteIssue.request());
    yield call(apiDelete, url, JSON_OPTS);
    yield put(deleteIssue.success());
  } catch (error) {
    yield put(deleteIssue.failure(error.response));
  } finally {
    yield put(deleteIssue.fulfill());
  }
}
