import _ from "lodash/fp";
import { apiDelete, apiGet, apiPost, apiPatch, JSON_OPTS } from "api";
import pathToRegexp from "path-to-regexp";
import { USER_ACTIONS, USER_ACTION } from "../api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchUserActions,
  createUserAction,
  updateUserAction,
  deleteUserAction,
  fetchGenericActions,
} from "../routines";
import { fetchLearningObject } from "learning_objects/routines";
import { fetchUser } from "accounts/routines";

export function* userActionsRequestSaga() {
  yield takeLatest(fetchUserActions.TRIGGER, fetchUserActionsSaga);
  yield takeLatest(createUserAction.TRIGGER, createUserActionSaga);
  yield takeLatest(updateUserAction.TRIGGER, updateUserActionSaga);
  yield takeLatest(deleteUserAction.TRIGGER, deleteUserActionSaga);
}

export function* fetchUserActionsSaga({ payload }) {
  try {
    const url = pathToRegexp.compile(USER_ACTIONS)({ id: payload.id });
    const { pending, actionTypes } = payload;
    const queries = _.concat(
      pending ? ["pending=true"] : [],
      actionTypes ? [`action_types=${actionTypes.join(",")}`] : []
    );
    const query = _.isEmpty(queries) ? "" : "?" + queries.join("&");
    yield put(fetchUserActions.request());
    const { data } = yield call(apiGet, url + query, JSON_OPTS);
    yield put(fetchUserActions.success(data));
  } catch (error) {
    yield put(fetchUserActions.failure(error.response));
  } finally {
    yield put(fetchUserActions.fulfill());
  }
}

export function* createUserActionSaga({ payload }) {
  try {
    const {
      user,
      action: { id: action_id },
      lo_id,
      refreshUserActions,
      metadata,
      action_timestamp,
    } = payload;
    const url = pathToRegexp.compile(USER_ACTIONS)(user);

    yield put(createUserAction.request());
    const { data } = yield call(
      apiPost,
      url,
      { user_action: { action_id, metadata, action_timestamp } },
      JSON_OPTS
    );
    yield put(createUserAction.success(data));
    if (lo_id) yield put(fetchLearningObject.trigger({ id: lo_id }));
    else if (refreshUserActions) yield put(fetchUserActions.trigger(user));
    else yield put(fetchGenericActions.trigger());
  } catch (error) {
    yield put(createUserAction.failure(error.response));
  } finally {
    yield put(createUserAction.fulfill());
  }
}

export function* updateUserActionSaga({ payload }) {
  try {
    const { user_action, fetchActionTypes, user, refreshUserActions } = payload;
    const url = pathToRegexp.compile(USER_ACTION)(user_action);
    yield put(updateUserAction.request());
    const { data } = yield call(apiPatch, url, { user_action }, JSON_OPTS);
    yield put(updateUserAction.success(data));
    if (fetchActionTypes)
      yield put(
        fetchUserActions.trigger({ id: "all", actionTypes: fetchActionTypes })
      );
    else if (refreshUserActions) yield put(fetchUserActions.trigger(user));
    else yield put(fetchUserActions.trigger({ id: "all", pending: true }));
  } catch (error) {
    yield put(updateUserAction.failure(error.response));
  } finally {
    yield put(updateUserAction.fulfill());
  }
}

export function* deleteUserActionSaga({ payload }) {
  try {
    const { lo_id, user_id } = payload;
    const url = pathToRegexp.compile(USER_ACTION)(payload);
    yield put(deleteUserAction.request());
    const { data } = yield call(apiDelete, url, JSON_OPTS);
    yield put(deleteUserAction.success(data));

    if (lo_id) yield put(fetchLearningObject.trigger({ id: lo_id }));
    else if (user_id) {
      yield put(fetchUserActions.trigger({ id: user_id }));
      yield put(fetchUser.trigger({ user_id: user_id }));
    } else yield put(fetchGenericActions.trigger());
  } catch (error) {
    yield put(deleteUserAction.failure(error.response));
  } finally {
    yield put(deleteUserAction.fulfill());
  }
}
