import _ from "lodash/fp";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";

import { UserCompact } from "accounts/components";
import ActionTypeChip from "./ActionTypeChip";
import PointsCompact from "./PointsCompact";
import { createUserAction, deleteUserAction } from "../routines";
import { lowerDeburrTrim } from "common/filters";

const useStyles = makeStyles({
  root: {
    margin: "15px 0px",
    padding: "20px",
  },
  lists: {
    display: "flex",
    "& .MuiIconButton-root": {
      padding: "0px",
      marginRight: "2px",
    },
    overflow: "scroll",
  },
  deleteButton: {
    color: "#ff5d5d",
  },
  addButton: {
    color: "#59b459",
  },
});

export default () => {
  const classes = useStyles();
  const [userFilter, setUserFilter] = useState("");
  const { learningObject, users } = useSelector(
    _.pick(["learningObject", "users"])
  );
  const dispatch = useDispatch();

  const lo_id = _.get("id")(learningObject);

  const handleUserFilter = ({ target }) => setUserFilter(target.value);

  const handleDeleteUserAction = (user_action) =>
    dispatch(deleteUserAction({ ...user_action, lo_id }));

  const handleCreateUserAction = (user, action) =>
    dispatch(createUserAction({ user, action, lo_id }));

  const actions = _.flow(
    _.get("actions"),
    _.map((a) => {
      const filteredUserActions = _.flow(
        _.get("user_actions"),
        _.filter(
          _.flow(
            _.at(["user.given_name", "user.family_name", "user.email"]),
            _.map(lowerDeburrTrim),
            _.some(_.includes(userFilter))
          )
        ),
        _.orderBy(["action_timestamp"], ["desc"])
      )(a);
      const assignedUsers = _.flow(
        _.map("user.id"),
        _.uniq
      )(filteredUserActions);
      const missingUsers = _.flow(
        _.filter(
          _.flow(
            _.at(["name", "email"]),
            _.map(lowerDeburrTrim),
            _.some(_.includes(userFilter))
          )
        ),
        _.reject(({ id }) => _.includes(id)(assignedUsers)),
        _.orderBy(["email"], ["asc"])
      )(users);

      return { ...a, filteredUserActions, missingUsers };
    })
  )(learningObject);

  return (
    <Paper className={classes.root}>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        onChange={handleUserFilter}
        value={userFilter}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.lists}>
        {actions.map((action, key) => {
          const {
            action_type,
            filteredUserActions,
            missingUsers,
            inserted_at,
          } = action;
          return (
            <List key={key} dense>
              <ListItem>
                {moment(inserted_at).format("DD/MM/YYYY")}
                <ActionTypeChip action_type={action_type} />
                <PointsCompact points={action} />
              </ListItem>
              {filteredUserActions.map(({ user, id }, key) => (
                <ListItem key={key}>
                  <Tooltip title="Eliminar">
                    <IconButton
                      className={classes.deleteButton}
                      onClick={() => handleDeleteUserAction({ id })}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <UserCompact user={user} />
                </ListItem>
              ))}
              {_.take(5)(missingUsers).map((user, userKey) => (
                <ListItem key={userKey}>
                  <Tooltip title="Asignar">
                    <IconButton
                      className={classes.addButton}
                      onClick={() => handleCreateUserAction(user, action)}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </Tooltip>
                  <UserCompact user={user} />
                  <PointsCompact points={user.points} />
                </ListItem>
              ))}
            </List>
          );
        })}
      </div>
    </Paper>
  );
};
