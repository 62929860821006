import React from "react";
import LiveviewIframe from "./LiveviewIframe";

export default function SystemDashboard() {
  return (
    <>
      Request logger token:
      <input
        onChange={(e) =>
          localStorage.setItem("requestLoggerToken", e.target.value)
        }
      />
      <LiveviewIframe key={"init_dashboard"} route={"init_dashboard"} />
    </>
  );
}
