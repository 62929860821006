import { createRoutine } from "redux-saga-routines";

export const fetchGenericActions = createRoutine("FETCH_GENERIC_ACTIONS");

export const createAction = createRoutine("CREATE_ACTION");
export const deleteAction = createRoutine("DELETE_ACTION");
export const clearUserActions = createRoutine("CLEAR_USER_ACTIONS");
export const fetchUserActions = createRoutine("FETCH_USER_ACTIONS");
export const createUserAction = createRoutine("CREATE_USER_ACTION");
export const updateUserAction = createRoutine("UPDATE_USER_ACTION");
export const deleteUserAction = createRoutine("DELETE_USER_ACTION");
export const createLearningObjectAction = createRoutine(
  "CREATE_LEARNING_OBJECT_ACTION"
);
export const createLOUserAction = createRoutine("CREATE_LO_USER_ACTION");
