import _ from "lodash/fp";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { PointsCompact } from ".";

const useStyles = makeStyles({
  pointsText: {
    fontWeight: "bold",
    marginRight: "5px",
    display: "inline",
    lineHeight: "26px",
  },
});

export default ({ learningObject }) => {
  const classes = useStyles();

  const requestAction = _.flow(
    _.get("actions"),
    _.filter({ action_type: "request" }),
    _.orderBy("inserted_at", "desc"),
    _.head,
    _.defaultTo({})
  )(learningObject);

  const completeAction = _.flow(
    _.get("actions"),
    _.filter({ action_type: "complete" }),
    _.orderBy("inserted_at", "desc"),
    _.head,
    _.defaultTo({})
  )(learningObject);

  return _.get("type")(learningObject) === "mooc" ? (
    <>
      <div>
        {requestAction.zircoins === 0 ? (
          <div className={classes.pointsText}>Curso gratuito!</div>
        ) : (
          <>
            <div className={classes.pointsText}>Coste:</div>
            <PointsCompact points={requestAction} />
          </>
        )}
      </div>
      <div>
        <div className={classes.pointsText}>Recompensa:</div>
        <PointsCompact points={completeAction} />
      </div>
    </>
  ) : null;
};
