export default {
  orderFields: {
    most_recent: 'PUBLISH_DATE',
    least_recent: 'PUBLISH_DATE',
    most_access: 'ACCESS_COUNT',
  },
  orderDirections: {
    most_recent: 'DESC',
    least_recent: 'ASC',
    most_access: 'DESC',
  },
  orderChips: [
    { key: 'most_recent', label: 'Más reciente' },
    { key: 'least_recent', label: 'Más antiguo' },
    { key: 'most_access', label: 'Más vistos' },
  ],
}
