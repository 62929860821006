import _ from "lodash/fp";
import React from "react";

import { UserCompact } from "accounts/components";
import { PointsCompact } from ".";

const NovaTalksActions = ({ learningObject }) => {
  const speakAction = _.flow(
    _.get("actions"),
    _.find({ action_type: "speak" })
  )(learningObject);

  const speakUserActions = _.flow(
    _.get("user_actions"),
    _.orderBy(["user.given_name", "user.email"], ["asc", "asc"])
  )(speakAction);

  const atendAction = _.flow(
    _.get("actions"),
    _.find({ action_type: "atend" })
  )(learningObject);

  const atendUserActions = _.flow(
    _.get("user_actions"),
    _.orderBy(["user.given_name", "user.email"], ["asc", "asc"])
  )(atendAction);

  return (
    <>
      {speakAction && (
        <div>
          <h3>
            Ponentes
            <PointsCompact points={speakAction} />
          </h3>
          {speakUserActions.map(({ user }, key) => (
            <UserCompact key={key} user={user} />
          ))}
        </div>
      )}
      {atendAction && (
        <div>
          <h3>
            Asistentes
            <PointsCompact points={atendAction} />
          </h3>
          {atendUserActions.map(({ user }, key) => (
            <UserCompact key={key} user={user} />
          ))}
        </div>
      )}
    </>
  );
};

export default NovaTalksActions;
