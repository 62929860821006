import _ from "lodash/fp";
import pathToRegexp from "path-to-regexp";

const routes = {
  LEARNING_OBJECTS: "/learning_objects",
  LEARNING_OBJECTS_PENDING: "/learning_objects/pending",
  LEARNING_OBJECT_ACTIONS_EDIT: "/learning_objects/:id/actions/edit",
  LEARNING_OBJECT_USER_ACTIONS_EDIT: "/learning_objects/:id/user_actions/edit",
  LEARNING_OBJECT: "/learning_objects/:id",
  LEARNING_OBJECT_EDIT: "/learning_objects/:id/edit",
  NOVA_ACADEMY: "/nova_academy",
  NOVA_ACADEMY_NEW: "/nova_academy/new",
  NOVA_TALKS: "/nova_talks",
  NOVA_TALKS_NEW: "/nova_talks/new",
  MOOCS: "/moocs",
  MOOCS_NEW: "/moocs/new",
  NOVA_BLOGS: "/nova_blogs",
  NOVA_BLOGS_NEW: "/nova_blogs/new",
  NOVA_PATHS: "/nova_paths",
  NOVA_PATHS_NEW: "/nova_paths/new",
};

const linkTo = _.mapValues(pathToRegexp.compile, routes);

export { routes as default, linkTo };
