import { apiGet, apiPatch, JSON_OPTS } from 'api'
import pathToRegexp from 'path-to-regexp'
import {
  fetchUsers,
  fetchUser,
  updateUser,
  updateUserBadges,
} from '../routines'
import { API_USERS, API_USER, API_USER_BADGES } from '../api'
import { all, call, put, takeLatest } from 'redux-saga/effects'

export function* usersRequestSaga() {
  yield all([
    takeLatest(fetchUsers.TRIGGER, fetchUsersSaga),
    takeLatest(fetchUser.TRIGGER, fetchUserSaga),
    takeLatest(updateUser.TRIGGER, updateUserSaga),
    takeLatest(updateUserBadges.TRIGGER, updateUserBadgesSaga),
  ])
}

/**
 * INDEX
 */
export function* fetchUsersSaga() {
  try {
    const url = API_USERS
    yield put(fetchUsers.request())
    const { data } = yield call(apiGet, url, JSON_OPTS)
    yield put(fetchUsers.success(data))
  } catch (error) {
    yield put(fetchUsers.failure(error.response))
  } finally {
    yield put(fetchUsers.fulfill())
  }
}

/**
 * SHOW
 */
export function* fetchUserSaga({ payload }) {
  try {
    const url = pathToRegexp.compile(API_USER)(payload)
    yield put(fetchUser.request())
    const { data: user } = yield call(apiGet, url, JSON_OPTS)
    yield put(fetchUser.success(user))
  } catch (error) {
    yield put(fetchUser.failure(error.response))
  } finally {
    yield put(fetchUser.fulfill())
  }
}

/**
 * UPDATE
 */
export function* updateUserSaga({ payload }) {
  try {
    const url = pathToRegexp.compile(API_USER)(payload)
    yield put(updateUser.request())
    const { data: user } = yield call(
      apiPatch,
      url,
      { user: payload },
      JSON_OPTS
    )
    yield put(updateUser.success(user))
    yield put(fetchUser.trigger(payload))
  } catch (error) {
    yield put(updateUser.failure(error.response))
  } finally {
    yield put(updateUser.fulfill())
  }
}

/**
 * UPDATE BADGES
 */
export function* updateUserBadgesSaga({ payload: { id, badges } }) {
  try {
    const url = pathToRegexp.compile(API_USER_BADGES)({ id })
    yield put(updateUserBadges.request())
    const { data: user } = yield call(apiPatch, url, { badges }, JSON_OPTS)
    yield put(updateUserBadges.success(user))
    yield put(fetchUser.trigger({ user_id: id }))
  } catch (error) {
    yield put(updateUserBadges.failure(error.response))
  } finally {
    yield put(updateUserBadges.fulfill())
  }
}
