import _ from "lodash/fp";
import moment from "moment";

import { clearUserActions, fetchUserActions } from "../routines";

const initialState = [];

const aggregatedUserActions = (state = initialState, { type, payload }) => {
  switch (type) {
    case fetchUserActions.SUCCESS:
      const userActions = payload.data;

      const latestLos = _.flow(
        _.orderBy(["action_timestamp"], ["desc"]),
        _.take(20)
      )(userActions);

      const latestApproved = _.flow(
        _.filter(ua => _.prop("status")(ua) === "APPROVED"),
        _.orderBy(["action_timestamp"], ["desc"]),
        _.take(20)
      )(userActions);

      const usersLos = _.flow(
        _.groupBy(
          (ua) =>
            _.prop("user.id")(ua) +
            "," +
            _.prop("action.learning_object.id")(ua)
        ),
        _.values,
        _.sortBy(_.prop("action_timestamp"))
      )(userActions);

      const [excesiveActions, validLength] = _.partition(
        (uas) => _.size(uas) > 2
      )(usersLos);
      const [singleAction, twoActions] = _.partition(
        (uas) => _.size(uas) === 1
      )(validLength);
      const [onlyRequests, completes] = _.partition(
        ([ua]) => _.prop("action.action_type")(ua) === "request"
      )(singleAction);
      const [onlyCompletes, completesOut] = _.partition(
        ([ua]) => _.prop("action.action_type")(ua) === "complete"
      )(completes);

      const [validCompleted, invalidTypes] = _.partition(
        ([ua1, ua2]) =>
          (_.prop("action.action_type")(ua1) === "request" &&
            _.prop("action.action_type")(ua2) === "complete") ||
          (_.prop("action.action_type")(ua2) === "request" &&
            _.prop("action.action_type")(ua1) === "complete")
      )(twoActions);
      const sixMonthsAgo = moment().subtract(6, "months");
      const [expiredRequests, notExpiredRequests] = _.partition(
        ([ua]) => _.prop("status")(ua) === "EXPIRED"
      )(onlyRequests);
      const [oldRequests, validRequests] = _.partition(([ua]) =>
        moment(_.prop("action_timestamp")(ua)).isBefore(sixMonthsAgo)
      )(notExpiredRequests);

      return {
        validRequests,
        validCompleted,
        completesOut,
        oldRequests,
        expiredRequests,
        excesiveActions,
        invalidTypes,
        onlyCompletes,
        latestLos,
        latestApproved,
      };
    case clearUserActions.TRIGGER:
      return initialState;
    default:
      return state;
  }
};

export { aggregatedUserActions };
