import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createLearningObject } from "../routines";
import { useHistory } from "react-router-dom";
import routes from "../routes";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";

import GenericForm from "common/GenericForm";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const formFields = [
  {
    field: "metadata.subtype",
    label: "Tipo de charla",
    type: "select",
    required: true,
    options: [
      { text: "Blue Talk", value: "blue_talk" },
      { text: "Lightning Talk", value: "lightning_talk" },
    ],
  },
  { field: "name", label: "Título", required: true },
  {
    field: "description",
    label: "Descripción",
    multiline: true,
  },
  { field: "subject", label: "Tema", required: true },
  {
    field: "tags",
    label: "Etiquetas (Pulsa Enter para añadir nuevas)",
    type: "chips",
  },
  {
    field: "metadata.speakers",
    label: "Ponentes (Incluye tu nombre)",
    type: "users",
    required: true,
  },
  { field: "publish_date", label: "Fecha sugerida", type: "date" },
  { field: "metadata.image", label: "URL Imagen" },
];

const NovaTalksNew = ({ createLearningObject }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleSubmit = (item) => {
    createLearningObject({ ...item, type: "nova_talk" });
    history.push(routes.LEARNING_OBJECTS_PENDING);
  };

  return (
    <Container maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <SpeakerNotesIcon />
        </Avatar>
        <h1>Proponer una Nova Talk</h1>
        <GenericForm
          fields={formFields}
          onSubmit={handleSubmit}
          onCancel={() => history.goBack()}
        />
      </div>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createLearningObject }, dispatch);

export default connect(null, mapDispatchToProps)(NovaTalksNew);
