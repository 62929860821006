import React from "react";
import { Switch, Route } from "react-router-dom";

import routes from "../routes";
import { Certifications } from ".";

export default function CertificationsRouter() {
  return (
    <>
      <Switch>
        <Route path={routes.CERTIFICATIONS} component={Certifications} />
      </Switch>
    </>
  );
}
