import _ from "lodash/fp";
import pathToRegexp from "path-to-regexp";

const routes = {
  CERTIFICATIONS: "/certifications",
};

const linkTo = _.mapValues(pathToRegexp.compile, routes);

export { routes as default, linkTo };
