import _ from "lodash/fp";
export default (type, metadata) => {
  switch (type) {
    case "mooc":
      return "Curso online";
    case "nova_talk":
      switch (_.get("subtype")(metadata)) {
        case "lightning_talk":
          return "Lightning Talk";
        case "nova_course":
          return "Nova Course";
        case "blue_talk":
          return "Blue Talk";
        default:
          return "Nova Talk Subtipo inválido";
      }
    case "nova_blog":
      return "Nova Blog";
    case "nova_path":
      return "Nova Path";
    default:
      return "Tipo inválido";
  }
};
