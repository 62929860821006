import React from "react";
import FileSaver from "file-saver";
import { apiGet } from "api";

import { makeStyles } from "@material-ui/core/styles";
import { Fab } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

const DOWNLOAD_ENDPOINT = "/api/v1/badge_templates/csv";

const useStyles = makeStyles((_theme) => ({
  downloadButton: {
    float: "right",
    marginRight: "15px",
    marginTop: "15px",
  },
}));

export default function DownloadCsv() {
  const classes = useStyles();

  const doDownload = () => {
    apiGet(DOWNLOAD_ENDPOINT).then(({ data }) => {
      const blob = new Blob([String.fromCharCode(0xfeff), data], {
        type: "text/csv;charset=utf-8",
      });
      FileSaver.saveAs(blob, `certificaciones.csv`);
    });
  };
  return (
    <Fab
      variant="extended"
      onClick={doDownload}
      className={classes.downloadButton}
      color="primary"
      aria-label="add"
    >
      <GetAppIcon />
      Descargar información
    </Fab>
  );
}
