import React from "react";
import { Switch, Route } from "react-router-dom";

import routes from "../routes";
import UserLoader from "./UserLoader";
import UsersLoader from "./UsersLoader";
import Users from "./Users";
import UserAccessesLoader from "./UserAccessesLoader";
import UserAccesses from "./UserAccesses";
import { User, Badges, BadgesLoader, UserActions } from ".";

export const AccountsRouter = () => (
  <Switch>
    <Route
      path={routes.BADGES}
      exact
      render={() => (
        <>
          <BadgesLoader />
          <Badges />
        </>
      )}
    />
    <Route
      path={routes.USERS}
      exact
      render={() => (
        <>
          <UsersLoader />
          <Users />
        </>
      )}
    />
    <Route
      path={routes.USER_ACTIONS}
      exact
      render={() => (
        <>
          <UserLoader />
          <UserActions />
        </>
      )}
    />
    <Route
      path={routes.USER}
      exact
      render={() => (
        <>
          <UserLoader />
          <User />
        </>
      )}
    />
    <Route
      path={routes.USER_ACCESSES}
      render={() => (
        <>
          <UserAccessesLoader />
          <UserAccesses />
        </>
      )}
    />
  </Switch>
);

export default AccountsRouter;
