import _ from "lodash/fp";
import React from "react";
import moment from "moment";

import { UserCompact, UserActionsButton } from "accounts/components";
import {
  LearningObjectCompact,
  LearningObjectEditUserActionsButton,
} from "learning_objects/components";

import OrderedTable from "common/OrderedTable";
import { ExpireUserActionButton } from "./userActionButtons";

export default ({ userActions, expireAction }) => {
  const content = _.map(_.first)(userActions);
  const columns = [
    {
      id: "id",
      label: "",
      render: (userActionId) => (
        <>
          {expireAction && (
            <ExpireUserActionButton userActionId={userActionId} />
          )}
        </>
      ),
    },
    {
      id: "action_timestamp",
      label: "Fecha",
      render: (action_timestamp) => moment(action_timestamp).fromNow(),
    },
    {
      id: "user.email",
      value: "user",
      label: "Usuario",
      render: (user) => (
        <>
          <UserCompact user={user} />
          <UserActionsButton user={user} />
        </>
      ),
    },
    {
      id: "action.learning_object.metadata.code",
      value: "action.learning_object",
      label: "Curso",
      render: (learning_object) => (
        <>
          <LearningObjectCompact learningObject={learning_object} />
          <LearningObjectEditUserActionsButton
            learningObject={learning_object}
          />
        </>
      ),
    },
  ];

  const searchFields = [
    "user.name",
    "user.email",
    "action.learning_object.name",
    "action.learning_object.metadata.code",
  ];

  return (
    <OrderedTable
      columns={columns}
      content={content}
      searchFields={searchFields}
    />
  );
};
