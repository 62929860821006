import { fetchIssues } from "../routines";

const fetchingIssues = (state = false, { type }) => {
  switch (type) {
    case fetchIssues.TRIGGER:
      return true;
    case fetchIssues.FULFILL:
      return false;
    default:
      return state;
  }
};

const initialState = [];

const issues = (state = initialState, { type, payload }) => {
  switch (type) {
    case fetchIssues.TRIGGER:
      return initialState;
    case fetchIssues.SUCCESS:
      return payload.data;
    default:
      return state;
  }
};

export { issues, fetchingIssues };
