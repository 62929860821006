import { fetchLearningObjects } from "../routines";

const fetchingLearningObjects = (state = false, { type }) => {
  switch (type) {
    case fetchLearningObjects.TRIGGER:
      return true;
    case fetchLearningObjects.FULFILL:
      return false;
    default:
      return state;
  }
};

const initialState = [];

const learningObjects = (state = initialState, { type, payload }) => {
  switch (type) {
    case fetchLearningObjects.TRIGGER:
      return initialState;
    case fetchLearningObjects.SUCCESS:
      return payload.data;
    default:
      return state;
  }
};

export { learningObjects, fetchingLearningObjects };
