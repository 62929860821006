import _ from "lodash/fp";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import Checkbox from "@material-ui/core/Checkbox";

import { UserCompact } from "accounts/components";
import { LearningObjectCompact } from "learning_objects/components";
import { PaidUserActionButton } from "./userActionButtons";

import OrderedTable from "common/OrderedTable";

export default () => {
  const aggregatedUserActions = useSelector(_.get("aggregatedUserActions"));
  const [showPaid, setShowPaid] = useState(false);

  const { validCompleted } = aggregatedUserActions;

  const content = _.flow(
    _.map((uas) => {
      const { metadata: request_metadata } = _.find({
        action: { action_type: "request" },
      })(uas);
      const complete_action = _.find({ action: { action_type: "complete" } })(
        uas
      );
      const {
        metadata: complete_metadata,
        action: { learning_object },
        user,
        action_timestamp,
        id,
      } = complete_action;

      const payment = !_.isNil(_.prop("payment")(complete_metadata));
      const cost = _.prop("cost")(request_metadata);
      return {
        user,
        learning_object,
        cost,
        payment,
        action_timestamp,
        id,
        complete_action,
      };
    }),
    _.filter(({ cost, payment }) => cost && (showPaid || !payment))
  )(validCompleted);
  const columns = [
    {
      id: "id",
      value: "complete_action",
      label: "",
      render: (userAction) => <PaidUserActionButton userAction={userAction} />,
    },
    {
      id: "action_timestamp",
      label: "Fecha",
      render: (action_timestamp) => moment(action_timestamp).fromNow(),
    },
    {
      id: "cost",
      label: "Valor",
      render: (cost) => (cost || 0).toFixed(2) + "€",
    },
    {
      id: "user.email",
      value: "user",
      label: "Usuario",
      render: (user) => <UserCompact user={user} />,
    },
    {
      id: "learning_object.metadata.code",
      value: "learning_object",
      label: "Curso",
      render: (lo) => <LearningObjectCompact learningObject={lo} />,
    },
  ];

  const searchFields = [
    "user.name",
    "user.email",
    "learning_object.name",
    "learning_object.metadata.code",
  ];

  const handleShowPaid = ({ target }) => setShowPaid(target.checked);

  return (
    <>
      <h1>Control de pagos</h1>
      <div>
        <Checkbox checked={showPaid} onChange={handleShowPaid} />
        Mostrar cursos pagados
      </div>
      <OrderedTable
        columns={columns}
        content={content}
        searchFields={searchFields}
      />
    </>
  );
};
