import _ from "lodash/fp";

export default (userActions) => {
  const propFilter = (prop, value) => (x) => _.prop(prop)(x) === value;
  const propReject = (prop, value) => (x) => _.prop(prop)(x) !== value;

  const orderedUserActions = _.orderBy(
    ["action_timestamp"],
    ["asc"]
  )(userActions);

  const pendingActions = _.filter(propFilter("status", "PENDING"))(
    orderedUserActions
  );

  const approvedActions = _.filter(propFilter("status", "APPROVED"))(
    orderedUserActions
  );

  const rejectedActions = _.filter(propFilter("status", "REJECTED"))(
    orderedUserActions
  );

  const expiredActions = _.filter(propFilter("status", "EXPIRED"))(
    orderedUserActions
  );

  const novaTalks = _.filter(
    (x) =>
      propFilter("action.learning_object.type", "nova_talk")(x) ||
      propFilter("action.learning_object.type", "nova_hack")(x)
  )(approvedActions);
  const atendNovaTalks = _.filter(propFilter("action.action_type", "atend"))(
    novaTalks
  );
  const speakNovaTalks = _.filter(propFilter("action.action_type", "speak"))(
    novaTalks
  );

  const novaBlogs = _.filter(
    propFilter("action.learning_object.type", "nova_blog")
  )(approvedActions);

  const otherActions = _.filter((x) =>
    _.isNull(_.prop("action.learning_object")(x))
  )(approvedActions);

  const moocs = _.filter(propFilter("action.learning_object.type", "mooc"))(
    approvedActions
  );

  const insertMoocs = _.filter(propFilter("action.action_type", "insert"))(
    moocs
  );
  const groupedMoocs = _.flow(
    _.filter((x) => _.prop("action.action_type")(x) !== "insert"),
    _.groupBy("action.learning_object.id")
  )(moocs);

  const groupedDesistedMoocs = _.flow(
    _.filter((x) => _.prop("action.action_type")(x) !== "insert"),
    _.filter((x) => _.prop("action.action_type")(x) !== "request"),
    _.groupBy("action.learning_object.id")
  )(moocs);

  const requestMoocs = _.flow(
    _.toPairs,
    _.filter(
      ([_id, actions]) =>
        _.size(actions) === 1 &&
        _.prop("[0].action.action_type")(actions) === "request"
    ),
    _.map(_.prop("[1][0]"))
  )(groupedMoocs);

  const desistMoocs = _.flow(
    _.toPairs,
    _.filter(
      ([_id, actions]) =>
        _.size(actions) === 1 &&
        _.prop("[0].action.action_type")(actions) === "desist"
    ),
    _.map(_.prop("[1][0]"))
  )(groupedDesistedMoocs);

  const completeAllMoocs = _.flow(
    _.toPairs,
    _.filter(([_id, actions]) =>
      _.reduce(
        (acc, x) =>
          acc ||
          _.prop("action.action_type")(x) === "complete" ||
          _.prop("action.action_type")(x) === "out_complete",
        false
      )(actions)
    ),
    _.map(
      _.flow(
        _.prop("[1]"),
        _.filter(
          (x) =>
            _.prop("action.action_type")(x) === "complete" ||
            _.prop("action.action_type")(x) === "out_complete"
        ),
        _.head
      )
    )
  )(groupedMoocs);

  const completeMoocs = _.filter(
    propReject("action.learning_object.metadata.certification", true)
  )(completeAllMoocs);

  const completeCertification = _.filter(
    propFilter("action.learning_object.metadata.certification", true)
  )(completeAllMoocs);

  return {
    pendingActions,
    rejectedActions,
    expiredActions,
    atendNovaTalks,
    speakNovaTalks,
    insertMoocs,
    requestMoocs,
    desistMoocs,
    completeMoocs,
    completeCertification,
    otherActions,
    novaBlogs,
  };
};
