import _ from "lodash/fp";
import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import OrderedTable from "common/OrderedTable";

import ActionTypeChip from "./ActionTypeChip";
import { UserCompact } from "accounts/components";
import { LearningObjectCompact } from "learning_objects/components";

import {
  DeleteUserActionButton,
  UpdateUserActionButton,
} from "scores/components/userActionButtons";

export default () => {
  const { latestLos } = useSelector(_.get("aggregatedUserActions"));

  const columns = [
    {
      id: "id",
      label: "",
      render: (_id, userAction) => (
        <>
          <DeleteUserActionButton
            userId={userAction.user}
            userActionId={userAction.id}
          />
          <UpdateUserActionButton
            userAction={userAction}
            fetchActionTypes={["request", "complete", "out_complete"]}
          />
        </>
      ),
    },
    {
      id: "updated_at",
      label: "Actualizado",
      render: (updated_at) => moment.utc(updated_at).fromNow(),
    },
    {
      id: "action.action_type",
      label: "Tipo",
      render: (actionType) => <ActionTypeChip action_type={actionType} />,
    },
    {
      id: "user.email",
      value: "user",
      label: "Usuario",
      render: (user) => <UserCompact user={user} />,
    },
    {
      id: "action.learning_object.metadata.code",
      value: "action.learning_object",
      label: "Curso",
      render: (lo) => <LearningObjectCompact learningObject={lo} />,
    },
    {
      id: "metadata",
      label: "Detalles",
      render: (metadata) => (
        <>
          {_.toPairs(metadata).map(([key, value], idx) => (
            <div key={idx}>
              <b style={{ marginRight: "3px" }}>{key}:</b>
              {value}
            </div>
          ))}
        </>
      ),
    },
  ];

  const searchFields = [
    "user.name",
    "user.email",
    "action.learning_object.name",
    "action.learning_object.metadata.code",
  ];

  return (
    <>
      <h1>Actividad recientes</h1>
      <OrderedTable
        columns={columns}
        content={latestLos || []}
        searchFields={searchFields}
      />
    </>
  );
};
