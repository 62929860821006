import _ from "lodash/fp";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import UsersActionsTable from "./UsersActionsTable";

const summary_item_style = {
  padding: "10px",
  border: "1px solid #ddd",
  borderRadius: "3px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "120px",
  cursor: "pointer",
};
const useStyles = makeStyles({
  summary: {
    margin: "15px 20px",
    padding: "15px",
    display: "flex",
    justifyContent: "space-around",
  },
  summary_item: { ...summary_item_style },
  summary_item_warning: {
    ...summary_item_style,
    background: "#ffffd5",
    color: "#464600",
  },
  summary_item_error: {
    ...summary_item_style,
    background: "#ffd9d9",
    color: "#860000",
  },
  selected_item: {
    border: "2px solid #3f50b5",
  },
  summary_item_value: {
    fontSize: "30px",
    textAlign: "center",
  },
  summary_item_text: {
    textAlign: "center",
  },
});

export default () => {
  const classes = useStyles();
  const aggregatedUserActions = useSelector(_.get("aggregatedUserActions"));
  const [selected, setSelected] = useState();

  const {
    validRequests,
    validCompleted,
    completesOut,
    oldRequests,
    expiredRequests,
    excesiveActions = [],
    invalidTypes = [],
    onlyCompletes = [],
  } = aggregatedUserActions;

  const invalidActions = [
    ...excesiveActions,
    ...invalidTypes,
    ...onlyCompletes,
  ];

  return (
    <>
      <h1>Cursos activos</h1>
      <Paper className={classes.summary}>
        <div
          className={
            classes.summary_item +
            (selected === "request" ? ` ${classes.selected_item}` : "")
          }
          onClick={() => setSelected("request")}
        >
          <div className={classes.summary_item_value}>
            {_.size(validRequests)}
          </div>
          <div className={classes.summary_item_text}>Activos</div>
        </div>
        <div
          className={
            classes.summary_item +
            (selected === "complete" ? ` ${classes.selected_item}` : "")
          }
          onClick={() => setSelected("complete")}
        >
          <div className={classes.summary_item_value}>
            {_.size(validCompleted)}
          </div>
          <div className={classes.summary_item_text}>Completados</div>
        </div>
        <div
          className={
            classes.summary_item +
            (selected === "out_complete" ? ` ${classes.selected_item}` : "")
          }
          onClick={() => setSelected("out_complete")}
        >
          <div className={classes.summary_item_value}>
            {_.size(completesOut)}
          </div>
          <div className={classes.summary_item_text}>Completados fuera</div>
        </div>
        <div
          className={
            classes.summary_item +
            (selected === "expired" ? ` ${classes.selected_item}` : "")
          }
          onClick={() => setSelected("expired")}
        >
          <div className={classes.summary_item_value}>
            {_.size(expiredRequests)}
          </div>
          <div className={classes.summary_item_text}>Expirados</div>
        </div>{" "}
        <div
          className={
            classes.summary_item_warning +
            (selected === "pending_expired" ? ` ${classes.selected_item}` : "")
          }
          onClick={() => setSelected("pending_expired")}
        >
          <div className={classes.summary_item_value}>
            {_.size(oldRequests)}
          </div>
          <div className={classes.summary_item_text}>Pendientes de expirar</div>
        </div>
        {!_.isEmpty(invalidActions) && (
          <div
            className={
              classes.summary_item_error +
              (selected === "invalid" ? ` ${classes.selected_item}` : "")
            }
            onClick={() => setSelected("invalid")}
          >
            <div className={classes.summary_item_value}>
              {_.size(invalidActions)}
            </div>
            <div className={classes.summary_item_text}>Errores</div>
          </div>
        )}
      </Paper>
      {selected === "request" && (
        <>
          <h2>Activos</h2>
          <UsersActionsTable userActions={validRequests} />
        </>
      )}
      {selected === "complete" && (
        <>
          <h2>Completados</h2>
          <UsersActionsTable userActions={validCompleted} />
        </>
      )}
      {selected === "out_complete" && (
        <>
          <h2>Completados por fuera</h2>
          <UsersActionsTable userActions={completesOut} />
        </>
      )}
      {selected === "pending_expired" && (
        <>
          <h2>Pendiente de Expirar</h2>
          <UsersActionsTable userActions={oldRequests} expireAction />
        </>
      )}
      {selected === "expired" && (
        <>
          <h2>Expirados</h2>
          <UsersActionsTable userActions={expiredRequests} />
        </>
      )}
      {selected === "invalid" && (
        <>
          <h2>Errores</h2>
          <h3>Acciones inválidas</h3>
          <UsersActionsTable
            userActions={[...excesiveActions, ...invalidTypes]}
          />
          <h3>Completados sin solicitud</h3>
          <UsersActionsTable userActions={onlyCompletes} />
        </>
      )}
    </>
  );
};
