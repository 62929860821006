import _ from "lodash/fp";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createUserAction } from "scores/routines";

import { makeStyles } from "@material-ui/core/styles";

import ButtonModal from "common/ButtonModal";
import { getActionTypeText } from "scores/constants";
import { UserCompact } from "accounts/components";
import { LearningObjectCompact } from "learning_objects/components";
import { ActionTypeChip, PointsCompact } from "..";
import FormBody from "common/FormBody";
import { validationErrors, formatValue } from "common/formUtils";
import { getActionFields } from "./userActionFields";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
  },
  headerName: {
    fontSize: "20px",
    fontWeight: "bold",
    marginLeft: "10px",
    marginRight: "10px",
  },
  detail: {
    padding: "20px",
  },
  completeProofButton: {
    marginLeft: "5px",
    textTransform: "none",
  },
}));

export default ({ action, user, learningObject }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form, setForm] = useState({});
  const [formErrors, setFormErrors] = useState();
  const onApprove = () => {
    const errors = validationErrors(fields, form);
    if (!_.isEmpty(errors)) {
      setFormErrors(errors);
      return false;
    }

    dispatch(
      createUserAction({
        ...formatValue(fields, form),
        user,
        action,
        refreshUserActions: true,
      })
    );
  };

  const actionType = _.prop("action_type")(action);
  const fields = getActionFields(actionType);

  return (
    <ButtonModal
      buttonText={getActionTypeText(actionType)}
      modalTitle="Crear acción de usuario"
      onSubmit={onApprove}
    >
      <>
        <div className={classes.header}>
          <UserCompact user={user} />
          <LearningObjectCompact learningObject={learningObject} />
          <ActionTypeChip action_type={actionType} />
          <PointsCompact points={action} />
        </div>
        <div className={classes.detail}>
          <FormBody
            value={form}
            setValue={setForm}
            fields={fields}
            errors={formErrors}
          />
        </div>
      </>
    </ButtonModal>
  );
};
