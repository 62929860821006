import _ from "lodash/fp";
import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  row: {
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "bold",
    background: "white",
    padding: "10px",
    margin: "2px 10px",
    border: "1px solid #efefef",
    borderRadius: "3px",
  },
  row_list: {
    marginLeft: "25px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  row_list_item: {
    margin: "3px",
  },
});

export default function UserOpenBadges() {
  const classes = useStyles();

  const { user } = useSelector(_.pick(["user"]));

  const openBadgeImages = _.flow(
    _.get("open_badges"),
    _.map(({ image_url }) => image_url)
  )(user);

  return _.isEmpty(openBadgeImages) ? null : (
    <div className={classes.row}>
      <div className={classes.row_list}>
        {openBadgeImages.map((imageUrl, key) => (
          <img
            className={classes.row_list_item}
            key={key}
            src={imageUrl}
            alt="Certificación"
            width={128}
          />
        ))}
      </div>
    </div>
  );
}
