import { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { clearUserActions, fetchUserActions } from "../routines";

const AllUserActionsLoader = ({
  clearUserActions,
  fetchUserActions,
  pending,
  actionTypes,
}) => {
  useEffect(() => {
    fetchUserActions({ id: "all", pending, actionTypes });
    return () => clearUserActions();
  }, [clearUserActions, fetchUserActions, pending, actionTypes]);
  return null;
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      clearUserActions,
      fetchUserActions,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(AllUserActionsLoader);
