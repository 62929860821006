import _ from "lodash/fp";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";

import LearningObjectCompact from "./LearningObjectCompact";
import { fetchLearningObjects } from "../routines";
import { lowerDeburrTrim } from "common/filters";

const useStyles = makeStyles({
  root: {
    "& .MuiAutocomplete-inputRoot": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    "& .MuiAutocomplete-input": {
      width: "100%",
    },
  },
  tag: { display: "flex" },
  smallButton: {
    padding: "2px",
    fontSize: "1.125rem",
  },
});

export default ({ onChange, value, name, ...props }) => {
  const classes = useStyles();
  const learningObjects = useSelector(_.get("learningObjects"));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLearningObjects({ active: true }));
  }, [dispatch]);

  const handleChange = (_e, newValue) => {
    const onlyObjectsValue = _.filter(_.conforms({ id: _.isString }))(newValue);
    if (typeof onChange === "function")
      onChange({ target: { name, value: onlyObjectsValue } });
  };

  const filterOptions = (los, { inputValue }) =>
    _.flow(
      _.filter(
        _.flow(
          _.at(["name", "metadata.code", "external_url"]),
          _.map(lowerDeburrTrim),
          _.some(_.includes(inputValue))
        )
      ),
      _.take(5)
    )(los);

  const onRemoveLo = ({ id }) => {
    const newValue = _.reject({ id })(value);
    if (typeof onChange === "function")
      onChange({ target: { name, value: newValue } });
  };
  const switchValuesIndices = (i1, i2) => {
    const item1 = _.nth(i1)(value);
    const item2 = _.nth(i2)(value);
    const half1 = _.slice(0, i1)(value);
    const half2 = _.slice(i2 + 1, _.size(value))(value);
    return [...half1, item2, item1, ...half2];
  };
  const onPromoteLo = (index) => {
    const newValue = switchValuesIndices(index - 1, index);
    if (typeof onChange === "function")
      onChange({ target: { name, value: newValue } });
  };
  const onDemoteLo = (index) => {
    const newValue = switchValuesIndices(index, index + 1);
    if (typeof onChange === "function")
      onChange({ target: { name, value: newValue } });
  };
  return (
    <Autocomplete
      className={classes.root}
      multiple
      disableClearable
      freeSolo
      options={learningObjects}
      onChange={handleChange}
      value={value}
      getOptionLabel={(lo) => (
        <LearningObjectCompact learningObject={lo} readOnly />
      )}
      renderTags={(los) => (
        <div>
          {los.map((lo, idx) => (
            <div key={idx} className={classes.tag}>
              <IconButton
                className={classes.smallButton}
                disabled={idx === 0}
                onClick={() => onPromoteLo(idx)}
              >
                <ExpandLessIcon />
              </IconButton>
              <IconButton
                className={classes.smallButton}
                disabled={idx + 1 === _.size(value)}
                onClick={() => onDemoteLo(idx)}
              >
                <ExpandMoreIcon />
              </IconButton>
              <IconButton
                className={classes.smallButton}
                onClick={() => onRemoveLo(lo)}
              >
                <CloseIcon />
              </IconButton>
              <LearningObjectCompact learningObject={lo} readOnly />
            </div>
          ))}
        </div>
      )}
      filterOptions={filterOptions}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          onKeyDown={(event) => {
            if (event.key === "Backspace") {
              event.stopPropagation();
            }
          }}
        />
      )}
    />
  );
};
