import {fetchUser} from '../routines'


const fetchingUser = (state=false, {type}) => {
  switch(type){
    case fetchUser.TRIGGER:
      return true
    case fetchUser.FULFILL:
      return false
    default:
      return state
  }
}

const initialState = []

const user = (state=initialState, {type, payload}) => {
  switch(type){
    case fetchUser.SUCCESS:
      return payload.data
    default:
      return state
  }
}

export {
  user,
  fetchingUser
}