import _ from "lodash/fp";
import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import PointsCompact from "./PointsCompact";
import { CreateActionButton, DeleteActionButton } from "./userActionButtons";
import { ActionTypeChip } from ".";

const useStyles = makeStyles({
  root: {
    margin: "20px",
  },
  row: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row({ actions, actionType, learningObject, classes }) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow className={classes.row}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          <ActionTypeChip action_type={actionType} />
        </TableCell>
        <TableCell align="left">
          <PointsCompact points={_.head(actions)} />
        </TableCell>
        <TableCell align="left">{_.size(actions)}</TableCell>
        <TableCell>
          <CreateActionButton
            learningObject={learningObject}
            actionType={actionType}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Points</TableCell>
                    <TableCell>Utilizaciones</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {actions.map((action) => (
                    <TableRow key={action.id}>
                      <TableCell>
                        {moment(_.prop("inserted_at")(action)).format(
                          "DD/MM/YYYY"
                        )}
                      </TableCell>
                      <TableCell>
                        <PointsCompact points={action} />
                      </TableCell>
                      <TableCell>
                        {_.flow(_.prop("user_actions"), _.size)(action)}
                      </TableCell>
                      <TableCell>
                        <DeleteActionButton
                          actionId={action.id}
                          learningObject={learningObject}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default ({ learningObject }) => {
  const classes = useStyles();
  const groupedActions = _.flow(
    _.prop("actions"),
    _.groupBy("action_type"),
    _.toPairs,
    _.map(([k, v]) => [k, _.orderBy(["inserted_at"], ["desc"])(v)])
  )(learningObject);

  return (
    <div className={classes.root}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Tipo de acción</TableCell>
              <TableCell>Puntos actuales</TableCell>
              <TableCell>Versiones</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedActions.map(([actionType, actions]) => (
              <Row
                key={actionType}
                actions={actions}
                actionType={actionType}
                learningObject={learningObject}
                classes={classes}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
