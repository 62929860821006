import _ from "lodash/fp";
import React from "react";
import { useSelector } from "react-redux";

import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";

import {
  UserActionsLoader,
  ActionTypeChip,
  PointsCompact,
} from "scores/components";
import {
  CreateUserActionButton,
  DeleteUserActionButton,
  UpdateUserActionButton,
} from "scores/components/userActionButtons";
import { UserInfo } from ".";
import {
  LearningObjectCompact,
  LearningObjectEditUserActionsButton,
} from "learning_objects/components";

const useStyles = makeStyles({
  lo_cell: { borderTop: "3px solid #3f50b5" },
  lo_row: {
    display: "flex",
    alignItems: "center",
  },
  createActionsText: {
    marginLeft: "15px",
    marginRight: "5px",
  },
  separator: {
    flexGrow: "1",
  },
  row_red: {
    background: "#fff0f0",
  },
  row_pending: {
    background: "#fffee9",
  },
});

export default () => {
  const classes = useStyles();
  const { userActions, user } = useSelector(_.pick(["userActions", "user"]));
  const groupedActions = _.flow(
    _.orderBy(
      ["action.learning_object.name", "action.action_type", "action_timestamp"],
      ["asc", "desc", "asc"]
    ),
    _.groupBy("action.learning_object.id"),
    _.toPairs,
    _.map(([_key, value]) => value)
  )(userActions);

  return (
    <>
      <UserActionsLoader />
      <UserInfo />
      <h1>Acciones del usuario</h1>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            {groupedActions.map((actions, key) => {
              const learningObject = _.get("[0].action.learning_object")(
                actions
              );
              return (
                <React.Fragment key={key}>
                  <TableRow>
                    <TableCell colSpan={5} className={classes.lo_cell}>
                      <div className={classes.lo_row}>
                        {learningObject ? (
                          <>
                            <LearningObjectCompact
                              learningObject={learningObject}
                              tooltip
                            />
                            <LearningObjectEditUserActionsButton
                              learningObject={learningObject}
                            />
                            <div className={classes.separator} />
                            <div className={classes.createActionsText}>
                              Crear acción:
                            </div>
                            {_.getOr(
                              [],
                              "actions"
                            )(learningObject).map((action, key) => (
                              <CreateUserActionButton
                                key={key}
                                user={user}
                                action={action}
                                learningObject={learningObject}
                              />
                            ))}
                          </>
                        ) : (
                          <b>Recompensas</b>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                  {actions.map((userAction, idx) => {
                    const {
                      action_timestamp,
                      action,
                      metadata,
                      id,
                      status,
                    } = userAction;
                    return (
                      <TableRow
                        key={idx}
                        className={
                          status === "EXPIRED" || status === "REJECTED"
                            ? classes.row_red
                            : status === "PENDING"
                            ? classes.row_pending
                            : ""
                        }
                      >
                        <TableCell>
                          <DeleteUserActionButton
                            userId={user.id}
                            userActionId={id}
                          />
                          <UpdateUserActionButton
                            userAction={userAction}
                            user={user}
                          />
                        </TableCell>
                        <TableCell>
                          {moment(action_timestamp).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>
                          <ActionTypeChip action_type={action.action_type} />
                          {status !== "APPROVED" && <>{status}</>}
                        </TableCell>
                        <TableCell>
                          <PointsCompact points={action} />
                        </TableCell>
                        <TableCell>
                          {_.toPairs(metadata).map(([key, value], idx) => (
                            <div key={idx}>
                              <b style={{ marginRight: "3px" }}>{key}:</b>
                              {value}
                            </div>
                          ))}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
