import _ from "lodash/fp";
import React from "react";

import { useQuery, gql } from "@apollo/client";

import { makeStyles } from "@material-ui/core/styles";
import BadgeTemplateRow from "./BadgeTemplateRow";
import DownloadCsv from "./DownloadCsv";

const useStyles = makeStyles((_theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  content: {
    width: "100%",
    maxWidth: "1000px",
  },
}));

const BADGE_TEMPLATES = gql`
  query {
    badgeTemplates {
      globalActivityUrl
      imageUrl
      name
      issuerSummary
      description
      level
      cost
      typeCategory
      timeToEarn
      openBadges {
        user {
          id
          given_name
          family_name
          email
          picture
        }
        issuedAt
        expiresAt
      }
    }
  }
`;

export default function Certifications() {
  const classes = useStyles();
  const { data: badgeTemplatesData } = useQuery(BADGE_TEMPLATES, {
    fetchPolicy: "network-only",
  });
  const badgeTemplates = _.getOr([], "badgeTemplates")(badgeTemplatesData);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <DownloadCsv />
        <h1>Certificaciones</h1>

        <div>
          {badgeTemplates.map((badgeTemplate, key) => (
            <BadgeTemplateRow key={key} badgeTemplate={badgeTemplate} />
          ))}
        </div>
      </div>
    </div>
  );
}
