import _ from "lodash/fp";
import React from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { updateUserAction } from "scores/routines";

import { Button } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { makeStyles } from "@material-ui/core/styles";

import ButtonModal from "common/ButtonModal";
import UserCompact from "accounts/components/UserCompact";
import LearningObjectCompact from "learning_objects/components/LearningObjectCompact";
import ActionTypeChip from "../ActionTypeChip";
import PointsCompact from "../PointsCompact";

const useStyles = makeStyles({
  header: {
    display: "flex",
    alignItems: "center",
  },
  headerName: {
    fontSize: "20px",
    fontWeight: "bold",
    marginLeft: "10px",
    marginRight: "10px",
  },
  detail: {
    padding: "20px",
    fontSize: "16px",
  },
  completeProofButton: {
    marginLeft: "5px",
    textTransform: "none",
  },
});

export default ({ userAction }) => {
  const classes = useStyles();
  const currentUser = useSelector(_.get("currentUser"));
  const dispatch = useDispatch();
  const { update } = bindActionCreators({ update: updateUserAction }, dispatch);

  const { action, creator, metadata } = userAction;

  const doUpdate = (status) => {
    update({
      user_action: {
        id: _.get("id")(userAction),
        status,
        metadata: {
          ..._.getOr({}, "metadata")(userAction),
          updated_by: _.get("id")(currentUser),
        },
      },
    });
  };

  const onApprove = () => doUpdate("APPROVED");
  const onReject = () => doUpdate("REJECTED");

  const learningObject = _.prop("action.learning_object")(userAction);

  const requestBody = (
    <>
      <div>
        <b>Motivo:</b> {_.get("reason")(metadata)}
      </div>
      <div>
        <b>Precio:</b> {_.get("cost")(metadata)}€
      </div>
    </>
  );
  const completeBody = (
    <>
      <div>
        <b>Verificar prueba:</b>
        <Button
          className={classes.completeProofButton}
          variant="outlined"
          href={_.get("proof")(metadata)}
          target="_blank"
          size="small"
          color="primary"
        >
          {_.get("proof")(metadata)}
        </Button>
      </div>
      <Rating
        value={_.getOr(0, "rate")(metadata) / 2}
        precision={0.5}
        readOnly
      />
      <div>
        <b>Comentários:</b> {_.get("comment")(metadata)}
      </div>
    </>
  );

  return (
    <ButtonModal
      buttonText="Ver solicitud"
      modalTitle="Aprobar solicitud"
      onSubmit={onApprove}
      onReject={onReject}
    >
      <>
        <div className={classes.header}>
          <UserCompact user={creator} />
          <ActionTypeChip action_type={action.action_type} />
          <LearningObjectCompact learningObject={learningObject} tooltip />
          <PointsCompact points={action} />
        </div>
        <div className={classes.detail}>
          {action.action_type === "request" && requestBody}
          {(action.action_type === "complete" ||
            action.action_type === "out_complete") &&
            completeBody}
        </div>
      </>
    </ButtonModal>
  );
};
