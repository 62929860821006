import _ from "lodash/fp";
import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchUserAccesses } from "../routines";

import { LinearProgress } from "@material-ui/core";

const UserAccessesLoader = ({ fetchUserAccesses, fetchingUsers }) => {
  useEffect(() => {
    fetchUserAccesses();
  }, [fetchUserAccesses]);

  return fetchingUsers ? <LinearProgress /> : null;
};

const mapStateToProps = _.pick(["fetchingUserAccesses"]);

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchUserAccesses,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAccessesLoader);
