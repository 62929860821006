import _ from "lodash/fp";
import React from "react";
import Moment from "react-moment";
import { makeStyles } from "@material-ui/core/styles";

import { UserCompact } from "accounts/components";
import { PointsCompact } from ".";
import MoocCompleteSection from "./MoocCompleteSection";

const useStyles = makeStyles({
  inserted: {
    display: "flex",
    lineHeight: "24px",
    float: "right",
    margin: "5px",
  },
  insertedText: { marginRight: "5px" },
  user_actions: {
    display: "flex",
  },
  request_column: {},
  requestRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderBottom: "1px solid #eeeeee",
    margin: "2px 0px",
    padding: "2px 0px",
  },
  requestTime: {
    color: "#4d4d4d",
    margin: "0px 4px",
    fontSize: "12px",
  },
});

export default ({ learningObject }) => {
  const classes = useStyles();

  const filterApproved = _.filter({ status: "APPROVED" });
  const insertAction = _.flow(
    _.get("actions"),
    _.find({ action_type: "insert" })
  )(learningObject);

  const completeActionUsers = _.flow(
    _.get("actions"),
    _.filter({ action_type: "complete" }),
    _.map("user_actions"),
    _.flatten,
    filterApproved
  )(learningObject);
  const outCompleteActionUsers = _.flow(
    _.get("actions"),
    _.filter({ action_type: "out_complete" }),
    _.map("user_actions"),
    _.flatten,
    filterApproved
  )(learningObject);

  const allCompleteUsers = [
    ...(completeActionUsers || []),
    ...(outCompleteActionUsers || []),
  ];

  const completedUserEmails = _.map(({ user: { email } }) => email)(
    allCompleteUsers
  );

  const requestUsers = _.flow(
    _.get("actions"),
    _.filter({ action_type: "request" }),
    _.map("user_actions"),
    _.flatten,
    filterApproved,
    _.filter(({ user: { email } }) => _.indexOf(email)(completedUserEmails) < 0)
  )(learningObject);

  return (
    <>
      {!_.isEmpty(_.prop("user_actions")(insertAction)) && (
        <div className={classes.inserted}>
          <div className={classes.insertedText}>Añadido por</div>
          <UserCompact user={_.prop("user_actions[0].user")(insertAction)} />
          <PointsCompact points={insertAction} />
        </div>
      )}
      <div className={classes.user_actions}>
        {!_.isEmpty(requestUsers) && (
          <div className={classes.request_column}>
            <h3>Quién lo está haciendo</h3>
            {requestUsers.map(({ user, action_timestamp }, key) => (
              <div className={classes.requestRow} key={key}>
                <div className={classes.requestTime}>
                  <Moment date={action_timestamp} format="DD/MM/YYYY" />
                </div>

                <UserCompact key={key} user={user} />
              </div>
            ))}
          </div>
        )}
        {!_.isEmpty(allCompleteUsers) && (
          <div>
            <h3>Completado por:</h3>
            <MoocCompleteSection completeUsers={allCompleteUsers} />
          </div>
        )}
      </div>
    </>
  );
};
