import _ from "lodash/fp";
import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import UserCompact from "./UserCompact";

const UserAccesses = ({ userAccesses }) => {
  const ordered = _.flow(
    _.orderBy(["inserted_at"], ["desc"]),
    _.take(10)
  )(userAccesses);
  return (
    <>
      <h1>Accesos de usuarios</h1>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Usuario</TableCell>
              <TableCell>Fecha</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ordered.map(({ user, inserted_at }, idx) => (
              <TableRow key={idx}>
                <TableCell>
                  <UserCompact user={user} />
                </TableCell>
                <TableCell>{moment(inserted_at + "Z").fromNow()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default connect(_.pick(["userAccesses"]))(UserAccesses);
