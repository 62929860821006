import _ from 'lodash/fp'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import routes from '../routes'
import {
  useQuery,
  gql
} from "@apollo/client";

import { Add as AddIcon } from '@material-ui/icons'
import NovaAcademyItem from './NovaAcademyItem'
import orderingFields from './novaTalksOrdering'
import useOrderBy from 'common/orderer'

import { Fab } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  content: {
    width: '100%',
    maxWidth: '1000px',
  },
  addButton: {
    float: 'right',
    marginRight: '15px',
    marginTop: '15px',
  },
})

const LEARNING_OBJECTS = gql`
  query (
    $filter: LearningObjectFilter,
    $orderBy: LearningObjectOrder
  ){
    learningObjects(
      filter: $filter,
    	orderBy: $orderBy
    ) {
      id
      name
      tags
      badgesIds
      rateAverage
      rateCount
      activeCount
      completeCount
      accessCount
      publish_date
      metadata {
        speakers {
          id
          given_name
          family_name
          email
          picture
        }
      }
      requestCost {
        zircoins
      }
    }
  }
`

export default () => {
  const classes = useStyles()
  const history = useHistory()

  let filter = {
    type: 'nova_talk',
    subtype: 'nova_course',
    active: true,
  }
  const { orderBySelector, orderBy } = useOrderBy(orderingFields, 'most_recent')

  const { data: learningObjectsData } = useQuery(
    LEARNING_OBJECTS,
    {variables: {
      filter,
      orderBy
    },
    fetchPolicy: "network-only"
  })
  
  const novaCourses = _.getOr([], 'learningObjects')(learningObjectsData)

  const navigateNew = () => history.push(routes.NOVA_ACADEMY_NEW)

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Fab
          variant="extended"
          onClick={navigateNew}
          className={classes.addButton}
          color="primary"
          aria-label="add"
        >
          <AddIcon />
          Quiero poner un Nova Course
        </Fab>
        <h1>Nova Academy</h1>
        {orderBySelector}
        <div>
          {novaCourses.map((learningObject, i) => (
            <NovaAcademyItem learningObject={learningObject} />
          ))}
        </div>
      </div>
    </div>
  )
}
