import _ from 'lodash/fp'
import React from 'react'
import useBadges from 'queries/badges'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  badges: { display: 'flex', alignItems: 'center' },
  badge: {
    margin: '2px',
  },
})


export default ({ badges, size }) => {
  const classes = useStyles()
  const {badges: allBadges} = useBadges()

  const orderedBadges = _.flow(_.orderBy(['order'], ['asc']))(allBadges)
  const selectedBadges = _.filter(({ id }) => _.includes(id)(badges))(
    orderedBadges
  )
  return (
    <div className={classes.badges}>
      {selectedBadges.map(({ name, image }, key) => (
        <div key={key} className={`${classes.badge}`} title={name}>
          <img
            src={image}
            width={size === 'small' ? '24px' : '32px'}
            alt={name}
          />
        </div>
      ))}
    </div>
  )
}
