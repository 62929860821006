import { login, fakeLogin, retrieveSession } from "../routines";

const authenticating = (state = false, { type }) => {
  switch (type) {
    case login.TRIGGER:
    case fakeLogin.TRIGGER:
    case retrieveSession.TRIGGER:
      return true;
    case login.FULFILL:
    case fakeLogin.FULFILL:
    case retrieveSession.FULFILL:
      return false;
    default:
      return state;
  }
};

export { authenticating };
