import _ from "lodash/fp";
import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";

import Home from "./Home";
import AccountsRouter from "accounts/components/AccountsRouter";
import CertificationsRoutes from "certifications/components/CertificationsRouter";
import EmailsRoutes from "emails/components/EmailsRouter";
import LearningObjectsRouter from "learning_objects/components/LearningObjectsRouter";
import ScoresRoutes from "scores/components/ScoresRouter";
import SystemRoutes from "system/components/SystemRouter";

import accountsRoutes from "accounts/routes";
import certificationsRoutes from "certifications/routes";
import emailsRoutes from "emails/routes";
import scoresRoutes from "scores/routes";
import systemRoutes from "system/routes";

import SystemDashboard from "SystemDashboard";

export const PrivateRoutes = ({ currentUser }) => {
  const location = useLocation();
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN);

  if (!token)
    return <Redirect to={{ pathname: "/login", state: { from: location } }} />;
  if (!currentUser)
    return (
      <Redirect
        to={{ pathname: "/retrieveSession", state: { from: location } }}
      />
    );

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path={accountsRoutes.USERS} component={AccountsRouter} />
      <Route path={accountsRoutes.USER_ACCESSES} component={AccountsRouter} />
      <Route path={accountsRoutes.BADGES} component={AccountsRouter} />
      <Route path={scoresRoutes.SCORES} component={ScoresRoutes} />
      <Route path={systemRoutes.ISSUES} component={SystemRoutes} />
      <Route path={systemRoutes.DASHBOARD} component={SystemDashboard} />
      <Route
        path={certificationsRoutes.CERTIFICATIONS}
        component={CertificationsRoutes}
      />
      <Route path={emailsRoutes.EMAILS} component={EmailsRoutes} />
      <Route component={LearningObjectsRouter} />
    </Switch>
  );
};
export default connect(_.pick(["currentUser"]))(PrivateRoutes);
