import _ from "lodash/fp";
import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { createBadge, updateBadge, deleteBadge } from "../routines";

import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CancelIcon from "@material-ui/icons/Cancel";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const MAXIMUM_FILE_SIZE_MB = 2;

const useStyles = makeStyles({
  root: {
    padding: "15px",
    display: "flex",
    flexWrap: "wrap",
  },
  item: {
    position: "relative",
    border: "1px solid #ddd",
    borderRadius: "3px",
    width: "150px",
    padding: "10px",
    margin: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  pointer: {
    cursor: "pointer",
    fontWeight: "bold",
    "&:hover": {
      background: "#eee",
    },
  },
  delete_button: {
    position: "absolute",
    top: "-10px",
    right: "-10px",
  },
  demote_button: {
    left: "-10px",
    position: "absolute",
    background: "green",
    padding: "0px",
    color: "white",
    "& :hover": {
      color: "black",
    },
  },
  promote_button: {
    right: "-10px",
    position: "absolute",
    background: "green",
    padding: "0px",
    color: "white",
    "& :hover": {
      color: "black",
    },
  },
  textfield: {
    "& .MuiOutlinedInput-input": {
      padding: "4px",
    },
    margin: "2px",
  },
  image: {
    flexGrow: "1",
    position: "relative",
    cursor: "pointer",
    alignItems: "center",
    display: "flex",
    "& #hover-image": {
      display: "none",
      color: "white",
      position: "absolute",
      background: "#00000050",
      top: "0px",
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    "&:hover": {
      "& #hover-image": {
        display: "flex",
      },
    },
  },
});

export default () => {
  const classes = useStyles();
  const badges = useSelector(_.get("badges"));
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const [openedText, setOpenedText] = useState();
  const [editingName, setEditingName] = useState();
  const [editingImageId, setEditingImageId] = useState();
  const [selectedTarget, setSelectedTarget] = useState("user");

  const orderedBadges = _.flow(
    _.filter({ target: selectedTarget }),
    _.orderBy(["order"], ["asc"])
  )(badges);
  const maxOrder = _.flow(_.map("order"), _.max)(orderedBadges);

  const handleImageChange = (e) => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const fileSizeMb = (file.size / 1024 / 1024).toFixed(4);
    if (fileSizeMb > MAXIMUM_FILE_SIZE_MB) {
      alert("Imagen debe ser menor que 2MB");
      return;
    }

    reader.onloadend = () => {
      if (editingImageId === null)
        dispatch(
          createBadge({
            image: reader.result,
            name: "Sello",
            target: selectedTarget,
            order: maxOrder === undefined ? 0 : maxOrder + 1,
          })
        );
      else dispatch(updateBadge({ id: editingImageId, image: reader.result }));
    };
    reader.readAsDataURL(file);
  };
  const handleDeleteBadge = (id) => dispatch(deleteBadge(id));

  const handleSwitchOrders = (idx1, idx2) => {
    const { id: id1, order: order1 } = orderedBadges[idx1];
    const { id: id2, order: order2 } = orderedBadges[idx2];
    dispatch(
      updateBadge({
        id: id1,
        order: order2,
      })
    );
    dispatch(
      updateBadge({
        id: id2,
        order: order1,
      })
    );
  };
  const handleDemoteBadge = (idx) => handleSwitchOrders(idx, idx - 1);
  const handlePromoteBadge = (idx) => handleSwitchOrders(idx, idx + 1);

  const openText = (id, name) => {
    setEditingName(name);
    setOpenedText(id);
  };
  const handleTextKeyPress = (ev) => {
    if (ev.key === "Enter") {
      ev.preventDefault();
      dispatch(
        updateBadge({
          id: openedText,
          name: editingName,
        })
      );
      setOpenedText(null);
    }
    if (ev.key === "Escape") {
      ev.preventDefault();
      setOpenedText(null);
    }
  };
  const handleEditImage = (id) => {
    setEditingImageId(id);
    inputFile.current.click();
  };
  return (
    <>
      <h1>Sellos</h1>
      <input
        type="file"
        id="file"
        accept="image/*"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={handleImageChange}
      />
      <ToggleButton
        value="hidden"
        variant="outlined"
        size="small"
        selected={selectedTarget === "user"}
        onClick={() => setSelectedTarget("user")}
      >
        Usuarios
      </ToggleButton>
      <ToggleButton
        value="hidden"
        variant="outlined"
        size="small"
        selected={selectedTarget === "learning_object"}
        onClick={() => setSelectedTarget("learning_object")}
      >
        Learning Objects
      </ToggleButton>
      <div className={classes.root}>
        {orderedBadges.map(({ image, name, id }, key) => (
          <div key={key} className={classes.item}>
            <IconButton
              color="secondary"
              size="small"
              className={classes.delete_button}
              onClick={() => handleDeleteBadge(id)}
            >
              <CancelIcon fontSize="small" />
            </IconButton>
            {key !== 0 && (
              <IconButton
                size="small"
                className={classes.demote_button}
                onClick={() => handleDemoteBadge(key)}
              >
                <ChevronLeftIcon fontSize="small" />
              </IconButton>
            )}
            {key + 1 !== _.size(orderedBadges) && (
              <IconButton
                size="small"
                className={classes.promote_button}
                onClick={() => handlePromoteBadge(key)}
              >
                <ChevronRightIcon fontSize="small" />
              </IconButton>
            )}
            <div className={classes.image} onClick={() => handleEditImage(id)}>
              <img src={image} width="125px" alt={name} />
              <div id="hover-image">
                <EditIcon />
              </div>
            </div>
            {id === openedText ? (
              <TextField
                autoFocus
                className={classes.textfield}
                value={editingName}
                variant="outlined"
                onChange={({ target }) => setEditingName(target.value)}
                onKeyPress={handleTextKeyPress}
                onBlur={() => setOpenedText(null)}
              />
            ) : (
              <div
                className={classes.pointer}
                onClick={() => openText(id, name)}
              >
                {name}
              </div>
            )}
          </div>
        ))}
        <div
          className={`${classes.item} ${classes.pointer}`}
          onClick={() => handleEditImage(null)}
        >
          <AddCircleIcon fontSize="large" />
          Nuevo
        </div>
      </div>
    </>
  );
};
