import _ from "lodash/fp";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import LearningObjectEditActionsButton from "./LearningObjectEditActionsButton";
import LearningObjectEditButton from "./LearningObjectEditButton";
import LearningObjectDeleteButton from "./LearningObjectDeleteButton";
import LearningObjectEditUserActionsButton from "./LearningObjectEditUserActionsButton";

const useStyles = makeStyles({
  buttons: {
    display: "flex",
    flexDirection: "column",
    margin: "5px",
  },
  button_item: {
    margin: "2px 0px",
  },
});

export default ({ learningObject }) => {
  const classes = useStyles();
  const [opened, setOpened] = useState(false);
  const { currentUser } = useSelector(_.pick(["currentUser"]));
  const toggleActive = () => setOpened(!opened);
  return currentUser.is_admin ? (
    <ExpansionPanel expanded={opened} onChange={toggleActive}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        Administración
      </ExpansionPanelSummary>
      <div className={classes.buttons}>
        <div className={classes.button_item}>
          <LearningObjectEditButton fullWidth learningObject={learningObject} />
        </div>
        <div className={classes.button_item}>
          <LearningObjectEditActionsButton
            fullWidth
            learningObject={learningObject}
          />
        </div>
        <div className={classes.button_item}>
          <LearningObjectEditUserActionsButton
            fullWidth
            learningObject={learningObject}
          />
        </div>
        <div className={classes.button_item}>
          <LearningObjectDeleteButton
            fullWidth
            learningObjectId={learningObject.id}
          />
        </div>
      </div>
    </ExpansionPanel>
  ) : null;
};
