import React, { useState } from "react";
import LiveviewIframe from "../../LiveviewIframe";
import { Tab, Tabs } from "@material-ui/core";

const TEMPLATES_INDEX = 0;
export default function Emails() {
  const [activeTab, setActiveTab] = useState(TEMPLATES_INDEX);
  const activeRoute = activeTab === TEMPLATES_INDEX ? "templates" : "rules";
  return (
    <>
      <h1>Emails</h1>
      <Tabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(_e, tab) => setActiveTab(tab)}
      >
        <Tab label="Templates" />
        <Tab label="Email rules" />
      </Tabs>

      <LiveviewIframe key={activeRoute} route={activeRoute} />
    </>
  );
}
