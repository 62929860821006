import {fetchUsers} from '../routines'


const fetchingUsers = (state=false, {type}) => {
  switch(type){
    case fetchUsers.TRIGGER:
      return true
    case fetchUsers.FULFILL:
      return false
    default:
      return state
  }
}

const initialState = []

const users = (state=initialState, {type, payload}) => {
  switch(type){
    case fetchUsers.SUCCESS:
      return payload.data
    default:
      return state
  }
}

export {
  users,
  fetchingUsers
}