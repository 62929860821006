import _ from 'lodash/fp'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Avatar } from '@material-ui/core'
import moment from 'moment'

import { PointsCompact, RankWidget } from 'scores/components'
import BadgesCompact from './BadgesCompact'
import { linkTo } from '../routes'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    background: 'white',
    border: '1px solid #efefef',
    margin: '10px',
    flexWrap: 'wrap',
    cursor: 'pointer',
  },
  avatar: {
    margin: '10px',
    width: '60px',
    height: '60px',
  },
  info_content: {
    margin: '5px',
    flexDirection: 'column',
  },
  name: {
    fontSize: '20px',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  email: {
    fontSize: '16px',
    color: '#656565',
    whiteSpace: 'nowrap',
  },
  rank: {
    fontSize: '16px',
    fontWeight: 'bolder',
    color: '#3347be',
  },
  admin_tag: {
    fontSize: '13px',
    fontWeight: 'bolder',
    color: 'green',
    marginRight: '10px',
  },
  points: {
    margin: '5px',
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  points_row: {
    flexDirection: 'row',
    fontWeight: 'bolder',
    '& .MuiChip-root': {
      fontSize: '17px',
    },
  },
  pending_points_row: {
    flexDirection: 'row',
    '& .MuiChip-root': {
      border: '1px solid #a9a931',
      background: '#fffee9',
    },
  },
})

export default ({ user }) => {
  const classes = useStyles()
  const history = useHistory()

  if (!user) return null

  const navigate = (user_id) => history.push(linkTo.USER({ user_id }))

  const {
    given_name,
    family_name,
    email,
    picture,
    is_admin,
    points,
    pending_points,
    hidden_at,
  } = user
  return (
    <div onClick={() => navigate(user.id)}>
      <div className={classes.root}>
        <Avatar src={picture} className={classes.avatar} />
        <div className={classes.info_content}>
          <div className={classes.name}>
            {given_name} {family_name}
          </div>
          <div className={classes.email}>{email}</div>
          <div className={classes.rank}>
            <RankWidget points={points} />
          </div>
        </div>
        <div className={classes.points}>
          {is_admin && <div className={classes.admin_tag}>Administrador</div>}
          <div className={classes.points_row}>
            <PointsCompact points={points} />
          </div>
          <div className={classes.pending_points_row}>
            <PointsCompact points={pending_points} />
          </div>
          <BadgesCompact size="small" badges={_.getOr([], 'badges')(user)} />
          {hidden_at && (
            <div>Oculto desde {moment(hidden_at).format('DD/MM/YYYY')}</div>
          )}
        </div>
      </div>
    </div>
  )
}
