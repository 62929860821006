import { apiDelete, apiGet, apiPost, JSON_OPTS } from "api";
import pathToRegexp from "path-to-regexp";
import { LEARNING_OBJECT_ACTION, USER_ACTIONS, ACTIONS, ACTION } from "../api";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  createAction,
  deleteAction,
  createLearningObjectAction,
  createLOUserAction,
  fetchGenericActions,
} from "../routines";
import { fetchLearningObject } from "learning_objects/routines";

export function* actionsRequestSaga() {
  yield takeLatest(
    createLearningObjectAction.TRIGGER,
    createLearningObjectActionSaga
  );
  yield takeLatest(createLOUserAction.TRIGGER, createLOUserActionSaga);
  yield takeLatest(fetchGenericActions.TRIGGER, fetchGenericActionsSaga);
  yield takeLatest(createAction.TRIGGER, createActionSaga);
  yield takeLatest(deleteAction.TRIGGER, deleteActionSaga);
}

export function* fetchGenericActionsSaga() {
  try {
    const url = ACTIONS + "?generic_actions=true";
    yield put(fetchGenericActions.request());
    const { data } = yield call(apiGet, url, JSON_OPTS);
    yield put(fetchGenericActions.success(data));
  } catch (error) {
    yield put(fetchGenericActions.failure(error.response));
  } finally {
    yield put(fetchGenericActions.fulfill());
  }
}

export function* createActionSaga({ payload }) {
  try {
    yield put(createAction.request());
    const { data } = yield call(
      apiPost,
      ACTIONS,
      { action: payload },
      JSON_OPTS
    );
    yield put(createAction.success(data));
    yield put(fetchGenericActions.trigger());
  } catch (error) {
    yield put(createAction.failure(error.response));
  } finally {
    yield put(createAction.fulfill());
  }
}

export function* deleteActionSaga({ payload }) {
  try {
    const { id, lo_id } = payload;
    const url = pathToRegexp.compile(ACTION)({ id });
    yield put(deleteAction.request());
    const { data } = yield call(apiDelete, url, JSON_OPTS);
    yield put(deleteAction.success(data));

    if (lo_id) yield put(fetchLearningObject.trigger({ id: lo_id }));
    else {
      yield put(fetchGenericActions.trigger());
    }
  } catch (error) {
    yield put(deleteAction.failure(error.response));
  } finally {
    yield put(deleteAction.fulfill());
  }
}

export function* createLearningObjectActionSaga({ payload }) {
  try {
    const { lo_id, action } = payload;
    const url = pathToRegexp.compile(LEARNING_OBJECT_ACTION)({ lo_id });
    yield put(createLearningObjectAction.request());
    const { data } = yield call(apiPost, url, { action }, JSON_OPTS);
    yield put(createLearningObjectAction.success(data));
    yield put(fetchLearningObject.trigger({ id: lo_id }));
  } catch (error) {
    yield put(createLearningObjectAction.failure(error.response));
  } finally {
    yield put(createLearningObjectAction.fulfill());
  }
}

export function* createLOUserActionSaga({ payload }) {
  try {
    const { lo_id, action, user } = payload;
    const action_url = pathToRegexp.compile(LEARNING_OBJECT_ACTION)({ lo_id });
    const user_action_url = pathToRegexp.compile(USER_ACTIONS)(user);
    yield put(createLOUserAction.request());
    const {
      data: {
        data: { id: action_id },
      },
    } = yield call(apiPost, action_url, { action }, JSON_OPTS);
    const { data } = yield call(
      apiPost,
      user_action_url,
      { user_action: { action_id } },
      JSON_OPTS
    );
    yield put(createLOUserAction.success(data));
    yield put(fetchLearningObject.trigger({ id: lo_id }));
  } catch (error) {
    yield put(createLOUserAction.failure(error.response));
  } finally {
    yield put(createLOUserAction.fulfill());
  }
}
