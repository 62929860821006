import _ from "lodash/fp";
import React, { useState } from "react";
import Moment from "react-moment";
import { makeStyles } from "@material-ui/core/styles";

import { Paper, Button } from "@material-ui/core";
import {
  KeyboardArrowDown as DownIcon,
  KeyboardArrowUp as UpIcon,
} from "@material-ui/icons";
import { UserCompact } from "accounts/components";

const useStyles = makeStyles({
  post_item: {
    margin: "5px 15px",
    padding: "15px",
    display: "flex",
  },
  post_image: {
    cursor: "pointer",
  },
  item_content: {
    flexGrow: "1",
    padding: "0px 15px",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  issuer: {
    fontWeight: "bold",
  },
  description: {
    color: "dark-gray",
    fontSize: "12px",
    padding: "10px",
  },
  detail_row: {
    marginLeft: "14px",
    display: "flex",
    flexDirection: "row",
  },
  detail_row_label: {
    fontWeight: "bold",
  },
  detail_row_value: {
    marginLeft: "5px",
  },
  users: {
    marginTop: "10px",
  },
  userBadge: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "3px",
  },
  issuedAt: {
    textAlign: "right",
    width: "90px",
    fontSize: "14px",
    color: "#444",
    marginRight: "10px",
  },
  expiresAt: {
    fontSize: "14px",
    color: "#444",
    marginRight: "15px",
  },
});

export default function BadgeTemplateRow({ badgeTemplate }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const details = [
    ["Categoria", "typeCategory"],
    ["Nivel", "level"],
    ["Coste", "cost"],
    ["Tiempo para adquirir", "timeToEarn"],
  ];

  const navigateSource = () =>
    window.open(badgeTemplate.globalActivityUrl, "_blank");

  const openBadges = _.get("openBadges")(badgeTemplate);

  return (
    <Paper className={classes.post_item}>
      <div className={classes.post_image} onClick={navigateSource}>
        <img
          className={classes.post_image}
          src={badgeTemplate.imageUrl}
          width={120}
          alt={badgeTemplate.name}
        />
      </div>
      <div className={classes.item_content}>
        <div className={classes.title} onClick={navigateSource}>
          {badgeTemplate.name}
        </div>
        <div className={classes.issuer}>{badgeTemplate.issuerSummary}</div>
        <div className={classes.description}>{badgeTemplate.description}</div>
        <div>
          {details.map(
            ([label, key]) =>
              _.get(key)(badgeTemplate) && (
                <div key={key} className={classes.detail_row}>
                  <div className={classes.detail_row_label}>{label}:</div>
                  <div className={classes.detail_row_value}>
                    {_.get(key)(badgeTemplate)}
                  </div>
                </div>
              )
          )}
        </div>
        <div>
          <Button
            color="primary"
            variant="outlined"
            endIcon={expanded ? <UpIcon /> : <DownIcon />}
            onClick={() => setExpanded(!expanded)}
          >
            {_.size(openBadges)} personas tienen ese certificado
          </Button>
          {expanded && (
            <div className={classes.users}>
              {openBadges.map(({ user, issuedAt, expiresAt }, key) => (
                <div className={classes.userBadge}>
                  <Moment
                    className={classes.issuedAt}
                    date={issuedAt}
                    format="DD/MM/YYYY"
                  />
                  {expiresAt && (
                    <div className={classes.expiresAt}>
                      - <Moment date={expiresAt} format="DD/MM/YYYY" />
                    </div>
                  )}
                  <UserCompact key={key} user={user} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Paper>
  );
}
