import _ from 'lodash/fp'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import { Chip, Checkbox, InputAdornment, TextField } from '@material-ui/core'
import {
  Search as SearchIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from '@material-ui/icons'
import Pagination from '@material-ui/lab/Pagination'

import BadgesFilter from './BadgesFilter'
import { lowerDeburrTrim } from 'common/filters'
import UserItem from './UserItem'

const descendingComparator = (a, b, orderBy) => {
  if (_.path(orderBy)(b) === _.path(orderBy)(a)) return 0
  if (_.path(orderBy)(a) === null) return 1
  if (_.path(orderBy)(b) === null) return -1

  if (_.path(orderBy)(b) < _.path(orderBy)(a)) {
    return -1
  }
  if (_.path(orderBy)(b) > _.path(orderBy)(a)) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const ROWS_PER_PAGE = 20

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  content: {
    width: '100%',
    maxWidth: '1000px',
  },
  search: {
    background: 'white',
    marginBottom: '15px',
  },
  badge_filter: {
    display: 'flex',
    alignItems: 'center',
  },
  badge_filter_text: {
    fontSize: '15px',
    marginRight: '5px',
  },
  paginationContainer: {
    padding: '15px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '65px',
  },
  orderButtons: {
    padding: '15px',
    display: 'flex',
    justifyContent: 'center',
  },
  orderTitle: {
    fontSize: '15px',
    fontWeight: 'bold',
    marginRight: '10px',
  },
  orderItem: {
    display: 'flex',
    alignItems: 'center',
  },
  orderIcon: {
    width: '16px',
  },
}))

const orderChips = [
  { key: 'given_name', label: 'Nombre' },
  { key: 'email', label: 'Correo' },
  { key: 'points.zircoins', label: 'Zircoins' },
  { key: 'points.learning_points', label: 'Aprendizaje' },
  { key: 'points.experience_points', label: 'Experiencia' },
  { key: 'points.communication_points', label: 'Comunicación' },
  { key: 'points.community_points', label: 'Comunidad' },
  { key: 'points.team_work_points', label: 'Equipo' },
]
const adminOrderChips = [
  { key: 'is_admin', label: 'Admin' },
  { key: 'hidden_at', label: 'Oculto' },
]

export default () => {
  const classes = useStyles()
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('points.learning_points')
  const [page, setPage] = React.useState(0)
  const [search, setSearch] = useState('')
  const [badgesFilter, setBadgesFilter] = useState([])
  const [showHidden, setShowHidden] = useState(false)

  const {
    users,
    currentUser: { is_admin },
  } = useSelector(_.pick(['users', 'currentUser']))

  const doHandleSearchChange = _.debounce(150)(({ target }) => {
    setPage(0)
    setSearch(target.value)
  })

  const handleSearchChange = (e) => {
    e.persist()
    doHandleSearchChange(e)
  }

  const handleShowHidden = ({ target }) => setShowHidden(target.checked)

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (_e, newPage) => {
    setPage(newPage - 1)
  }

  const getUsersFilter = lowerDeburrTrim(search)

  const filterUsers = (users, filters, badgesFilter) =>
    filters || !_.isEmpty(badgesFilter) || !showHidden
      ? _.flow(
          _.filter(
            _.flow(
              _.at(['name', 'email']),
              _.map(lowerDeburrTrim),
              _.some(_.includes(filters))
            )
          ),
          _.filter(
            (user) =>
              _.isEmpty(badgesFilter) ||
              _.every(
                (badgeFilter) =>
                  _.indexOf(badgeFilter)(_.getOr([], 'badges')(user)) >= 0
              )(badgesFilter)
          ),
          _.filter((user) => showHidden || _.isNil(_.prop('hidden_at')(user)))
        )(users)
      : users

  const filteredUsers = stableSort(
    filterUsers(users, getUsersFilter, badgesFilter),
    getComparator(order, orderBy)
  )
  const pagesCount = Math.floor(filteredUsers.length / ROWS_PER_PAGE)

  const orderChipsMap = ({ key, label }, idx) => (
    <Chip
      key={idx}
      className={classes.order_tag}
      variant={orderBy === key ? 'default' : 'outlined'}
      label={
        <div className={classes.orderItem}>
          {label}
          {orderBy === key ? (
            order === 'asc' ? (
              <ArrowUpwardIcon className={classes.orderIcon} />
            ) : (
              <ArrowDownwardIcon className={classes.orderIcon} />
            )
          ) : null}
        </div>
      }
      size="small"
      onClick={() => handleRequestSort(key)}
    />
  )

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <h1>Usuarios</h1>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Buscar usuario"
          className={classes.search}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />{' '}
        <div className={classes.orderButtons}>
          <div className={classes.orderTitle}>Ordenar:</div>
          {orderChips.map(orderChipsMap)}
          {is_admin && adminOrderChips.map(orderChipsMap)}
        </div>
        {is_admin && (
          <div>
            <Checkbox checked={showHidden} onChange={handleShowHidden} />
            Mostrar usuarios ocultos
          </div>
        )}
        <div className={classes.badge_filter}>
          <div className={classes.badge_filter_text}>Filtrar por sellos:</div>
          <BadgesFilter
            selectedBadges={badgesFilter}
            onChange={setBadgesFilter}
          />
        </div>
        <div className={classes.paginationContainer}>
          {pagesCount > 0 && (
            <Pagination
              count={pagesCount}
              page={page + 1}
              onChange={handleChangePage}
            />
          )}
        </div>
        <div>
          {filteredUsers
            .slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
            .map((user, idx) => (
              <UserItem user={user} key={idx} />
            ))}
        </div>
        <div className={classes.paginationContainer}>
          {pagesCount > 0 && (
            <Pagination
              count={pagesCount}
              page={page + 1}
              onChange={handleChangePage}
            />
          )}
        </div>
      </div>
    </div>
  )
}
