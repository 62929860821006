import React from "react";
import PointsForm from "./PointsForm";
export default ({
  name,
  value,
  onChange,
  label,
  error,
  helperText,
  showOnly,
}) => {
  return (
    <>
      {label}
      <PointsForm
        showOnly={showOnly}
        error={error}
        value={value}
        onChange={(value) => onChange({ target: { name, value } })}
      />
      {error && <p style={{ color: "red" }}>{helperText}</p>}
    </>
  );
};
