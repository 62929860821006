import { retrieveSession } from "../routines";

const initialState = false;

const retrieveError = (state = initialState, { type }) => {
  switch (type) {
    case retrieveSession.TRIGGER:
      return initialState;
    case retrieveSession.FAILURE:
      return true;
    default:
      return state;
  }
};

export { retrieveError };
