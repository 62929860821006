import _ from 'lodash/fp'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { linkTo } from '../routes'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Avatar } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'

import LearningObjectCard from './LearningObjectCard'
import parseImage from '../loImageParser'

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #a5a5a5;',
    display: 'flex',
    borderRadius: '15px',
    alignItems: 'center',
    paddingRight: '10px',
    fontSize: '14px',
    height: '30px',
    margin: '3px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  rootHover: {
    cursor: 'pointer',
    '&:hover': {
      background: '#e1e1e1',
    },
  },
  name: {
    marginLeft: '5px',
  },
  avatar: {
    width: '30px',
    height: '30px',
  },
  code_tag: {
    margin: '5px',
    fontWeight: 'bolder',
    fontSize: '11px',
    background: '#ffffffbd',
    padding: '2px',
    borderRadius: '10px',
    minWidth: '19px',
    textAlign: 'center',
  },
}))

const LearningObjectCompact = ({ learningObject, readOnly, tooltip }) => {
  const classes = useStyles()
  const history = useHistory()

  if (!learningObject) return null

  const navigate = () => history.push(linkTo.LEARNING_OBJECT(learningObject))
  const imageSource = parseImage(learningObject)

  const content = (
    <div
      onClick={!readOnly ? navigate : null}
      className={classes.root + (readOnly ? '' : ' ' + classes.rootHover)}
    >
      <Avatar src={imageSource} className={classes.avatar} />
      {_.has('metadata.code')(learningObject) && (
        <div className={classes.code_tag}>
          #{_.prop('metadata.code')(learningObject)}
        </div>
      )}
      <div className={classes.name}>{learningObject.name}</div>
    </div>
  )

  const WideTooltip = withStyles({
    tooltip: {
      minWidth: '400px',
    },
  })(Tooltip)

  return tooltip ? (
    <WideTooltip title={<LearningObjectCard learningObject={learningObject} />}>
      {content}
    </WideTooltip>
  ) : (
    content
  )
}

export default LearningObjectCompact
