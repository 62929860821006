import _ from "lodash/fp";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";

import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";

import PointsCompact from "./PointsCompact";
import NewRewardForm from "./NewRewardForm";
import { UserCompact } from "accounts/components";
import { deleteAction, createUserAction, deleteUserAction } from "../routines";
import { lowerDeburrTrim } from "common/filters";

const useStyles = makeStyles({
  heading: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontSize: "18px",
    marginRight: "10px",
  },
  users: {
    fontSize: "13px",
    color: "#444",
    marginLeft: "10px",
    lineHeight: "28px",
  },
  content: {
    flexDirection: "column",
    "& .MuiIconButton-root": {
      padding: "0px",
      marginRight: "2px",
    },
  },
  lists: {
    display: "flex",
    justifyContent: "space-around",
  },
  separator: {
    width: "5px",
  },
  deleteButton: {
    color: "#ff5d5d",
  },
  deleteActionButton: {
    color: "#ff5d5d",
    margin: "0px 5px",
    padding: "0px",
  },
  addButton: {
    color: "#59b459",
  },
  modal: { border: "none" },
  modalContent: {
    position: "absolute",
    top: "50vh",
    left: "50vw",
    border: "none",
  },
  createButton: {
    float: "right",
    margin: "15px",
  },
});

export default () => {
  const classes = useStyles();
  const {
    genericActions,
    users,
    creatingUserAction,
    deletingUserAction,
    fetchingGenericActions,
  } = useSelector(
    _.pick([
      "genericActions",
      "users",
      "creatingUserAction",
      "deletingUserAction",
      "fetchingGenericActions",
    ])
  );
  const [userFilter, setUserFilter] = useState("");
  const [expandedId, setExpandedId] = useState();
  const [newActionFormActive, setNewActionFormActive] = useState(false);
  const dispatch = useDispatch();

  const handleUserFilter = ({ target }) => setUserFilter(target.value);

  const handleCreateUserAction = (user, action) => {
    dispatch(createUserAction({ user, action }));
  };
  const handleCreateAll = (users, action) =>
    _.forEach((user) => handleCreateUserAction(user, action))(users);

  const handleDeleteUserAction = (user_action) => {
    dispatch(deleteUserAction(user_action));
  };
  const handleDeleteAll = (user_actions) =>
    _.forEach(handleDeleteUserAction)(user_actions);

  const handleDeleteAction = (action) => dispatch(deleteAction(action));

  const asignedActions = expandedId
    ? _.flow(
        _.find({ id: expandedId }),
        _.get("user_actions"),
        _.filter(
          _.flow(
            _.at(["user.name", "user.email"]),
            _.map(lowerDeburrTrim),
            _.some(_.includes(userFilter))
          )
        ),
        _.orderBy(["action_timestamp"], ["desc"])
      )(genericActions)
    : [];

  const userIdsWithActions = _.flow(_.map("user.id"), _.uniq)(asignedActions);

  const usersWithoutAction = _.flow(
    _.filter(
      _.flow(
        _.at(["name", "email"]),
        _.map(lowerDeburrTrim),
        _.some(_.includes(userFilter))
      )
    ),
    _.reject(({ id }) => _.includes(id)(userIdsWithActions)),
    _.orderBy(["email"], ["asc"])
  )(users);

  return (
    <>
      {!newActionFormActive && (
        <Button
          className={classes.createButton}
          variant="contained"
          color="primary"
          onClick={() => setNewActionFormActive(true)}
        >
          Crear nueva recompensa
        </Button>
      )}
      <h1>Recompensas</h1>
      {newActionFormActive && (
        <NewRewardForm onSubmit={() => setNewActionFormActive(false)} />
      )}
      {genericActions.map((action, key) => (
        <ExpansionPanel
          key={key}
          onChange={(_e, expanded) =>
            setExpandedId(expanded ? _.get("id")(action) : null)
          }
          expanded={expandedId === _.get("id")(action)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.heading}
          >
            <div className={classes.label}>{_.get("label")(action)}</div>
            <PointsCompact points={action} />
            <div className={classes.users}>
              (Utilizada {_.flow(_.get("user_actions"), _.size)(action)} veces)
            </div>
            <Tooltip title={"Eliminar recompensa"}>
              <IconButton
                className={classes.deleteActionButton}
                onClick={() => handleDeleteAction(action)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </ExpansionPanelSummary>
          {expandedId === _.get("id")(action) && (
            <ExpansionPanelDetails className={classes.content}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                // className={classes.search}
                onChange={handleUserFilter}
                value={userFilter}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <div className={classes.lists}>
                <List dense>
                  <ListItem>
                    <b>Usuarios que la tienen ({_.size(asignedActions)})</b>
                    <Tooltip
                      title={"Eliminar de todas las " + _.size(asignedActions)}
                      disabled={_.size(asignedActions) === 0}
                    >
                      <IconButton
                        className={classes.deleteButton}
                        onClick={() => handleDeleteAll(asignedActions)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                  {_.take(10)(asignedActions).map(
                    ({ action_timestamp, user, id }, key) => (
                      <ListItem key={key}>
                        <Tooltip title="Eliminar recompensa">
                          <IconButton
                            className={classes.deleteButton}
                            onClick={() => handleDeleteUserAction({ id })}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Dar recompensa denuevo">
                          <IconButton
                            className={classes.addButton}
                            onClick={() => handleCreateUserAction(user, action)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Tooltip>
                        <Moment date={action_timestamp} format="DD/MM/YYYY" />
                        <div className={classes.separator} />
                        <UserCompact user={user} />
                      </ListItem>
                    )
                  )}
                </List>
                <List dense>
                  <ListItem>
                    <b>Usuarios no tienen ({_.size(usersWithoutAction)})</b>
                    <Tooltip title="Dar a todos">
                      <IconButton
                        onClick={() =>
                          handleCreateAll(usersWithoutAction, action)
                        }
                        className={classes.addButton}
                        disabled={_.size(usersWithoutAction) === 0}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                  {_.take(10)(usersWithoutAction).map((user, userKey) => (
                    <ListItem key={userKey}>
                      <Tooltip title="Dar recompensa">
                        <IconButton
                          className={classes.addButton}
                          onClick={() => handleCreateUserAction(user, action)}
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </Tooltip>
                      <UserCompact user={user} />
                    </ListItem>
                  ))}
                </List>
              </div>
            </ExpansionPanelDetails>
          )}
        </ExpansionPanel>
      ))}
      <Modal
        open={
          deletingUserAction || creatingUserAction || fetchingGenericActions
        }
        className={classes.modal}
      >
        <div className={classes.modalContent}>
          <CircularProgress />
        </div>
      </Modal>
    </>
  );
};
