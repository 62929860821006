import _ from 'lodash/fp'
import React from 'react'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'

import { scores } from '../constants'

const useStyles = makeStyles({
  chip: { margin: '2px' },
})

const PointsCompact = ({ points, size = 'small' }) => {
  const classes = useStyles()
  const buildChip = ({ prop, name, icon }, idx) =>
    _.getOr(0, prop)(points) === 0 ? null : (
      <Chip
        key={idx}
        className={classes.chip}
        size={size}
        variant="outlined"
        icon={icon}
        label={_.prop(prop)(points)}
        title={name}
      />
    )
  return <>{scores.map(buildChip)}</>
}
export default PointsCompact
