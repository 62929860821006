import _ from "lodash/fp";
import React from "react";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import parseUserActions from "../userActionsParser";
import UserActionsLoader from "./UserActionsLoader";
import { LearningObjectCompact } from "learning_objects/components";
import { DesistUserActionButton } from "./userActionButtons";
import UserActionsExpiredRow from "./UserActionsExpiredRow";
import UserActionsPendingRow from "./UserActionsPendingRow";
import UserActionsRejectedRow from "./UserActionsRejectedRow";
import UserActionsRewardsRow from "./UserActionsRewardsRow";
import { PointsCompact } from "scores/components";

import { makeStyles } from "@material-ui/core/styles";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "bold",
    background: "white",
    padding: "10px",
    margin: "2px 10px",
    border: "1px solid #efefef",
    borderRadius: "3px",
  },
  row_list: {
    marginLeft: "25px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row_item: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  timestamp: {
    textAlign: "right",
    width: "90px",
    fontSize: "14px",
    color: "#444",
    marginRight: "15px",
  },
  separator: { width: "15px" },
  cost_detail: {
    fontSize: "14px",
    display: "flex",
    alignItem: "center",
  },
  cost_detail_value: {
    marginLeft: "3px",
  },
});

const UserActionsList = () => {
  const classes = useStyles();

  const { userActions, currentUser, user } = useSelector(
    _.pick(["userActions", "currentUser", "user"])
  );

  const {
    pendingActions,
    rejectedActions,
    expiredActions,
    atendNovaTalks,
    speakNovaTalks,
    insertMoocs,
    requestMoocs,
    desistMoocs,
    completeMoocs,
    completeCertification,
    otherActions,
    novaBlogs,
  } = parseUserActions(userActions);

  const getActionByType = (userAction, action_type) =>
    _.prop("action.action_type")(userAction) === action_type;

  const getActionFromLearningObjectByType = (userAction, action_type) =>
    _.flow(
      _.prop("action.learning_object.actions"),
      _.find({ action_type: action_type })
    )(userAction);

  const buildRow = (list, name) =>
    _.isEmpty(list) ? null : (
      <div className={classes.row}>
        {name}
        <div className={classes.row_list}>
          {list.map((x, idx) => (
            <div className={classes.row_item} key={idx}>
              <Moment
                className={classes.timestamp}
                date={_.prop("action_timestamp")(x)}
                format="DD/MM/YYYY"
              />
              <PointsCompact points={_.prop("action")(x)} />
              <div className={classes.separator} />
              <LearningObjectCompact
                learningObject={_.prop("action.learning_object")(x)}
              />
              {_.prop("is_admin")(currentUser) &&
                _.prop("metadata.cost")(x) && (
                  <div className={classes.cost_detail}>
                    <LocalAtmIcon />{" "}
                    <div className={classes.cost_detail_value}>
                      {_.prop("metadata.cost")(x)} €
                    </div>
                  </div>
                )}

              {getActionByType(x, "request") && getActionFromLearningObjectByType(x, "desist") &&
              (user.id === currentUser.id || currentUser.is_admin) && (
                <div className={classes.cost_detail}>
                  <DesistUserActionButton
                    userId={user}
                    action={getActionFromLearningObjectByType(x, "desist")}
                    confirmMessage="Cancelar la solicitud"
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );

  return (
    <>
      <UserActionsLoader />
      <div className={classes.root}>
        <UserActionsPendingRow pendingActions={pendingActions} />
        {buildRow(completeCertification, "Certificaciones")}
        {buildRow(speakNovaTalks, "Ponente")}
        {buildRow(novaBlogs, "Publicaciones")}
        {buildRow(requestMoocs, "Cursos en progreso")}
        {buildRow(desistMoocs, "Cursos desistidos")}
        {buildRow(completeMoocs, "Cursos completados")}
        {buildRow(atendNovaTalks, "Nova talks asistidas")}
        {buildRow(insertMoocs, "Cursos añadidos")}
        {!_.isEmpty(otherActions) && (
          <UserActionsRewardsRow otherActions={otherActions} />
        )}
        <UserActionsRejectedRow rejectedActions={rejectedActions} />
        <UserActionsExpiredRow expiredActions={expiredActions} />
      </div>
    </>
  );
};

export default UserActionsList;
