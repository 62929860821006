import {fetchAuthorizationUri} from '../routines'

const authorizationUri = (state = null, {type, payload}) => {
  switch(type){
    case fetchAuthorizationUri.SUCCESS:
      return payload
    default:
      return state
  }
}

export {authorizationUri}