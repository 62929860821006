import _ from "lodash/fp";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createLOUserAction } from "../routines";
import { useHistory } from "react-router-dom";
import { linkTo } from "learning_objects/routes";

import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Button } from "@material-ui/core";

import FormBody from "common/FormBody";
import { validationErrors, formatValue } from "common/formUtils";
import { getActionTypeText } from "../constants";

const useStyles = makeStyles({
  card: {
    width: "100%",
    marginTop: "5px",
  },
  button: {
    padding: "15px",
  },
});

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const learningObject = useSelector(_.get("learningObject"));

  const [form, setForm] = useState({
    complete: {},
  });
  const [formErrors, setFormErrors] = useState();

  const fields = [
    {
      field: "complete",
      label: getActionTypeText("complete"),
      type: "points",
      showOnly: [
        "learning_points",
        "experience_points",
        "community_points",
        "communication_points",
        "zircoins",
      ],
    },
  ];

  const onSubmit = () => {
    const errors = validationErrors(fields, form);
    if (!_.isEmpty(errors)) {
      setFormErrors(errors);
      return false;
    }

    const { publish } = formatValue(fields, form);

    const lo_id = _.prop("id")(learningObject);
    const user = _.prop("creator")(learningObject);
    dispatch(
      createLOUserAction({
        lo_id,
        action: {
          ...publish,
          action_type: "complete",
        },
        user,
      })
    );
    history.push(linkTo.LEARNING_OBJECT(learningObject));
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <FormBody
          value={form}
          setValue={setForm}
          fields={fields}
          errors={formErrors}
        />
        <Button variant="outlined" fullWidth onClick={onSubmit}>
          Validar Nova Path
        </Button>
      </CardContent>
    </Card>
  );
};
