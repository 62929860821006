import { createRoutine } from "redux-saga-routines";

export const login = createRoutine("LOGIN");
export const fakeLogin = createRoutine("FAKE_LOGIN");
export const fetchAuthorizationUri = createRoutine("FETCH_AUTHORIZATION_URI");
export const retrieveSession = createRoutine("RETRIEVE_SESSION");

export const fetchUsers = createRoutine("FETCH_USERS");
export const fetchUser = createRoutine("FETCH_USER");
export const updateUser = createRoutine("UPDATE_USER");

export const fetchUserAccesses = createRoutine("FETCH_USER_ACCESSES");

export const fetchBadges = createRoutine("FETCH_BADGES");
export const createBadge = createRoutine("CREATE_BADGE");
export const updateBadge = createRoutine("UPDATE_BADGE");
export const deleteBadge = createRoutine("DELETE_BADGE");

export const updateUserBadges = createRoutine("UPDATE_USER_BADGES");
