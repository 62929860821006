import _ from "lodash/fp";
import pathToRegexp from "path-to-regexp";

const routes = {
  USERS: "/users",
  USER: "/users/:user_id",
  USER_ACTIONS: "/users/:user_id/actions",
  USER_ACCESSES: "/user_accesses",
  BADGES: "/badges",
};

const linkTo = _.mapValues(pathToRegexp.compile, routes);

export { routes as default, linkTo };
