import React from "react";
import { useDispatch } from "react-redux";
import { createUserAction } from "scores/routines";

import ButtonModal from "common/ButtonModal";
import CancelIcon from "@material-ui/icons/Cancel";

const DesistUserActionButton  = ({
  userId,
  action,
  confirmMessage = "Quieres borrar la acción del usuario?",
}) => {
  const dispatch = useDispatch();

  const handleDesistUserAction = () =>
    {
      return dispatch(createUserAction({ user: userId, action, refreshUserActions: true}));
    }

  return (
    <ButtonModal
      buttonIcon={<CancelIcon />}
      modalTitle="¿Deseas desistir este curso? Recuerda que no se devolverán los zircoins."
      onSubmit={handleDesistUserAction}
    >
      {confirmMessage}
    </ButtonModal>
  );
};

export default DesistUserActionButton
