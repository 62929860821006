import { apiGet, JSON_OPTS } from "api";
import { fetchUserAccesses } from "../routines";
import { API_USER_ACCCESSES } from "../api";
import { all, call, put, takeLatest } from "redux-saga/effects";

export function* userAccessesRequestSaga() {
  yield all([takeLatest(fetchUserAccesses.TRIGGER, fetchUserAccessesSaga)]);
}

/**
 * INDEX
 */
export function* fetchUserAccessesSaga() {
  try {
    const url = API_USER_ACCCESSES;
    yield put(fetchUserAccesses.request());
    const { data } = yield call(apiGet, url, JSON_OPTS);
    yield put(fetchUserAccesses.success(data));
  } catch (error) {
    yield put(fetchUserAccesses.failure(error.response));
  } finally {
    yield put(fetchUserAccesses.fulfill());
  }
}
