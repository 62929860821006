import _ from 'lodash/fp'
import React from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import ReactPlayer from 'react-player'
import ReactMarkdown from 'react-markdown/with-html'
import 'github-markdown-css'

import MoocsActions from 'scores/components/MoocsActions'
import NovaTalksActions from 'scores/components/NovaTalksActions'
import LearningObjectAdminMenu from './LearningObjectAdminMenu'
import LearningObjectDetail from './LearningObjectDetail'
import LearningObjectCompact from './LearningObjectCompact'
import LearningObjectBadges from './LearningObjectBadges'
import { LoActions, LoActionPoints } from 'scores/components'
import parseLoImage from 'learning_objects/loImageParser'

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: '25px',
    paddingBottom: '0px',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '15px',
  },
  left_column: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
  },
  right_column: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    paddingLeft: '15px',
    maxHeight: 'calc(100vh - 123px)',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  img: {
    objectFit: 'cover',
    width: '200px',
    height: '200px',
    borderRadius: '5px',
  },
  lo_points: {
    textAlign: 'center',
    margin: '15px 0px',
  },
  lo_name: {
    fontSize: '28px',
    marginBottom: '20px',
  },
  lo_description: {
    fontSize: '16px',
    color: '#777',
    marginLeft: '20px',
    marginBottom: '10px',
    whiteSpace: 'pre-line',
  },
  lo_tags: {
    marginLeft: '20px',
    marginBottom: '10px',
    '& > *': {
      margin: '5px',
    },
  },
  detail_row: {
    marginLeft: '20px',
  },
  code_tag: {
    margin: '5px',
    fontWeight: 'bolder',
    fontSize: '14px',
  },
  videoPlayer: {
    padding: '25px',
  },
  childrenLo: {
    padding: '25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
})

const LearningObject = ({ learningObject }) => {
  const classes = useStyles()

  const imageSource = parseLoImage(learningObject)
  const videoUrl = _.prop('metadata.video_url')(learningObject)
  const childrenLos = _.prop('metadata.children_learning_objects')(
    learningObject
  )

  return (
    <Paper className={classes.root}>
      <div className={classes.row}>
        <div className={classes.left_column}>
          <img
            className={classes.img}
            src={imageSource}
            alt={learningObject.name}
          />
          <div className={classes.lo_points}>
            <LoActionPoints learningObject={learningObject} />
          </div>
          {!_.isNil(learningObject.external_url) && (
            <Button
              variant="outlined"
              href={learningObject.external_url}
              target="_blank"
              size="small"
              color="primary"
            >
              Enlace externo
            </Button>
          )}
          <div className={classes.lo_points}>
            <LoActions learningObject={learningObject} />
          </div>
          <LearningObjectAdminMenu learningObject={learningObject} />
        </div>
        <div className={classes.right_column}>
          {_.has('metadata.code')(learningObject) && (
            <div className={classes.code_tag}>
              #{_.prop('metadata.code')(learningObject)}
            </div>
          )}
          <div className={classes.lo_name}>{learningObject.name}</div>
          <div className={classes.lo_description}>
            {learningObject.description}
          </div>
          {learningObject.tags && (
            <div className={classes.lo_tags}>
              {learningObject.tags.map((t, i) => (
                <Chip key={i} label={t} size="small" />
              ))}
            </div>
          )}
          <div className={classes.detail_row}>
            <LearningObjectBadges />
            <LearningObjectDetail learningObject={learningObject} />
          </div>
          {videoUrl && (
            <div className={classes.videoPlayer}>
              {_.includes('drive.google.com')(videoUrl) ? (
                <iframe
                  title="google video"
                  style={{ border: 'none' }}
                  src={videoUrl}
                  width="640"
                  height="360"
                ></iframe>
              ) : (
                <ReactPlayer url={videoUrl} controls />
              )}
            </div>
          )}
          {childrenLos && (
            <div className={classes.childrenLo}>
              {childrenLos.map((lo, idx) => (
                <LearningObjectCompact key={idx} learningObject={lo} />
              ))}
            </div>
          )}
          {_.prop('metadata.content')(learningObject) && (
            <div className="markdown-body">
              <ReactMarkdown
                source={_.get('metadata.content')(learningObject)}
                escapeHtml={false}
              />
            </div>
          )}
          {learningObject.type === 'mooc' && (
            <MoocsActions learningObject={learningObject} />
          )}
          {(learningObject.type === 'nova_talk' ||
            learningObject.type === 'nova_hack') && (
            <NovaTalksActions learningObject={learningObject} />
          )}
        </div>
      </div>
    </Paper>
  )
}
const mapStateToProps = _.pick(['learningObject'])

export default connect(mapStateToProps)(LearningObject)
