import _ from "lodash/fp";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import {
  LocalPlay as LocalPlayIcon,
  Class as ClassIcon,
  MoveToInbox as MoveToInboxIcon,
  PermContactCalendar as PermContactCalendarIcon,
  People as PeopleIcon,
  Security as SecurityIcon,
  SpeakerNotes as SpeakerNotesIcon,
  School as SchoolIcon,
  CastForEducation as CastForEducationIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Timeline as TimelineIcon,
  MonetizationOn as MonetizationOnIcon,
  PieChart as PieChartIcon,
  NewReleases as NewReleasesIcon,
  Rowing as RowingIcon,
  Email as EmailIcon,
} from "@material-ui/icons";

import accountsRoutes from "accounts/routes";
import certificationsRoutes from "certifications/routes";
import emailsRoutes from "emails/routes";
import learningObjectsRoutes from "learning_objects/routes";
import scoresRoutes from "scores/routes";
import systemRoutes from "system/routes";

export const Menu = ({ currentUser }) => {
  const history = useHistory();
  const navigate = (route) => history.push(route);
  return (
    <List>
      <ListItem button onClick={() => navigate(learningObjectsRoutes.MOOCS)}>
        <ListItemIcon>
          <ClassIcon />
        </ListItemIcon>
        <ListItemText primary="Catálogo" />
      </ListItem>
      <ListItem
        button
        onClick={() => navigate(learningObjectsRoutes.NOVA_TALKS)}
      >
        <ListItemIcon>
          <SpeakerNotesIcon />
        </ListItemIcon>
        <ListItemText primary="Nova Talks" />
      </ListItem>
      <ListItem
        button
        onClick={() => navigate(learningObjectsRoutes.NOVA_ACADEMY)}
      >
        <ListItemIcon>
          <SchoolIcon />
        </ListItemIcon>
        <ListItemText primary="Nova Academy" />
      </ListItem>
      <ListItem
        button
        onClick={() => navigate(learningObjectsRoutes.NOVA_PATHS)}
      >
        <ListItemIcon>
          <TimelineIcon />
        </ListItemIcon>
        <ListItemText primary="Nova Paths" />
      </ListItem>
      <ListItem
        button
        onClick={() => navigate(learningObjectsRoutes.NOVA_BLOGS)}
      >
        <ListItemIcon>
          <CastForEducationIcon />
        </ListItemIcon>
        <ListItemText primary="Nova Blogs" />
      </ListItem>
      <ListItem button onClick={() => navigate(accountsRoutes.USERS)}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Ranking de usuarios" />
      </ListItem>
      <ListItem
        button
        onClick={() => navigate(learningObjectsRoutes.LEARNING_OBJECTS_PENDING)}
      >
        <ListItemIcon>
          <MoveToInboxIcon />
        </ListItemIcon>
        <ListItemText primary="Cursos pendientes" />
      </ListItem>
      {_.prop("is_admin")(currentUser) && (
        <>
          <ListSubheader
            style={{ textAlign: "center", width: "240px", height: "30px" }}
          >
            Administración
          </ListSubheader>
          <ListItem
            button
            onClick={() => navigate(certificationsRoutes.CERTIFICATIONS)}
          >
            <ListItemIcon>
              <SecurityIcon />
            </ListItemIcon>
            <ListItemText primary="Certificaciones" />
          </ListItem>
          <ListItem button onClick={() => navigate(emailsRoutes.EMAILS)}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="Emails" />
          </ListItem>
          <ListItem button onClick={() => navigate(scoresRoutes.USER_ACTIONS)}>
            <ListItemIcon>
              <PermContactCalendarIcon />
            </ListItemIcon>
            <ListItemText primary="Solicitudes" />
          </ListItem>
          <ListItem
            button
            onClick={() => navigate(scoresRoutes.USER_ACTIONS_IN_PROGRESS)}
          >
            <ListItemIcon>
              <RowingIcon />
            </ListItemIcon>
            <ListItemText primary="Cursos activos" />
          </ListItem>
          <ListItem
            button
            onClick={() => navigate(scoresRoutes.USER_ACTIONS_LASTEST)}
          >
            <ListItemIcon>
              <NewReleasesIcon />
            </ListItemIcon>
            <ListItemText primary="Actividad reciente" />
          </ListItem>
          <ListItem
            button
            onClick={() => navigate(scoresRoutes.USER_ACTIONS_PAYMENTS)}
          >
            <ListItemIcon>
              <MonetizationOnIcon />
            </ListItemIcon>
            <ListItemText primary="Control de pagos" />
          </ListItem>
          <ListItem button onClick={() => navigate(scoresRoutes.REWARDS)}>
            <ListItemIcon>
              <LocalPlayIcon />
            </ListItemIcon>
            <ListItemText primary="Recompensas" />
          </ListItem>
          <ListItem button onClick={() => navigate(accountsRoutes.BADGES)}>
            <ListItemIcon>
              <AssignmentTurnedInIcon />
            </ListItemIcon>
            <ListItemText primary="Sellos" />
          </ListItem>
          <ListItem
            button
            component="a"
            href="https://metabase.nova.bluetab.net"
            target="_blank"
          >
            <ListItemIcon>
              <PieChartIcon />
            </ListItemIcon>
            <ListItemText primary="Metabase" />
          </ListItem>

          <ListItem button onClick={() => navigate(systemRoutes.DASHBOARD)}>
            <ListItemIcon>
              <AssignmentTurnedInIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard Sistema" />
          </ListItem>
        </>
      )}
    </List>
  );
};

export default Menu;
