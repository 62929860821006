import { fetchBadges } from "../routines";

const fetchingBadges = (state = false, { type }) => {
  switch (type) {
    case fetchBadges.TRIGGER:
      return true;
    case fetchBadges.FULFILL:
      return false;
    default:
      return state;
  }
};

const initialState = [];

const badges = (state = initialState, { type, payload }) => {
  switch (type) {
    case fetchBadges.TRIGGER:
      return initialState;
    case fetchBadges.SUCCESS:
      return payload.data;
    default:
      return state;
  }
};

export { badges, fetchingBadges };
