import _ from "lodash/fp";
import React from "react";
import { useHistory } from "react-router-dom";
import { linkTo } from "accounts/routes";
import Moment from "react-moment";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  name_row: {
    display: "flex",
    alignItems: "center",
  },
  name_elem: {
    cursor: "pointer",
    padding: "0px 5px",
    "&:hover": {
      background: "#eeeeee",
    },
  },
  rate_elem: {
    display: "flex",
    alignItems: "center",
    margin: "0px 10px",
  },
});

const userLabel = ({ given_name, family_name, email }) =>
  _.isNil(given_name) ? email : `${given_name} ${family_name}`;

export default ({ completeUsers }) => {
  const classes = useStyles();
  const history = useHistory();

  const navigate = (user) => history.push(linkTo.USER({ user_id: user.id }));
  return (
    <List>
      {completeUsers.map(({ user, action_timestamp, metadata }, idx) => (
        <ListItem alignItems="flex-start" key={idx}>
          <ListItemAvatar>
            <Avatar src={user.picture} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <div className={classes.name_row}>
                <div
                  className={classes.name_elem}
                  onClick={() => navigate(user)}
                >
                  {userLabel(user)}
                </div>
                <div className={classes.rate_elem}>
                  {_.get("rate")(metadata) && (
                    <Rating
                      value={_.get("rate")(metadata) / 2}
                      precision={0.5}
                      readOnly
                    />
                  )}
                </div>
                <Typography
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  <Moment date={action_timestamp} format="DD/MM/YYYY" />
                </Typography>
              </div>
            }
            secondary={_.get("comment")(metadata)}
          />
        </ListItem>
      ))}
    </List>
  );
};
