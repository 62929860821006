import _ from 'lodash/fp'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { linkTo } from '../routes'
import { makeStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Rating from '@material-ui/lab/Rating'
import Chip from '@material-ui/core/Chip'
import { BadgesCompact } from 'accounts/components'
import parseLoImage from 'learning_objects/loImageParser'
import { LearningObjectStatsCompact } from '.'
import { PointsCompact } from 'scores/components'

const useStyles = makeStyles({
  post_item: {
    margin: '5px 15px',
    padding: '15px',
    cursor: 'pointer',
    display: 'flex',
  },
  post_image: {
    width: '177px',
    height: '100px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  item_content: {
    flexGrow: '1',
    padding: '0px 15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
    fontSize: '15px',
  },
  code_tag: {
    margin: '5px',
    fontWeight: 'bolder',
    fontSize: '11px',
    background: '#ffffffbd',
    padding: '2px',
    borderRadius: '10px',
    minWidth: '19px',
    textAlign: 'center',
  },
  certificate_tag: {
    float: 'right',
    background: '#60ff2dab',
    margin: '5px',
    fontWeight: 'bolder',
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: '5px',
    '& > *': {
      margin: '2px',
    },
  },
  rating: {
    display: 'flex',
    alignItems: 'center',
  },
  rate_count: {
    marginLeft: '5px',
    fontSize: '12px',
    whiteSpace: 'nowrap',
  },
})

export default ({ addTagFilter, activeTags, learningObject }) => {
  const classes = useStyles()
  const history = useHistory()
  const navigate = () => history.push(linkTo.LEARNING_OBJECT(learningObject))
  const imageSource = parseLoImage(learningObject)

  return (
    <Paper
      className={classes.post_item}
      onClick={() => navigate(learningObject)}
    >
      <div
        className={classes.post_image}
        style={{ backgroundImage: `url(${imageSource})` }}
      />
      <div className={classes.item_content}>
        <div className={classes.title}>
          <div className={classes.code_tag}>
            #{_.prop('metadata.code')(learningObject)}
          </div>
          {_.get('name')(learningObject)}
          {_.prop('metadata.certification')(learningObject) && (
            <Chip
              className={classes.certificate_tag}
              label="Certificación"
              size="small"
            />
          )}
        </div>
        {learningObject.tags && (
          <div className={classes.tags}>
            {learningObject.tags.map((t, i) => (
              <Chip
                size="small"
                variant={activeTags.indexOf(t) >= 0 ? 'default' : 'outlined'}
                key={i}
                label={t}
                clickable
                onClick={(e) => {
                  e.stopPropagation()
                  addTagFilter(t)
                }}
              />
            ))}
          </div>
        )}
        <BadgesCompact badges={learningObject.badgesIds} />
      </div>
      <div className={classes.detail}>
        <PointsCompact points={_.get('requestCost')(learningObject)} />
        {_.get('rateAverage')(learningObject) && (
          <div className={classes.rating}>
            <Rating
              value={_.get('rateAverage')(learningObject) / 2}
              precision={0.5}
              readOnly
            />
            <div className={classes.rate_count}>
              ( {_.get('rateCount')(learningObject)} )
            </div>
          </div>
        )}
        <LearningObjectStatsCompact learningObject={learningObject} />
      </div>
    </Paper>
  )
}
