import _ from "lodash/fp";
import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import FormBody from "./FormBody";
import { validationErrors, formatValue } from "./formUtils";

export default ({
  fields,
  defaultItem,
  onSubmit,
  onCancel,
  submitText = "Solicitar",
}) => {
  const [item, setItem] = useState(defaultItem);
  const [errors, setErrors] = useState({});

  useEffect(() => setItem(defaultItem), [defaultItem]);

  const handleSubmit = () => {
    const requiredErrors = validationErrors(fields, item);

    if (_.isEmpty(requiredErrors)) {
      setErrors({});
      onSubmit(formatValue(fields, item));
    } else {
      setErrors(requiredErrors);
    }
  };

  return (
    <form noValidate autoComplete="off">
      <FormBody
        value={item}
        setValue={setItem}
        errors={errors}
        fields={fields}
      />
      <Button
        onClick={handleSubmit}
        variant="contained"
        fullWidth
        color="primary"
      >
        {submitText}
      </Button>
      <Button
        onClick={onCancel}
        variant="contained"
        fullWidth
        color="secondary"
      >
        Cancelar
      </Button>
    </form>
  );
};
