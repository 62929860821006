import React from "react";
import { useDispatch } from "react-redux";
import { updateUserAction } from "scores/routines";

import Button from "@material-ui/core/Button";

export default ({ userActionId }) => {
  const dispatch = useDispatch();

  const handleExpireUserAction = () =>
    dispatch(
      updateUserAction({
        user_action: { id: userActionId, status: "EXPIRED" },
        fetchActionTypes: ["request", "complete", "out_complete"],
      })
    );

  return (
    <Button variant="outlined" size="small" onClick={handleExpireUserAction}>
      Expirado
    </Button>
  );
};
