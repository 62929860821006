import _ from 'lodash/fp'
import {
  useQuery,
  gql
} from "@apollo/client"

const BADGES = gql`
  query {
    badges {
      id
      image
      name
      order
      target
      insertedAt
      updatedAt
    }
  }
`

export default () => {
  const { loading, data } = useQuery(BADGES)
  const badges = _.getOr([], 'badges')(data)
  return {loading, badges}
}