import _ from 'lodash/fp'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  IconButton,
} from '@material-ui/core'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'

import { linkTo } from 'accounts/routes'
import systemRoutes from 'system/routes'
import Menu from './Menu'
import PrivateRoutes from './PrivateRoutes'
import LogoutButton from './LogoutButton'
import PointsCompact from 'scores/components/PointsCompact'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    flexGrow: 1,
    cursor: 'pointer',
  },
  username: {
    marginLeft: '5px',
    marginRight: '8px',
  },
  drawerPaper: {
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: '100vh',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: '58px',
    [theme.breakpoints.up('sm')]: {
      width: '58px',
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    padding: '10px',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  userInfo: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    '&:hover': {
      background: '#ffffff2e',
    },
  },
  userPoints: {
    background: 'white',
    display: 'inherit',
    borderRadius: '15px',
  },
}))

const PrivateApp = ({ currentUser, history }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const navigateUser = () =>
    history.push(linkTo.USER({ user_id: currentUser.id }))
  const navigateHome = () => history.push('/')
  const navigateIssues = () => history.push(systemRoutes.ISSUES)
  return (
    <div className="private-app">
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className="toolbar">
          <Typography
            variant="h5"
            color="inherit"
            noWrap
            className={classes.title}
            onClick={navigateHome}
          >
            Nova Project
          </Typography>
          <div className={classes.userInfo} onClick={navigateUser}>
            <Avatar src={currentUser && currentUser.picture} />
            <Typography className={classes.username}>
              {currentUser && currentUser.given_name}
            </Typography>
            <div className={classes.userPoints}>
              <PointsCompact points={_.getOr({}, 'points')(currentUser)} />
            </div>
          </div>
          <IconButton
            onClick={navigateIssues}
            color="inherit"
            title="Incidencias y sugerencias"
          >
            <NotificationImportantIcon />
          </IconButton>
          <LogoutButton />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <Toolbar />
        <Menu currentUser={currentUser} />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <PrivateRoutes />
      </main>
    </div>
  )
}

export default compose(withRouter, connect(_.pick(['currentUser'])))(PrivateApp)
