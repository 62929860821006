import _ from "lodash/fp";
import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchLearningObject } from "../routines";

import { LinearProgress } from "@material-ui/core";

const LearningObjectLoader = ({
  fetchLearningObject,
  fetchingLearningObject,
}) => {
  const { id } = useParams();
  useEffect(() => {
    fetchLearningObject({ id });
  }, [fetchLearningObject, id]);
  return fetchingLearningObject ? <LinearProgress /> : null;
};

const mapStateToProps = _.pick(["fetchingLearningObject"]);

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchLearningObject,
    },
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LearningObjectLoader);
