import { fetchGenericActions } from "../routines";

const fetchingGenericActions = (state = false, { type }) => {
  switch (type) {
    case fetchGenericActions.TRIGGER:
      return true;
    case fetchGenericActions.FULFILL:
      return false;
    default:
      return state;
  }
};

const initialState = [];

const genericActions = (state = initialState, { type, payload }) => {
  switch (type) {
    case fetchGenericActions.SUCCESS:
      return payload.data;
    default:
      return state;
  }
};

export { genericActions, fetchingGenericActions };
