import _ from 'lodash/fp'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Container, CssBaseline } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { updateLearningObject } from '../routines'
import { linkTo } from '../routes'

import GenericForm from 'common/GenericForm'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}))

const moocFormFields = [
  { field: 'external_url', label: 'Enlace', required: true },
  { field: 'name', label: 'Título', required: true },
  {
    field: 'description',
    label: 'Descripción',
    multiline: true,
  },
  { field: 'subject', label: 'Tema' },
  {
    field: 'tags',
    label: 'Etiquetas (Enter para añadir nuevas)',
    type: 'chips',
  },
  {
    field: 'metadata.code',
    label: 'Código',
    type: 'numeric',
  },
  {
    field: 'metadata.certification',
    label: 'Certificación',
    type: 'checkbox',
  },
  {
    field: 'metadata.duration',
    label: 'Duración (horas)',
    type: 'numeric',
  },
  {
    field: 'metadata.real_cost',
    label: 'Precio',
    required: true,
    type: 'numeric',
  },
  { field: 'metadata.image', label: 'URL Imagen' },
]
const novaTalkFormFields = [
  { field: 'name', label: 'Título', required: true },
  {
    field: 'description',
    label: 'Descripción',
    multiline: true,
  },
  { field: 'subject', label: 'Tema', required: true },
  {
    field: 'tags',
    label: 'Etiquetas (Enter para añadir nuevas)',
    type: 'chips',
  },
  {
    field: 'metadata.speakers',
    label: 'Ponentes',
    type: 'users',
    required: true,
  },
  { field: 'metadata.image', label: 'URL Imagen' },
  { field: 'publish_date', label: 'Fecha de charla', type: 'date' },
  { field: 'metadata.video_url', label: 'Enlace al video' },
]
const novaBlogFormFields = [
  { field: 'name', label: 'Título', required: true },
  {
    field: 'description',
    label: 'Descripción',
    multiline: true,
  },
  { field: 'subject', label: 'Tema', required: true },
  {
    field: 'tags',
    label: 'Etiquetas (Pulsa Enter para añadir nuevas)',
    type: 'chips',
  },
  { field: 'publish_date', label: 'Fecha de publicación', type: 'date' },
  {
    field: 'metadata.content',
    label: 'Contenido',
    required: true,
    multiline: true,
  },
  { field: 'metadata.image', label: 'URL Imagen' },
]
const novaPathFormFields = [
  { field: 'name', label: 'Título', required: true },
  {
    field: 'description',
    label: 'Descripción',
    multiline: true,
  },
  { field: 'subject', label: 'Tema', required: true },
  {
    field: 'tags',
    label: 'Etiquetas (Pulsa Enter para añadir nuevas)',
    type: 'chips',
  },
  { field: 'metadata.image', label: 'URL Imagen' },
  {
    field: 'metadata.children_learning_objects',
    label: 'Cursos',
    type: 'learning_object',
  },
]

export default () => {
  const { learningObject } = useSelector(_.pick(['learningObject']))
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const handleSubmit = (item) => {
    const itemClean = _.omit(['actions', 'type'])(item)
    const nextUrl = _.isEmpty(_.getOr([], 'actions')(item))
      ? linkTo.LEARNING_OBJECT_ACTIONS_EDIT(learningObject)
      : linkTo.LEARNING_OBJECT(learningObject)
    dispatch(updateLearningObject({ id: learningObject.id, ...itemClean }))
    history.push(nextUrl)
  }

  return (
    <Container maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <h1>Editar</h1>
        <GenericForm
          fields={
            _.prop('type')(learningObject) === 'nova_talk'
              ? novaTalkFormFields
              : _.prop('type')(learningObject) === 'nova_blog'
              ? novaBlogFormFields
              : _.prop('type')(learningObject) === 'nova_path'
              ? novaPathFormFields
              : moocFormFields
          }
          defaultItem={learningObject}
          onSubmit={handleSubmit}
          onCancel={() => history.goBack()}
          submitText="Guardar"
        />
      </div>
    </Container>
  )
}
