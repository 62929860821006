import _ from "lodash/fp";
import pathToRegexp from "path-to-regexp";

const routes = {
  ISSUES: "/issues",
  DASHBOARD: "/system_dashboard",
};

const linkTo = _.mapValues(pathToRegexp.compile, routes);

export { routes as default, linkTo };
