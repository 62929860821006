import _ from "lodash/fp";
import React, { useEffect } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import queryString from "query-string";
import { login } from "../routines";

export const OpenIDConnect = ({ authenticating, location, login }) => {
  const { hash } = location;
  useEffect(() => {
    const { id_token } = queryString.parse(hash);
    login(id_token);
  }, [login, hash]);

  const locationPath = _.path("state.from.pathname")(location);
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN);
  if (token) return <Redirect to={locationPath || "/"} />;

  return (
    <span>{authenticating ? "Authenticated" : "Done authenticatings"}</span>
  );
};

const mapStateToProps = _.pick(["authenticating"]);
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ login }, dispatch)
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OpenIDConnect);
