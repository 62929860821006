import _ from "lodash/fp";
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";

const useStyles = makeStyles({
  detail_row: {
    marginLeft: "10px",
    display: "flex",
    alignItems: "center",
    marginBottom: "2px",
  },
  detail_row_title: {
    fontSize: "16px",
  },
  detail_row_value: {
    marginLeft: "5px",
    fontSize: "13px",
  },
});

export default ({ learningObject }) => {
  const classes = useStyles();

  const detailFields = [
    {
      field: "metadata.duration",
      Icon: ScheduleIcon,
      suffix: " horas",
    },
    {
      field: "publish_date",
      Icon: EventIcon,
    },
    {
      field: "metadata.real_cost",
      Icon: LocalAtmIcon,
      suffix: "€",
    },
  ];

  return (
    <div>
      {detailFields.map(({ field, Icon, suffix }, key) =>
        _.prop(field)(learningObject) ? (
          <div className={classes.detail_row} key={key}>
            <Icon className={classes.detail_row_title} />
            <div className={classes.detail_row_value}>
              {_.prop(field)(learningObject) + (suffix || "")}
            </div>
          </div>
        ) : null
      )}
    </div>
  );
};
