import { all } from "redux-saga/effects";
import accountsSagas from "./accounts/sagas";
import learningObjectsSagas from "./learning_objects/sagas";
import scoresSagas from "./scores/sagas";
import systemSagas from "./system/sagas";

export default function* sagas() {
  yield all([
    ...accountsSagas,
    ...learningObjectsSagas,
    ...scoresSagas,
    ...systemSagas,
  ]);
}
