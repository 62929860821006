import React from "react";
import { Switch, Route } from "react-router-dom";

import routes from "../routes";
import { Issues, IssuesLoader } from ".";

export const AccountsRouter = () => (
  <Switch>
    <Route
      path={routes.BADGES}
      exact
      render={() => (
        <>
          <IssuesLoader />
          <Issues />
        </>
      )}
    />
  </Switch>
);

export default AccountsRouter;
