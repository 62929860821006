import _ from "lodash/fp";
import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Redirect } from "react-router-dom";
import { Button, TextField } from "@material-ui/core";
import "./Login.css";

import { fakeLogin } from "../routines";

const Login = ({ fakeLogin, authenticating }) => {
  const [fakeLoginEmail, setFakeLoginEmail] = useState("");
  const handleFakeLogin = () => {
    if (!fakeLoginEmail.includes("@")) {
      alert("must include @");
      return;
    }
    fakeLogin({ email: fakeLoginEmail });
  };

  const token = localStorage.getItem(process.env.REACT_APP_TOKEN);
  if (token) return <Redirect to={"/"} />;

  return (
    <div className="login">
      <div className="logo-login">
        <img src="logo_nova.svg" alt="Nova Project" />
      </div>
      <div className="center-google" style={{ textAlign: "center" }}>
        <TextField
          value={fakeLoginEmail}
          onChange={({ target }) => setFakeLoginEmail(target.value)}
        />
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleFakeLogin}
        >
          Fake login
        </Button>
      </div>
      <img className="icon-bluetab" src="bluetab_ibm.png" alt="Bluetab" />
    </div>
  );
};

const mapStateToProps = _.pick(["authenticating"]);
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ fakeLogin }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
