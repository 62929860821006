import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN)
  return token
    ? {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      }
    : { headers };
});

const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_HOST + '/api/v2' });

const errorLink = () =>
  onError(({ networkError, graphQLErrors }) => {
    console.error({ networkError, graphQLErrors })
  });

export const createClient = () =>
  new ApolloClient({
    cache: new InMemoryCache({}),
    link: authLink.concat(errorLink()).concat(httpLink),
  });
