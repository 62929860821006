import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton, Modal } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "75%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  body: {
    width: "100%",
    marginBottom: "15px",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  divider: {
    width: "5px",
  },
  fullWidth: {
    width: "100%",
  },
  margin: {
    margin: "0px 3px",
  },
  compact: {
    fontSize: "11px",
    padding: "0px 10px",
  },
}));

const ButtonModal = ({
  buttonText,
  buttonIcon,
  modalTitle,
  children,
  onSubmit,
  onReject,
  fullWidth,
  compact,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClose = () => setOpen(false);

  return (
    <>
      {buttonIcon ? (
        <IconButton
          variant="outlined"
          size="small"
          onClick={() => setOpen(true)}
        >
          {buttonIcon}
        </IconButton>
      ) : (
        <Button
          className={
            (fullWidth ? classes.fullWidth : classes.margin) +
            (compact ? ` ${classes.compact}` : "")
          }
          variant="outlined"
          size="small"
          onClick={() => setOpen(true)}
        >
          {buttonText}
        </Button>
      )}
      <Modal open={open} onClose={handleClose}>
        <div className={classes.paper}>
          <h2>{modalTitle}</h2>
          <div className={classes.body}>{children}</div>
          <div className={classes.buttons}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                if (onSubmit() !== false) handleClose();
              }}
            >
              Aceptar
            </Button>

            {onReject && (
              <>
                <div className={classes.divider} />
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    onReject();
                    handleClose();
                  }}
                >
                  Rechazar
                </Button>
              </>
            )}
            <div className={classes.divider} />
            <Button variant="outlined" size="small" onClick={handleClose}>
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ButtonModal;
