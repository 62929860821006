import _ from "lodash/fp";
import React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { updateUserAction } from "scores/routines";

import Button from "@material-ui/core/Button";

export default ({ userAction }) => {
  const dispatch = useDispatch();

  const payment = _.prop("metadata.payment")(userAction);

  const handleExpireUserAction = () =>
    dispatch(
      updateUserAction({
        user_action: {
          id: userAction.id,
          metadata: {
            ...(userAction.metadata || {}),
            payment: payment ? null : new Date(),
          },
        },
        fetchActionTypes: ["request", "complete"],
      })
    );

  return (
    <>
      {payment && <div>Pagado el {moment(payment).format("DD/MM/YYYY")}</div>}
      <Button variant="outlined" size="small" onClick={handleExpireUserAction}>
        {payment ? "Marcar como no pagado" : "Marcar pagado"}
      </Button>
    </>
  );
};
