import _ from "lodash/fp";
import React from "react";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import ActionTypeChip from "./ActionTypeChip";

import { makeStyles } from "@material-ui/core/styles";

import { LearningObjectCompact } from "learning_objects/components";
import { DeleteUserActionButton } from "./userActionButtons";

const useStyles = makeStyles({
  row: {
    display: "flex",
    alignItems: "flex-start",
    fontSize: "18px",
    fontWeight: "bold",
    background: "#fffee9",
    padding: "10px",
    margin: "2px 10px",
    border: "1px solid #a9a931",
    borderRadius: "3px",
    flexDirection: "column",
  },
  row_list: {
    marginLeft: "25px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row_list_item: {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: "normal",
    "& .MuiChip-root": {
      margin: "0px 10px",
    },
  },
});

const UserActionsPendingRow = ({ pendingActions }) => {
  const classes = useStyles();

  const { currentUser, user } = useSelector(
    _.pick(["currentUser", "userActions", "user"])
  );
  const isMyProfile = _.prop("id")(currentUser) === _.prop("id")(user);

  const title = isMyProfile
    ? "Tus solicitudes pendientes"
    : "Solicitudes pendientes del usuario";

  return (_.prop("is_admin")(currentUser) || isMyProfile) &&
    !_.isEmpty(pendingActions) ? (
    <div className={classes.row}>
      {title}
      <div className={classes.row_list}>
        {pendingActions.map((x, key) => (
          <div className={classes.row_list_item} key={key}>
            <Moment date={_.prop("action_timestamp")(x)} format="DD/MM/YYYY" />
            <ActionTypeChip action_type={_.prop("action.action_type")(x)} />
            <LearningObjectCompact
              learningObject={_.prop("action.learning_object")(x)}
            />
            <DeleteUserActionButton
              userActionId={_.prop("id")(x)}
              userId={_.prop("id")(user)}
              confirmMessage="Cancelar la solicitud"
            />
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default UserActionsPendingRow;
