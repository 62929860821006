import _ from "lodash/fp";
import React from "react";
import useBadges from 'queries/badges'
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { updateLearningObjectBadges } from "../routines";

const useStyles = makeStyles({
  badges: { display: "flex", alignItems: "center" },
  badge: {
    margin: "3px",
  },
  badge_clickable: {
    cursor: "pointer",
    "&:hover": {
      filter: "grayscale(0.5)",
    },
  },
  inactive: {
    filter: "grayscale(1)",
  },
  active: {
    filter: "none",
  },
});

export default () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {badges} = useBadges()
  const {
    learningObject,
    currentUser: { is_admin },
  } = useSelector(_.pick(["learningObject", "currentUser"]));
  if (!learningObject) return null;

  const doUpdateBadge = (id) => {
    const hasBadge = _.indexOf(id)(learningObject.badges) >= 0;
    const newBadges = _.flow(
      _.get("badges"),
      _.reject((item) => item === id),
      _.concat(hasBadge ? [] : [id])
    )(learningObject);
    dispatch(
      updateLearningObjectBadges({ id: learningObject.id, badges: newBadges })
    );
  };

  const orderedBadges = _.flow(
    _.filter({ target: "learning_object" }),
    _.orderBy(["order"], ["asc"])
  )(badges);

  const learningObjectBadges = _.getOr([], "badges")(learningObject);
  const learningObjectOrderedBadges = _.filter(({ id }) =>
    _.includes(id)(learningObjectBadges)
  )(orderedBadges);

  return (
    <>
      <div className={classes.badges}>
        {is_admin
          ? orderedBadges.map(({ image, name, id }, key) => (
              <div
                key={key}
                className={`${classes.badge} ${classes.badge_clickable} ${
                  _.indexOf(id)(learningObject.badges) >= 0
                    ? classes.active
                    : classes.inactive
                }`}
                title={name}
                onClick={() => doUpdateBadge(id)}
              >
                <img src={image} width="48px" alt={name} />
              </div>
            ))
          : learningObjectOrderedBadges.map(({ name, image }, key) => (
              <div
                key={key}
                className={`${classes.badge} ${classes.active}`}
                title={name}
              >
                <img src={image} width="48px" alt={name} />
              </div>
            ))}
      </div>
    </>
  );
};
