import _ from "lodash/fp";
import React from "react";
import { useSelector } from "react-redux";

import { LearningObjectPreview } from ".";
import { UserActionsEditor } from "scores/components";

export default () => {
  const learningObject = useSelector(_.get("learningObject"));
  return (
    <>
      <LearningObjectPreview learningObject={learningObject} />
      <UserActionsEditor />
    </>
  );
};
