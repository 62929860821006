export default {
  orderFields: {
    lowest_cost: 'ZIRCOINS_COST',
    highest_cost: 'ZIRCOINS_COST',
    most_active: 'ACTIVE_COUNT',
    most_complete: 'COMPLETE_COUNT',
    most_access: 'ACCESS_COUNT',
    better_rate: 'RATE_SCORE'
  },
  orderDirections: {
    lowest_cost: 'ASC',
    highest_cost: 'DESC',
    most_active: 'DESC',
    most_complete: 'DESC',
    most_access: 'DESC',
    better_rate: 'DESC',
  },
  orderChips: [
    { key: 'better_rate', label: 'Mejor valorado' },
    { key: 'lowest_cost', label: 'Mayor coste zircoins' },
    { key: 'highest_cost', label: 'Menor coste zircoins' },
    { key: 'most_active', label: 'Más activo' },
    { key: 'most_complete', label: 'Más completado' },
    { key: 'most_access', label: 'Más accedidos' },
  ],
}
