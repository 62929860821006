const API_SIGN_IN = "api/v1/sign_in";
const API_MY_USER = "api/v1/my_user";
const API_SESSION = "api/v1/session";
const API_SESSION_FAKE = "api/v1/session/fake";
const API_SESSION_AUTHORIZATION_URI = "api/v1/session/authorization-uri";
const API_USERS = "api/v1/users";
const API_USER = "api/v1/users/:user_id";
const API_USER_ACCCESSES = "api/v1/user_accesses";
const API_BADGES = "api/v1/badges";
const API_BADGE = "api/v1/badges/:id";
const API_USER_BADGES = "api/v1/users/:id/badges";

export {
  API_SIGN_IN,
  API_MY_USER,
  API_SESSION,
  API_SESSION_FAKE,
  API_SESSION_AUTHORIZATION_URI,
  API_USERS,
  API_USER,
  API_USER_ACCCESSES,
  API_BADGES,
  API_BADGE,
  API_USER_BADGES,
};
