import { createRoutine } from "redux-saga-routines";

export const fetchLearningObjects = createRoutine("FETCH_LEARNING_OBJECTS");
export const fetchLearningObject = createRoutine("FETCH_LEARNING_OBJECT");
export const createLearningObject = createRoutine("CREATE_LEARNING_OBJECT");
export const updateLearningObject = createRoutine("UPDATE_LEARNING_OBJECT");
export const deleteLearningObject = createRoutine("DELETE_LEARNING_OBJECT");
export const updateLOCode = createRoutine("UPDATE_LO_CODE");

export const updateLearningObjectBadges = createRoutine(
  "UPDATE_LEARNING_OBJECT_BADGES"
);
