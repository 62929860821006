import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import 'moment/locale/es'

import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './store'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import { ApolloProvider } from "@apollo/client"
import { createClient } from "./graphql"

import './index.css'
import AppRoutes from './AppRoutes'

moment.locale('es')

const darkTheme = createMuiTheme({
  // palette: {
  //   type: "dark",
  // },
  typography: {
    fontFamily: '"Exo 2"',
  },
})

const client = createClient()

const App = () => (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <ThemeProvider theme={darkTheme}>
        <Router>
          <AppRoutes />
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  </Provider>
)

var mountNode = document.getElementById('root')
ReactDOM.render(<App />, mountNode)
