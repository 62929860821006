import _ from "lodash/fp";
import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { createUserAction } from "scores/routines";

import ButtonModal from "common/ButtonModal";
import { makeStyles } from "@material-ui/core/styles";

import { minPointsSum } from "scores/constants";
import FormBody from "common/FormBody";
import { validationErrors, formatValue } from "common/formUtils";

const useStyles = makeStyles({
  requested: {
    display: "inline",
    marginLeft: "5px",
    background: "#828282",
    borderRadius: "15px",
    color: "white",
    padding: "1px 8px",
  },
});

const fields = [
  {
    field: "metadata.reason",
    label: "Cuéntanos el motivo por el cuál quieres hacer ese curso",
    multiline: true,
    required: true,
  },
  { field: "metadata.cost", type: "numeric", label: "Coste €", required: true },
];

const RequestButton = ({ learningObject }) => {
  const [form, setForm] = useState({});
  const [formErrors, setFormErrors] = useState();
  const classes = useStyles();
  const currentUser = useSelector(_.get("currentUser"));
  const dispatch = useDispatch();
  const { create } = bindActionCreators({ create: createUserAction }, dispatch);

  const requestAction = _.flow(
    _.get("actions"),
    _.filter({ action_type: "request" }),
    _.orderBy("inserted_at", "desc"),
    _.head,
    _.defaultTo({})
  )(learningObject);

  const desistAction = _.flow(
    _.get("actions"),
    _.filter({ action_type: "desist" }),
    _.orderBy("inserted_at", "desc"),
    _.head,
    _.defaultTo({})
  )(learningObject);

  const minPointOnRequest = minPointsSum(
    _.getOr({}, "points")(currentUser),
    requestAction
  );

  const lastTimeUserAction = _.flow(
    _.prop("user_actions"),
    _.filter({ status: "APPROVED", user: { email: currentUser.email } }),
    _.orderBy("action_timestamp", "desc"),
    _.head,
    _.prop("action_timestamp"),
    (isoDate) => _.toNumber(new Date(isoDate))
  );

  const lastTimeRequestAction = lastTimeUserAction(requestAction);
  const lastTimeDesistAction = lastTimeUserAction(desistAction);
  const hasBeenDesisted =
    !_.isNaN(lastTimeDesistAction) &&
    lastTimeDesistAction > lastTimeRequestAction;

  const isMyPendingRequest = !_.isNil(
    _.flow(
      _.get("user_actions"),
      _.filter({ status: "PENDING" }),
      _.find({ user: { id: currentUser.id } })
    )(requestAction)
  );

  const reachedMaximumAmountOfCourses =
    learningObject.requested_moocs_by_user >= 3;

  const canRequest =
    (_.isNaN(lastTimeRequestAction) || hasBeenDesisted) &&
    !isMyPendingRequest &&
    minPointOnRequest >= 0;

  const onSubmit = () => {
    const errors = validationErrors(fields, form);
    if (!_.isEmpty(errors)) {
      setFormErrors(errors);
      return false;
    }
    create({
      ...formatValue(fields, form),
      user: currentUser,
      action: requestAction,
      lo_id: learningObject.id,
    });
  };
  const requestButton = reachedMaximumAmountOfCourses ? (
    <div className={classes.requested}>Excedido limite de cursos</div>
  ) : (
    <ButtonModal
      fullWidth
      buttonText="Solicitar"
      modalTitle={learningObject.name}
      onSubmit={onSubmit}
    >
      <FormBody
        value={form}
        setValue={setForm}
        fields={fields}
        errors={formErrors}
      />
    </ButtonModal>
  );

  return canRequest ? (
    requestButton
  ) : isMyPendingRequest ? (
    <div className={classes.requested}>Solicitud pendiente</div>
  ) : null;
};

export default RequestButton;
