import _ from "lodash/fp";
import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateLOCode } from "learning_objects/routines";
import { linkTo } from "learning_objects/routes";

import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Button } from "@material-ui/core";

import FormBody from "common/FormBody";
import { validationErrors, formatValue } from "common/formUtils";
import { getActionTypeText } from "../constants";

const useStyles = makeStyles({
  card: {
    width: "100%",
    marginTop: "5px",
  },
  button: {
    padding: "15px",
  },
});

const MoocsActionsForm = ({
  updateLOCode,
  learningObject,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [form, setForm] = useState({ insert: { community_points: 5 } });
  const [formErrors, setFormErrors] = useState();

  const costZircoins =
    Math.floor(
      _.getOr(0, "metadata.real_cost")(learningObject) *
        Math.pow(300000, 2) *
        Math.pow(10, -12)
    ) * -1;
  useEffect(
    () =>
      setForm((f) => ({
        ...f,
        request: { zircoins: costZircoins },
      })),
    [costZircoins]
  );
  const rewardZircoins = Math.round(
    (_.getOr(0, "metadata.duration")(learningObject) / 10) * 1.5 + 0.5
  );
  const rewardLearning = Math.round(
    _.getOr(0, "metadata.duration")(learningObject) * 10
  );
  useEffect(
    () =>
      setForm((f) => ({
        ...f,
        complete: { zircoins: rewardZircoins, learning_points: rewardLearning },
      })),
    [rewardZircoins, rewardLearning]
  );

  const fields = [
    {
      field: "insert",
      label: getActionTypeText("insert"),
      type: "points",
      showOnly: ["community_points"],
    },
    {
      field: "request",
      label: getActionTypeText("request"),
      type: "points",
      showOnly: ["zircoins"],
    },
    {
      field: "complete",
      label: getActionTypeText("complete"),
      type: "points",
      showOnly: ["zircoins", "learning_points"],
    },
    {
      field: "desist",
      label: getActionTypeText("desist"),
      type: "points",
      showOnly: ["zircoins"],
    },
  ];

  const onSubmit = () => {
    const errors = validationErrors(fields, form);
    if (!_.isEmpty(errors)) {
      setFormErrors(errors);
      return false;
    }

    const { request, insert, complete, desist } = formatValue(fields, form);

    const id = _.prop("id")(learningObject);
    const user = _.prop("creator.id")(learningObject);

    updateLOCode({
      id,
      user,
      actions: [
        {
          ...request,
          action_type: "request",
        },
        {
          ...complete,
          action_type: "complete",
        },
        {
          ...complete,
          zircoins: 0,
          action_type: "out_complete",
        },
        {
          ...desist,
          action_type: "desist",
        },
        {
          ...insert,
          action_type: "insert",
        },
      ]
    });
    history.push(linkTo.LEARNING_OBJECT(learningObject));
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <FormBody
          value={form}
          setValue={setForm}
          fields={fields}
          errors={formErrors}
        />
        <Button variant="outlined" fullWidth onClick={onSubmit}>
          Verificar curso
        </Button>
      </CardContent>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { updateLOCode },
    dispatch
  );

export default connect(null, mapDispatchToProps)(MoocsActionsForm);
