import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { Login, FakeLogin, RetrieveSession, OpenIDConnect } from './accounts/components'
import { Redirector } from './Redirector'

import PrivateApp from './PrivateApp'

export const AppRoutes = ({ redirectUrl }) => (
  <Switch>
    <Route path="/oidc" component={OpenIDConnect} />
    {redirectUrl && <Redirector redirectUrl={redirectUrl} />}
    {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
      <Route path="/login_fake" component={FakeLogin} />
    }
    <Route path="/login" component={Login} />
    <Route path="/retrieveSession" component={RetrieveSession} />
    <PrivateApp />
  </Switch>
)

export default compose(
  withRouter,
  connect(({ redirectUrl }) => ({ redirectUrl }))
)(AppRoutes)
