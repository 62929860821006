import _ from 'lodash/fp'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { linkTo } from '../routes'
import { makeStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import parseLoImage from 'learning_objects/loImageParser'
import { LearningObjectStatsCompact } from '.'

const useStyles = makeStyles({
  post_item: {
    margin: '5px 15px',
    padding: '15px',
    cursor: 'pointer',
    display: 'flex',
  },
  post_image: {
    width: '177px',
    height: '100px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  item_content: {
    flexGrow: '1',
    padding: '0px 15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  detail: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
    fontSize: '15px',
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: '5px',
    '& > *': {
      margin: '2px',
    },
  },
  description: {
    color: '#555',
    maxWidth: '800px',
    whiteSpace: 'pre-line',
    padding: '5px 15px',
  },
})

export default ({ learningObject }) => {
  const classes = useStyles()
  const history = useHistory()
  const navigate = () => history.push(linkTo.LEARNING_OBJECT(learningObject))
  const imageSource = parseLoImage(learningObject)

  return (
    <Paper
      className={classes.post_item}
      onClick={() => navigate(learningObject)}
    >
      <div
        className={classes.post_image}
        style={{ backgroundImage: `url(${imageSource})` }}
      />
      <div className={classes.item_content}>
        <div className={classes.title}>{_.get('name')(learningObject)}</div>
        <div className={classes.description}>
          {_.get('description')(learningObject)}
        </div>
        {learningObject.tags && (
          <div className={classes.tags}>
            {learningObject.tags.map((t, i) => (
              <Chip size="small" variant="outlined" key={i} label={t} />
            ))}
          </div>
        )}
      </div>
      <div className={classes.detail}>
        <div>{_.get('subject')(learningObject)}</div>
        <LearningObjectStatsCompact learningObject={learningObject} />
      </div>
    </Paper>
  )
}
