import React from "react";
import { Switch, Route } from "react-router-dom";

import routes from "../routes";
import { Emails } from ".";

export default function EmailsRouter() {
  return (
    <>
      <Switch>
        <Route path={routes.EMAILS} component={Emails} />
      </Switch>
    </>
  );
}
