import _ from "lodash/fp";
import { apiGet, apiPost, apiPatch, apiDelete, JSON_OPTS } from "api";
import pathToRegexp from "path-to-regexp";
import {
  fetchBadges,
  createBadge,
  updateBadge,
  deleteBadge,
} from "../routines";
import { API_BADGES, API_BADGE } from "../api";
import { all, call, put, takeLatest } from "redux-saga/effects";

export function* badgesRequestSaga() {
  yield all([
    takeLatest(fetchBadges.TRIGGER, fetchBadgesSaga),
    takeLatest(createBadge.SUCCESS, fetchBadgesSaga),
    takeLatest(updateBadge.SUCCESS, fetchBadgesSaga),
    takeLatest(deleteBadge.SUCCESS, fetchBadgesSaga),

    takeLatest(createBadge.TRIGGER, createBadgeSaga),
    takeLatest(updateBadge.TRIGGER, updateBadgeSaga),
    takeLatest(deleteBadge.TRIGGER, deleteBadgeSaga),
  ]);
}

/**
 * INDEX
 */
export function* fetchBadgesSaga({ payload }) {
  try {
    const type = _.prop("type")(payload);
    const active = _.prop("active")(payload) ? "true" : "false";
    const activeQuery = `active=${active}`;
    const queryParams = type ? `type=${type}&${activeQuery}` : activeQuery;
    const url = API_BADGES + `?${queryParams}`;
    yield put(fetchBadges.request());
    const { data } = yield call(apiGet, url, JSON_OPTS);
    yield put(fetchBadges.success(data));
  } catch (error) {
    yield put(fetchBadges.failure(error.response));
  } finally {
    yield put(fetchBadges.fulfill());
  }
}

/**
 * CREATE
 */
export function* createBadgeSaga({ payload }) {
  try {
    const url = API_BADGES;
    yield put(createBadge.request());
    const { data: badge } = yield call(
      apiPost,
      url,
      { badge: payload },
      JSON_OPTS
    );
    yield put(createBadge.success(badge));
  } catch (error) {
    yield put(createBadge.failure(error.response));
  } finally {
    yield put(createBadge.fulfill());
  }
}

/**
 * UPDATE
 */
export function* updateBadgeSaga({ payload }) {
  try {
    const url = pathToRegexp.compile(API_BADGE)({ id: payload.id });
    yield put(updateBadge.request());
    const { data: badge } = yield call(
      apiPatch,
      url,
      { badge: payload },
      JSON_OPTS
    );
    yield put(updateBadge.success(badge));
  } catch (error) {
    yield put(updateBadge.failure(error.response));
  } finally {
    yield put(updateBadge.fulfill());
  }
}

/**
 * DELETE
 */
export function* deleteBadgeSaga({ payload }) {
  try {
    const url = pathToRegexp.compile(API_BADGE)({ id: payload });
    yield put(deleteBadge.request());
    yield call(apiDelete, url, JSON_OPTS);
    yield put(deleteBadge.success());
  } catch (error) {
    yield put(deleteBadge.failure(error.response));
  } finally {
    yield put(deleteBadge.fulfill());
  }
}
