import _ from "lodash/fp";
import { login, retrieveSession, fetchUser } from "../routines";

const initialState = null;

const currentUser = (state = initialState, { type, payload }) => {
  switch (type) {
    case login.SUCCESS:
      const { user } = payload;
      return { ...user };
    case retrieveSession.SUCCESS:
      return { ...payload };
    case fetchUser.SUCCESS:
      return _.prop("data.id")(payload) === _.prop("id")(state)
        ? { ...payload.data }
        : state;
    case login.FAILURE:
      return null;
    default:
      return state;
  }
};

export { currentUser };
