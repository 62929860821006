import React from "react";
import { Switch, Route } from "react-router-dom";

import routes from "../routes";
import {
  LearningObject,
  LearningObjectActionsEdit,
  LearningObjectEdit,
  LearningObjectLoader,
  LearningObjectsLoader,
  LearningObjectsPending,
  LearningObjectUserActionsEdit,
  Moocs,
  MoocsNew,
  NovaTalks,
  NovaTalksNew,
  NovaAcademy,
  NovaAcademyNew,
  NovaBlogs,
  NovaBlogsNew,
  NovaPaths,
  NovaPathsNew,
} from ".";
import { UsersLoader } from "accounts/components";

export const LearningObjectsRouter = () => (
  <>
    <Switch>
      <Route exact path={routes.NOVA_TALKS_NEW} component={NovaTalksNew}/>
      <Route exact path={routes.NOVA_ACADEMY_NEW} component={NovaAcademyNew}/>
      <Route exact path={routes.NOVA_BLOGS_NEW} component={NovaBlogsNew}/>
      <Route exact path={routes.NOVA_PATHS_NEW} component={NovaPathsNew}/>
      <Route path={routes.MOOCS_NEW} component={MoocsNew} />
      <Route
        exact
        path={routes.LEARNING_OBJECT_EDIT}
        render={() => (
          <>
            <LearningObjectLoader />
            <LearningObjectEdit />
          </>
        )}
      />
      <Route
        exact
        path={routes.LEARNING_OBJECTS_PENDING}
        render={() => (
          <>
            <LearningObjectsLoader active={false} />
            <LearningObjectsPending />
          </>
        )}
      />
      <Route
        exact
        path={routes.LEARNING_OBJECT_USER_ACTIONS_EDIT}
        render={() => (
          <>
            <UsersLoader />
            <LearningObjectLoader />
            <LearningObjectUserActionsEdit />
          </>
        )}
      />
      <Route
        exact
        path={routes.LEARNING_OBJECT_ACTIONS_EDIT}
        render={() => (
          <>
            <LearningObjectLoader />
            <LearningObjectActionsEdit />
          </>
        )}
      />
      <Route
        exact
        path={routes.LEARNING_OBJECT}
        render={() => (
          <>
            <LearningObjectLoader />
            <LearningObject />
          </>
        )}
      />
      <Route path={routes.NOVA_ACADEMY} component={NovaAcademy}/>
      <Route path={routes.NOVA_TALKS} component={NovaTalks}/>
      <Route path={routes.NOVA_BLOGS} component={NovaBlogs}/>
      <Route path={routes.NOVA_PATHS} component={NovaPaths}/>
      <Route exact path={routes.MOOCS} component={Moocs} />
    </Switch>
  </>
);

export default LearningObjectsRouter;
