import _ from "lodash/fp";
import React from "react";
import { connect } from "react-redux";

import { LearningObjectPreview } from ".";
import MoocsActionsForm from "scores/components/MoocsActionsForm";
import NovaTalksActionsForm from "scores/components/NovaTalksActionsForm";
import NovaBlogsActionsForm from "scores/components/NovaBlogsActionsForm";
import NovaPathsActionsForm from "scores/components/NovaPathsActionsForm";
import LoActionsEditor from "scores/components/LoActionsEditor";

const LearningObjectActionsEdit = ({ learningObject }) => {
  const type = _.prop("type")(learningObject);
  return (
    <>
      <LearningObjectPreview learningObject={learningObject} />
      {_.isEmpty(_.prop("actions")(learningObject)) ? (
        <div>
          <h2>Definir costes y recompensas</h2>
          {type === "mooc" && (
            <MoocsActionsForm learningObject={learningObject} />
          )}
          {type === "nova_talk" && (
            <NovaTalksActionsForm learningObject={learningObject} />
          )}
          {type === "nova_blog" && (
            <NovaBlogsActionsForm learningObject={learningObject} />
          )}
          {type === "nova_path" && (
            <NovaPathsActionsForm learningObject={learningObject} />
          )}
        </div>
      ) : (
        <LoActionsEditor learningObject={learningObject} />
      )}
    </>
  );
};

const mapStateToProps = _.pick(["learningObject"]);

export default connect(mapStateToProps)(LearningObjectActionsEdit);
