import _ from "lodash/fp";
import React from "react";
import Moment from "react-moment";
import PointsCompact from "./PointsCompact";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  row: {
    display: "flex",
    alignItems: "flex-start",
    fontSize: "18px",
    fontWeight: "bold",
    background: "white",
    padding: "10px",
    margin: "2px 10px",
    border: "1px solid #efefef",
    borderRadius: "3px",
    flexDirection: "column",
  },
  row_list: {
    marginLeft: "25px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  row_list_item: {
    margin: "5px 0px",
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: "normal",
    "& .MuiChip-root": {
      margin: "0px 10px",
    },
  },
  row_list_label: {
    margin: "0px 2px",
    fontWeight: "bolder",
  },
  row_list_date: {
    color: "#4d4d4d",
    margin: "0px 4px",
    fontSize: "12px",
  },
});

export default ({ otherActions }) => {
  const classes = useStyles();

  return (
    <div className={classes.row}>
      Recompensas
      <div className={classes.row_list}>
        {otherActions.map((x, key) => (
          <div className={classes.row_list_item} key={key}>
            <div className={classes.row_list_date}>
              <Moment
                date={_.prop("action_timestamp")(x)}
                format="DD/MM/YYYY"
              />
            </div>
            <div className={classes.row_list_label}>
              {_.prop("action.label")(x)}
            </div>
            <PointsCompact points={_.prop("action")(x)} />
          </div>
        ))}
      </div>
    </div>
  );
};
