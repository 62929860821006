import React, { useState, useEffect, useRef } from "react";
import { apiGet, JSON_OPTS } from "api";

const HOST_URL = process.env.REACT_APP_API_HOST;
export default function LiveviewIframe({ route }) {
  const [liveviewNonce, setLiveviewNonce] = useState();

  const iframeRef = useRef();
  useEffect(() => {
    apiGet("api/v1/liveview_nonce", JSON_OPTS).then(({ data: { data } }) =>
      setLiveviewNonce(data)
    );
  }, []);

  // console.log("urlObject", urlObject);

  // const res = await fetch("http://example.com/someiframe", {
  //   method: 'GET',
  //   headers: {
  //     // Here you can set any headers you want
  //   }
  // });
  // const blob = await res.blob();
  // const urlObject = URL.createObjectURL(blob);
  // document.querySelector('iframe').setAttribute("src", urlObject)

  return (
    <div>
      {liveviewNonce ? (
        <iframe
          title={`nova-liveview-${route}`}
          ref={iframeRef}
          src={`${HOST_URL}/${route}/${liveviewNonce}`}
          frameBorder="0"
          height="1000"
          width="100%"
        ></iframe>
      ) : 
      <div> Loading Liveview</div>}
    </div>
  );
}
