import _ from 'lodash/fp'
import React, {useEffect} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import './Login.css'

import { fetchAuthorizationUri } from '../routines'

const Login = ({
  authorizationUri,
  fetchAuthorizationUri,
  authenticating,
  loginError,
}) => {
  const url = authorizationUri && _.replace("response_type=code", "response_type=id_token")(authorizationUri)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {fetchAuthorizationUri()}, [])
  
  return  (<div className='login'>
    <div className="logo-login">
      <img  src="logo_nova.svg" alt="Nova Project" />
    </div>
    <div className="center-google">
      {url ?
        <a className='login-button' href={url} disabled={authenticating}>
          <img className="icon-google" src="google.svg" alt="Google" />
            Log in with Google
        </a>
        :
        <p className='info' >Obteniendo datos de conexión...</p>
      }
      {loginError && <p className='error' >Usuario inválido. Debe ser una cuenta <b>@bluetab.net</b></p>}
    </div>
    <img className="icon-bluetab" src="bluetab_ibm.png" alt="Bluetab"/>
  </div>)
}

const mapStateToProps = _.pick([
  'authorizationUri',
  'currentUser',
  'authenticating',
  'loginError'
])
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ fetchAuthorizationUri }, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(Login)
