import _ from "lodash/fp";
import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import routes from "../routes";

import { useQuery, gql } from "@apollo/client";

import { makeStyles } from "@material-ui/core/styles";
import {
  Chip,
  Checkbox,
  Fab,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { Search as SearchIcon, Add as AddIcon } from "@material-ui/icons";

import MoocItem from "./MoocItem";
import BadgesFilter from "accounts/components/BadgesFilter";
import orderingFields from "./moocsOrdering";
import useOrderBy from "common/orderer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  content: {
    width: "100%",
    maxWidth: "1000px",
  },
  search: {
    width: "100%",
    background: "white",
  },
  paginationContainer: {
    padding: "15px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    height: "65px",
  },
  filterTagsContainer: {
    lineHeight: "35px",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  addButton: {
    float: "right",
    marginRight: "15px",
    marginTop: "15px",
  },
  badge_filter: {
    display: "flex",
    alignItems: "center",
  },
  badge_filter_text: {
    fontSize: "15px",
    fontWeight: "bold",
    marginRight: "5px",
  },
  filterLine: {
    display: "flex",
    justifyContent: "space-around",
  },
}));

const LEARNING_OBJECTS = gql`
  query (
    $limit: Int
    $offset: Int
    $filter: LearningObjectFilter
    $orderBy: LearningObjectOrder
  ) {
    learningObjects(
      limit: $limit
      offset: $offset
      filter: $filter
      orderBy: $orderBy
    ) {
      id
      name
      tags
      badgesIds
      rateAverage
      rateCount
      activeCount
      completeCount
      accessCount
      metadata {
        code
        certification
        image
      }
      requestCost {
        zircoins
      }
    }
  }
`;
const COUNT_LEARNING_OBJECTS = gql`
  query ($filter: LearningObjectFilter) {
    countLearningObjects(filter: $filter)
  }
`;

const ITEMS_PER_PAGE = 10;

const Moocs = () => {
  const classes = useStyles();
  const refContainer = useRef(null);
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [filterTags, setFilterTags] = useState([]);
  const [page, setPage] = useState(1);
  const [onlyCertificates, setOnlyCertificates] = useState(false);
  const [badgesFilter, setBadgesFilter] = useState([]);
  const { orderBySelector, orderBy } = useOrderBy(
    orderingFields,
    "better_rate"
  );

  let filter = {
    type: "mooc",
    active: true,
  };
  filter = !_.isEmpty(search) ? { ...filter, matching: search } : filter;
  filter = onlyCertificates ? { ...filter, certification: true } : filter;
  filter = !_.isEmpty(filterTags) ? { ...filter, tags: filterTags } : filter;
  filter = !_.isEmpty(badgesFilter)
    ? { ...filter, badges: badgesFilter }
    : filter;

  const { data: countLearningObjects } = useQuery(COUNT_LEARNING_OBJECTS, {
    variables: { filter },
    fetchPolicy: "network-only",
  });
  const { data: learningObjectsData } = useQuery(LEARNING_OBJECTS, {
    variables: {
      filter,
      limit: ITEMS_PER_PAGE,
      offset: (page - 1) * ITEMS_PER_PAGE,
      orderBy,
    },
    fetchPolicy: "network-only",
  });

  const count = _.getOr(0, "countLearningObjects")(countLearningObjects);
  const learningObjects = _.getOr([], "learningObjects")(learningObjectsData);

  const dbHandleSearchChange = _.debounce(150)(({ target }) => {
    setPage(1);
    setSearch(target.value);
  });
  const handleSearchChange = (e) => {
    e.persist();
    dbHandleSearchChange(e);
  };
  const handleOnlyCertificate = ({ target }) =>
    setOnlyCertificates(target.checked);
  const paginate = (_, page) => {
    setPage(page);
    refContainer.current.scrollIntoView();
  };

  const pagesCount = Math.ceil(count / ITEMS_PER_PAGE);

  const addTagFilter = (tag) => {
    if (filterTags.indexOf(tag) >= 0) removeTagFilter(tag);
    else {
      setFilterTags([...filterTags, tag]);
      setPage(1);
    }
  };
  const removeTagFilter = (tag) =>
    setFilterTags(_.reject((t) => t === tag)(filterTags));

  const navigateNew = () => history.push(routes.MOOCS_NEW);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Fab
          variant="extended"
          onClick={navigateNew}
          className={classes.addButton}
          color="primary"
          aria-label="add"
        >
          <AddIcon />
          Añadir nuevo curso
        </Fab>
        <h1 ref={refContainer}>Catálogo</h1>
        <TextField
          variant="outlined"
          className={classes.search}
          onChange={handleSearchChange}
          placeholder="¿Qué quieres aprender?"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {orderBySelector}
        <div className={classes.filterLine}>
          <div className={classes.badge_filter}>
            <div className={classes.badge_filter_text}>Filtrar por sellos:</div>
            <BadgesFilter
              selectedBadges={badgesFilter}
              onChange={setBadgesFilter}
              target="learning_object"
            />
          </div>
          <div className={classes.badge_filter_text}>
            <Checkbox
              checked={onlyCertificates}
              onChange={handleOnlyCertificate}
            />
            Mostrar sólo certificaciones
          </div>
        </div>

        <div className={classes.filterTagsContainer}>
          {filterTags.map((tag, idx) => (
            <Chip
              key={idx}
              label={tag}
              onDelete={() => removeTagFilter(tag)}
              className={classes.chip}
            />
          ))}
        </div>
        <div className={classes.paginationContainer}>
          {pagesCount > 0 && (
            <Pagination count={pagesCount} page={page} onChange={paginate} />
          )}
        </div>
        <div>
          {learningObjects.map((learningObject, key) => (
            <MoocItem
              key={key}
              learningObject={learningObject}
              addTagFilter={addTagFilter}
              activeTags={filterTags}
            />
          ))}
        </div>
        <div className={classes.paginationContainer}>
          {pagesCount > 0 && (
            <Pagination count={pagesCount} page={page} onChange={paginate} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Moocs;
